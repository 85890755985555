import * as React from "react";
import { Label } from "office-ui-fabric-react/lib/Label";
import OfferReadModel from "../../models/OfferReadModel";
import { Link, IconButton, Spinner, ThemeSettingName } from "office-ui-fabric-react";
import CustomerDialog from "../details/CustomerDialog";
import { CustomerActions } from "../../actions/CustomerActions";
import { ICustomer, IPerson, IProject } from "../../models/interfaces";
import { WorkerDialog } from "../details/WorkerDialog";
import { OfferStatuses } from "../../models/Enums";
import { WithContext as ReactTags } from "react-tag-input";
import { OfferActions } from "../../actions";
import "../../../../styles/tables.less";
import "../../../../styles/tags.less";
import "../../../../extensions/Array.Extensions";
import { ProjectDialog } from "../details/ProjectDialog";

interface Props {
    model: OfferReadModel;
}

interface State {
    customerDialogHidden: boolean;
    workerDialogHidden: boolean;
    projectDialogHidden: boolean;
    customer?: ICustomer;
    worker?: IPerson;
    project?: IProject;
    tags: string[];
    selectedTags: string[];
    availableTags: string[];
    isSaving: boolean;
}

export class OfferDetails extends React.Component<Props, State> {    
    constructor(props: Props) {
        super(props);
        this.state = {
            customerDialogHidden: true,
            workerDialogHidden: true,
            projectDialogHidden: true,
            tags: props.model.tags,
            selectedTags: props.model.tags.slice(),
            isSaving: false,
            availableTags: []
        };
    }

    componentWillMount() {
        OfferActions.getAllTags()
            .then(tags => {
                let availableTags = tags.map(t => t.name);
                this.setState({...this.state, availableTags}); 
            });
    }

    render() {
        let project = this.props.model.project;
        let saveButtonVisible = !this.state.isSaving && !this.state.tags.equal(this.state.selectedTags);
        return (
            <div className="ms-Grid">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-u-sm6 ms-u-md4 ms-lg3">
                        <Label>
                            Numer <u>{this.props.model.offerNumber}</u>
                        </Label>
                    </div>
                    <div className="ms-Grid-col ms-u-sm6 ms-u-md4 ms-lg3">
                        <Label>Status <b>{OfferStatuses[this.props.model.status]}</b></Label>
                    </div>
                    <div className="ms-Grid-col ms-u-sm6 ms-u-md4 ms-u-lg6">
                        <ReactTags
                            classNames={{tags: "inline-flex"}}
                            tags={this._stringArrayToTags(this.state.selectedTags)}
                            placeholder="Dodaj tag"
                            handleAddition={(tag) => this._addTag(tag.text)}
                            handleDelete={this._removeTag}
                            suggestions={this._stringArrayToTags(this.state.availableTags)}
                            allowDragDrop={false}/>
                        { saveButtonVisible && <IconButton
                            className={"tag-save"}
                            iconProps={{ iconName: "Save" }}
                            title={"Zapisz tagi"}
                            onClick={this._saveTags}/>
                        }
                        { this.state.isSaving && <Spinner className={"inline-flex"}/> }
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-u-sm6 ms-u-md3 ms-lg3">
                        <Label>Klient</Label>
                        <Link onClick={this._getCustomer}>
                            <b>{this.props.model.customer.name}</b>
                        </Link>
                    </div>
                    <div className="ms-Grid-col ms-u-sm6 ms-u-md3 ms-lg3">
                        <Label>Osoba kontaktowa</Label>
                        <Link onClick={this._getWorker}>
                            <b>{this.props.model.contactPerson.name}</b>
                        </Link>
                    </div>
                    <div className="ms-Grid-col ms-u-sm6 ms-u-md3 ms-lg3">
                        <Label>Projekt</Label>
                        {
                            this.props.model.project !== null
                            && (
                                <Link onClick={this._getProject}>
                                    <b>{this.props.model.project.name}</b>
                                </Link>
                            )
                        }
                        {
                            this.props.model.project === null
                            && <span>-</span>
                        }
                    </div>
                </div>
                <WorkerDialog
                    title="Dane osoby kontaktowej"
                    hidden={this.state.workerDialogHidden}
                    disabledTextFields
                    worker={this.state.worker}
                    onDismiss={() => this.setState({ workerDialogHidden: true })}/>
                <CustomerDialog
                    title="Dane klienta"
                    hidden={this.state.customerDialogHidden}
                    disabledTextFields
                    customer={this.state.customer}
                    onDismiss={() => this.setState({ customerDialogHidden: true })}/>
                <ProjectDialog
                    title="Dane projektu"
                    hidden={this.state.projectDialogHidden}
                    disabledTextFields
                    project={this.state.project}
                    onDismiss={() => this.setState({ projectDialogHidden: true })}/>
            </div>
        );
    }

    private _getCustomer = () => {
        CustomerActions.getCustomer(this.props.model.customer.id)
            .then(x => {
                this.setState({ customer: x, customerDialogHidden: false });
            });
    }

    private _getWorker = () => {
        CustomerActions.getWorker(this.props.model.contactPerson.id)
            .then(x => {
                this.setState({ worker: x, workerDialogHidden: false });
            });
    }

    private _getProject = () => {
        CustomerActions.getProject(this.props.model.project.id)
            .then(project => {
                this.setState({ project, projectDialogHidden: false });
            })
    }

    private _addTag = (tag: string) => {
        this.setState({...this.state, selectedTags: this.state.selectedTags.concat(tag)});
    }

    private _removeTag = (index: number) => {
        let selectedTags = this.state.selectedTags;
        selectedTags.splice(index, 1);
        this.setState({...this.state, selectedTags});
    }

    private _saveTags = () => {
        this.setState({...this.state, isSaving: true});
        OfferActions
            .updateOfferTags(this.props.model.id, this.state.selectedTags)
            .then(() => {
                this.setState({...this.state, tags: this.state.selectedTags, isSaving: false});
            });
    }

    private _stringArrayToTags(array: string[]) {
        return array.map(t => { return { id: t, text: t }; });
    }
}
