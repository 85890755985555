import * as Notifications from "react-notification-system";

export interface IEvent {
    name: string;
    body: any;
    ok: boolean;
}

export interface IEventType {
    name: string;
    result: IEventResult;
}

export interface IEventResult {
    notification?: Notifications.Notification;
    dialog?: INotificationDialog;
}

export interface INotificationDialog {
    buttonTitle: string;
    title: string;
    hidden: boolean;
    action: () => void;
}

export const succesModel: Notifications.Notification = {
    title: "Sukces",
    level: "success",
    position: "br",
    autoDismiss: 20
};

export const errorModel: Notifications.Notification = {
    title: "Błąd",
    level: "error",
    position: "br",
    autoDismiss: 20
};


