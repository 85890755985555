import * as React from "react";
import { Select } from "../../../../components";
import { CustomerActions } from "../../actions/CustomerActions";
import { ProjectDialog } from "./ProjectDialog";
import { IProjectListItem, IProject } from "../../models/interfaces";
import { connect, Dispatch } from "react-redux";
import { onEditProject, onAddProject, onDismissProjectDialog, onChangeProject, onLoadProjects } from "../../store/offerDetails/actions";
import {v4 as uuidv4} from "uuid";

interface Props {
    projectId: number;
    project: IProject;
    projects: IProjectListItem[];
    hidden: boolean;
    edited: boolean;
    title: string;
    onChangeProject: (id: number) => void;
    onChange: (project: IProject) => void;
    onLoad: (projects: IProjectListItem[]) => void;
    onEdit: (project: IProject) => void;
    onDismiss: () => void;
    onAdd: () => void;
}

interface State {
    changed: Boolean;
    isLoading: boolean;
}

class ProjectSelect extends React.Component<Props, State>  {
    private select: Select;

    constructor(props: any) {
        super(props);
        this.state = { isLoading: false, changed: false };
    }

    componentDidMount() {
        this._loadProjects();
    }

    render() {
        return (
            <div>
                <Select noResultsText="Brak projektu" ref={select => this.select = select}
                    placeholder="Wybierz"
                    onAddClicked={this.props.onAdd}
                    onChange={this.props.onChangeProject}
                    onEditClicked={this.onEditProject.bind(this)}
                    options={this.props.projects}
                    labelKey="name"
                    buttonsAreVisible
                    withLoaded
                    selectedId={this.props.projectId}
                    isLoading={this.state.isLoading}/>
                <ProjectDialog
                    {...this.props}
                    onSaved={this._onSaved.bind(this)}/>
            </div>
        );
    }

    private _loadProjects() {
        this.setState({ isLoading: true });
        CustomerActions.getAllProjects()
            .then(projects => {
                this.props.onLoad(projects);
                this.setState({ isLoading: false });
            });
    }

    private _onSaved(project: IProjectListItem) {
        let projects = this.props.projects;
        let index = projects.findIndex(x => x.id === project.id);
        if (index === -1) {
            this.props.onLoad([project, ...projects]);
        }
        else {
            projects[index].name = project.name;
            this.props.onLoad(projects);
        }
        this.props.onChangeProject(project.id);
    }

    private onEditProject() {
        let projectId = this.select.state.selected.id;
        CustomerActions.getProject(projectId)
            .then(project => {
                project.contacts.forEach(c => {
                    c.id = uuidv4();
                });
                this.props.onEdit({ ...project, id: this.props.projectId });
            });
    }
}

function mapStateToProps(state: any) {
    return {
        project: state.offerDetails.project,
        projects: state.offerDetails.projects,
        hidden: state.offerDetails.projectDialogHidden,
        edited: state.offerDetails.edited,
        title: state.offerDetails.title,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        onEdit: (project: IProject) => dispatch(onEditProject(project)),
        onDismiss: () => dispatch(onDismissProjectDialog()),
        onAdd: () => dispatch(onAddProject()),
        onLoad: (projects: IProjectListItem[]) => dispatch(onLoadProjects(projects)),
        onChange: (project: IProject) => dispatch(onChangeProject(project))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelect);
