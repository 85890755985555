import * as React from "react";
import { Select } from "../../../../../components";
import { Label } from "office-ui-fabric-react";
import { IKeyItem } from "../../../../../models/interfaces";

interface IAffectPriceChoiceProps {
    item: IKeyItem;
    setupAffectPrice: any[];
    onChange: (item: IKeyItem) => any;
}

export default class AffectPriceChoice extends React.Component<IAffectPriceChoiceProps, any> {
    render() {
        let { item, onChange } = this.props;
        return (
            <div className="ms-Grid-col ms-lg4">
                <Label> Rodzaj wpływu na cenę  </Label>
                <Select noResultsText="Brak typow"
                    className="without-buttons"
                    placeholder="Wybierz"
                    selectedId={item.affectsPrice}
                    onChange={(affectsPrice) => onChange({ ...item, affectsPrice })}
                    options={this.props.setupAffectPrice}
                    labelKey="name"
                    isLoading={false}/>
            </div>
        );
    }
}
