import * as React from "react";
import { IKeyItemValue } from "../../../../../models/interfaces";
import { NumberInput } from "../../../../../components";
import "../../../styles/key/styles.less";

interface INumberKeyItemProps {
    value?: number;
    min?: number;
    hint?: string;
    onChange: (value: IKeyItemValue) => void;
}

interface INumberKeyItemState {
    value?: number;
}
export default class NumberKeyItem extends React.Component<INumberKeyItemProps, INumberKeyItemState> {
    constructor(props: INumberKeyItemProps) {
        super(props);
        this.state = { value: this.props.value };
    }

    onChange(newValue: number) {
        this.setState({ value: newValue });
        this.props.onChange({ value: newValue });
    }

    render() {
        return (
            <div className="numberKeyItem"
                title={this.props.hint}>
                <NumberInput
                    onChange={this.onChange.bind(this)}
                    type="text"
                    inputClassName="numberKeyItem"
                    value={this.state.value}
                    min={this.props.min}/>
            </div>
        );
    }
}
