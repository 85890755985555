export const enum CustomColorsDialogActionTypes {
    OPEN = "Admin/CustomColorsDialog/OPEN",
    SHOW = "Admin/CustomColorsDialog/SHOW",
    HIDE = "Admin/CustomColorsDialog/HIDE",
    MARK = "Admin/CustomColorsDialog/MARK",
    CHANGE_COST_FACTOR = "Admin/CustomColorsDialog/CHANGE_COST_FACTOR",
    CHANGE_ALL = "Admin/CustomColorsDialog/CHANGE_ALL"
}

export class CustomColorDialogState {
    items: CustomColorEditing[] = [];
    isHidden: boolean = true;
}

export class CustomColorEditing {
    colorId: number;
    value: number;
    other: string;
    assigned: boolean;
    keyId: number;
    originalCostFactor: number;
    costFactor: number;
    isDefault: boolean;
}

export class CustomColorEdited {
    colorId: number;
    isDefault: boolean;
    costFactor?: number;
}
