import * as React from "react";
import { CheckboxVisibility, autobind, Spinner, SpinnerSize, IconButton, Panel, PanelType } from "office-ui-fabric-react/lib";
import { Label } from "office-ui-fabric-react/lib/Label";
import { DetailsList, DetailsListLayoutMode, IColumn, Selection } from "office-ui-fabric-react/lib/DetailsList";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import { OfferListItem } from "../../models/OfferListItem";
import { ContextualMenu } from "../list/ContextualMenu";
import DisplayHelper from "../../../../helpers/DisplayHelper";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { clearItems, pushItem, deleteOffer, changeStatus, showComments, hideComments } from "../../store/offersList/actions";
import NavigationHelper from "../../helpers/NavigationHelper";
import { LabelMenu } from "../../../../components";
import { OfferStatuses, OfferStatus } from "../../models/Enums";
import { WithContext as ReactTags } from "react-tag-input";
import { CommentsThread } from "../comments";

interface Props {
    items: OfferListItem[];
    isLoaded: boolean;
    isFull: boolean;
    onlyMy: boolean;
    commentsAreVisible: boolean;
    commentsOffer: OfferListItem;
    onLoad: (length: number) => void;
    onChangeStatus: (item: {id: number, newStatus: OfferStatus}) => void;
    onDownload: (length: number) => void;
    onDelete: (id: number) => void;
    clearItems: () => void;
    pushItem: (item: OfferListItem) => void;
    showComments: (item: OfferListItem) => void;
    hideComments: () => void;
}

class OffersList extends React.Component<Props, any> {
    private _contextualMenu: ContextualMenu;
    private _selection: Selection;

    constructor(props: Props) {
        super(props);
        this._handleContextualMenu = this._handleContextualMenu.bind(this);
    }

    render() {
        return (
            <div>
                <MarqueeSelection selection={this._selection}>
                    <DetailsList
                        items={this.props.items}
                        columns={OffersList.Columns}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={(item) => NavigationHelper.goToOfferView(item.id)}
                        onItemContextMenu={(item?: OfferListItem, index?: number, ev?: MouseEvent) => this._handleContextualMenu(item, ev)}
                        onRenderItemColumn={(item, index, col) => this._onRenderItemColumn(item, index, col, this._contextualMenu)}/>
                    {!this.props.isLoaded && <Spinner size={SpinnerSize.large}/>}
                </MarqueeSelection>
                <ContextualMenu
                    ref={(ref: ContextualMenu) => this._contextualMenu = ref}
                    onCopy={this.props.pushItem}
                    onStatusChange={(id, status) => this.props.onChangeStatus({id, newStatus: status})}
                    onDelete={id => this.props.onDelete(id)}/>
                <Panel isOpen={this.props.commentsAreVisible} 
                    onDismiss={this.props.hideComments}
                    type={PanelType.medium}
                    headerText={`Komentarze do oferty ${this.props.commentsOffer.number}`}>
                    <CommentsThread threadId={this.props.commentsOffer.commentsThreadId}/>
                </Panel>
            </div>
        );
    }

    @autobind
    onScroll() {
        if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight)
            && this.props.isLoaded
            && !this.props.isFull) {
            let length = this.props.items === undefined ? 0 : this.props.items.length;
            this.props.onDownload(length);
        }
    }

    showComments(item: OfferListItem) {
        this.props.showComments(item);
    }

    private _handleContextualMenu(item: OfferListItem, event: MouseEvent) {
        if (event.type !== "contextmenu") {
            return;
        }
        this._contextualMenu.setState({
            isVisible: true,
            target: { x: event.clientX, y: event.clientY },
            item
        });
    }

    componentDidMount() {
        let length = this.props.items === undefined ? 0 : this.props.items.length;
        this.props.onLoad(length);
        window.addEventListener("scroll", this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    private static Columns : IColumn[] = [
        {
            key: "column1",
            name: "Numer oferty",
            fieldName: "number",
            minWidth: 100,
            maxWidth: 160,
            isResizable: true,
        },
        {
            key: "comments",
            name: "",
            fieldName: "comments",
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
        },
        {
            key: "column2",
            name: "Klient",
            fieldName: "client",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "column4",
            name: "Projekt",
            fieldName: "project",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true
        },
        {
            key: "column7",
            name: "Wartość",
            fieldName: "sum",
            minWidth: 100,
            maxWidth: 180,
            isResizable: true
        },
        {
            key: "column8",
            name: "Status",
            fieldName: "status",
            minWidth: 60,
            maxWidth: 120,
            isResizable: true
        },
        {
            key: "tags",
            name: "Tagi",
            fieldName: "tags",
            minWidth: 200,
            isResizable: true
        },
        {
            key: "column3",
            name: "Autor",
            fieldName: "author",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "column5",
            name: "Data utworzenia",
            fieldName: "createdDate",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
        },
        {
            key: "column6",
            name: "Data modyfikacji",
            fieldName: "modifiedDate",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: "column7",
            name: "Następny kontakt",
            fieldName: "nextContactDate",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            
        }
    ];

    private _onRenderItemColumn(item: OfferListItem, index: any, column: any, menu: any) {
        switch (column.fieldName) {
            case "number":
                return <LabelMenu
                    buttonId={`menu-${item.id}`}
                    text={item.number}
                    linkText
                    onLinkClick={() => NavigationHelper.goToOfferView(item.id)}
                    onClick={() => 
                        menu.setState({
                            isVisible: true,
                            target: document.querySelector(`#menu-${item.id}`) as HTMLElement,
                            item
                        })}/>;
            case "comments":
                return <IconButton iconProps={{ iconName: "Comment" }} onClick={() => this.showComments(item)}/>
            case "modifiedDate":
                return <Label>{DisplayHelper.formatDateTime(item.modifiedDate)}</Label>;
            case "createdDate":
                return <Label>{DisplayHelper.formatDateTime(item.createdDate)}</Label>;
            case "nextContactDate":
                return <Label><span style={{color: '#ff1100'}}>{DisplayHelper.formatDate(item.nextContactDate)}</span></Label>;
            case "sum":
                return <Label>{DisplayHelper.formatFixed(item.sum)} {item.currency}</Label>;
            case "status":
                return <Label>{OfferStatuses[item.status]}</Label>;
            case "tags":
                let tags = item.tags.map(t => { return { id: t, text: t}; });
                return (
                    <ReactTags
                        readOnly
                        tags={tags}
                        handleDelete={() => {}}
                        handleAddition={() => {}}/>
                    );
            default:
                return <Label>{(item as any)[column.fieldName]}</Label>;
        }
    }
}

function mapStateToProps(state: any, ownProps: { loadItems: () => void, onDownload: () => void }) {
    return {
        items: state.offersList.items,
        isLoaded: state.offersList.isLoaded,
        isFull: state.offersList.isFull,
        onlyMy: state.offersList.onlyMy,
        commentsAreVisible: state.offersList.comments.areVisible,
        commentsOffer: state.offersList.comments.offer
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        clearItems: () => dispatch(clearItems()),
        pushItem: (item: OfferListItem) => dispatch(pushItem(item)),
        onDelete: (id: number) => dispatch(deleteOffer(id)),
        onChangeStatus: (item: {id: number, newStatus: OfferStatus}) => dispatch(changeStatus(item)),
        showComments: (item: OfferListItem) => dispatch(showComments(item)),
        hideComments: () => dispatch(hideComments())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OffersList);
