import * as React from "react";
import { DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { autobind } from "@uifabric/utilities";
import { IProductType } from "../../../../../models/interfaces";
import { Dialog } from "../../../../../components/dialogs";

interface ProductTypeDialogProps {
    text?: string;
    onConfirmed: (item: IProductType) => any;
}

interface ProductTypeDialogState {
    hidden: boolean;
    item: { id: number, name: string, symbol: string };
}

export default class ProductTypeDialog extends React.Component<ProductTypeDialogProps, ProductTypeDialogState> {
    private _symbol: TextField;
    private _name: TextField;

    constructor(props: any) {
        super(props);
        this.state = {
            hidden: true,
            item: { id: null, name: undefined, symbol: undefined }
        };
    }

    public render() {
        return (
            <Dialog
                modalProps={{ isBlocking: false, containerClassName: "dialog-min-width" }}
                hidden={this.state.hidden}
                dialogContentProps={{ type: DialogType.normal  }}
                onDismiss={this._closeDialog}
                title={this.props.text === null
                    ? "Edycja"
                    : this.props.text}>
                <TextField
                    label="Nazwa"
                    defaultValue={this.state.item === undefined ? "" : this.state.item.name}
                    required
                    ref={(ref: any) => this._name = ref}/>
                <TextField
                    label="Skrót"
                    defaultValue={this.state.item === undefined ? "" : this.state.item.symbol}
                    required
                    ref={(ref: any) => this._symbol = ref}/>
                <DialogFooter>
                    <PrimaryButton
                        onClick={this._onConfirmDialog}
                        text="Zapisz"
                        disabled={this.state.hidden}/>
                    <DefaultButton
                        onClick={this._closeDialog}
                        text="Anuluj"
                        disabled={this.state.hidden}/>
                </DialogFooter>
            </Dialog>
        );
    }

    show(item?: { id: number, name: string, symbol: string }) {
        if (item === undefined) {
            this.setState({ hidden: false, item: { id: null, name: undefined, symbol: undefined } });
        }
        this.setState({ hidden: false, item: item });
    }

    @autobind
    private _onConfirmDialog() {
        let id;
        if (this.state.item !== undefined) {
            id = this.state.item.id;
        }
        let item = { id, name: this._name.value, symbol: this._symbol.value } as IProductType;
        this.props.onConfirmed(item);
        this.setState({ hidden: true });
    }

    @autobind
    private _closeDialog() {
        this.setState({ hidden: true });
    }
}
