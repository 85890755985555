import { IPoint } from "@uifabric/utilities";
import RalColor from "../../../../models/RalColor";

export const enum RalColorsActionTypes {
    LOAD = "Admin/RalColors/LOAD",
    ON_ADD = "Admin/RalColors/ON_ADD",
    SHOW_MENU = "Admin/RalColors/SHOW_MENU",
    SHOW_ADD_DIALOG = "Admin/RalColors/SHOW_ADD_DIALOG",
    SHOW_EDIT_DIALOG = "Admin/RalColors/SHOW_EDIT_DIALOG",
    SHOW_INVOKED_DIALOG = "Admin/RalColors/SHOW_INVOKED_DIALOG",
    EDIT = "Admin/RalColors/EDIT",
    ADD = "Admin/RalColors/ADD",
    DELETE = "Admin/RalColors/DELETE",
    DISMISS_MENU = "Admin/RalColors/DISMISS_MENU",
    DISMISS_DIALOG = "Admin/RalColors/DISMISS_DIALOG",
    ON_CHANGE = "Admin/RalColors/ON_CHANGE",
    ON_MARK = "Admin/RalColors/ON_MARK"
}

export class RalColorEditorState {
    items: RalColor[] = [];
    isContextMenuVisible: boolean = false;
    edited: boolean = false;
    target: IPoint | HTMLElement = undefined;
    showDialog: boolean = false;
    menuItem: any = new RalColor();
}
