import * as React from "react";
import { Label, Button } from "office-ui-fabric-react";

export default class AccessDeniedToApp extends React.Component<any, any> {
    render() {
        return (
            <div>
                <Label>Brak dostępu do tej strony</Label>
                <div><Button onClick={this.props.history.goBack}>Wróć do poprzedniej strony</Button></div>
            </div>
        );
    }
}
