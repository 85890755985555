import * as React from "react";
import { Ribbon, RibbonPlugin } from "roosterjs-react-ribbon";
import { EmojiPlugin, createEditorViewState, EditorViewState } from "roosterjs-react";
import ReactEditor from "./ReactEditor";
import ribbonButtonRenderer from "./ribbonButtonRenderer";
import { autobind, DefaultButton } from "office-ui-fabric-react";
import "./CommentsEditor.less";

interface Props {
    onSave: (comment: string) => void;
    onChange?: (comment: string) => void;
}

interface State {
    //state: EditorViewState;
    changed: boolean;
}

export class CommentsEditor extends React.Component<Props, State> {
    private _viewState: EditorViewState;
    private _ribbonPlugin: RibbonPlugin;
    private _editor: ReactEditor;
    //private _emojiPlugin: EmojiPlugin;

    constructor(props: Props) {
        super(props);

        this._viewState = createEditorViewState();
        this._ribbonPlugin = new RibbonPlugin();
        //this._emojiPlugin = new EmojiPlugin();
        //this._emojiPlugin.initializeContentEditable()

        this.state = {changed: false};
    }

    ribbonButtons = [
        // "emoji",
        "bold",
        "italic",
        "underline",
        "font",
        "size",
        "bkcolor",
        "color",
        "bullet",
        "number",
        "indent",
        "outdent",
        "quote",
        "left",
        "center",
        "right",
        "link",
        "unlink",
        "sub",
        "super",
        "strike",
        // 'alttext',
        // 'ltr',
        // 'rtl',
        // 'undo',
        // 'redo',
        "unformat"
    ];

    render() {
        return (
            <div className="comments-editor">
                <div className={"editor"}>
                    <Ribbon
                        ribbonPlugin={this._ribbonPlugin}
                        className={"comments-ribbon"}
                        buttonRenderer={ribbonButtonRenderer}
                        buttonNames={this.ribbonButtons}
                        //additionalButtons={{ emoji: { name: "btnEmoji", onClick: () => this._emojiPlugin.startEmoji() } }}
                        />
                    <ReactEditor
                        viewState={this._viewState}
                        onChange={this._handleChange}
                        ref={m => this._editor = m}
                        plugins={[this._ribbonPlugin /* , this._emojiPlugin */]} />
                </div>
                <DefaultButton
                    className="btn-comments-save"
                    text="Zapisz"
                    onClick={this._onSave}
                    disabled={!this.state.changed}/>
            </div>
        );
    }

    @autobind
    private _onSave() {        
        let content = this._viewState.content;
        this._editor.clean();        
        this.setState({changed: false});
        this.props.onSave(content);
    }

    @autobind
    private _handleChange(message: string) {
        if (this.props.onChange) {
            this.props.onChange(message);
            this.setState({changed: true});
        }
    }
}
