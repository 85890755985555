import { createAction } from "redux-actions";
import { KeyItemMenuActionTypes } from "./types";
import { IKeyItem } from "../../../../models/interfaces";

export const keyItemDismissMenu = createAction(
    KeyItemMenuActionTypes.DISMISS_MENU
);

export const keyItemShowAddDialog = createAction(
    KeyItemMenuActionTypes.SHOW_ADD_DIALOG
);

export const keyItemShowEditDialog = createAction(
    KeyItemMenuActionTypes.SHOW_EDIT_DIALOG
);

export const keyItemDismissDialog = createAction(
    KeyItemMenuActionTypes.DISMISS_DIALOG
);

export const keyItemOnChange = createAction(
    KeyItemMenuActionTypes.ON_CHANGE,
    (item: IKeyItem) => item
);

export const keyItemShowMenu = createAction(
    KeyItemMenuActionTypes.SHOW_MENU,
    (item: IKeyItem, event: MouseEvent) => {
        let menu = { item, target: { x: event.clientX, y: event.clientY } };
        return menu;
    }
);

export const keyItemValueAdd = createAction(
    KeyItemMenuActionTypes.VALUE_ADD
);

export const keyItemValueDelete = createAction(
    KeyItemMenuActionTypes.VALUE_DELETE,
    (index: number) => index
);
