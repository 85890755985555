import * as React from "react";
import { Dialog, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Label } from "office-ui-fabric-react/lib/Label";
import "./Dialog.less";

interface IConfirmDialogProps {
    text?: string;
}

interface IConfirmDialogState {
    visible: boolean;
    messages: { key: string, message: string }[];
}

export class ValidationDialog extends React.Component<IConfirmDialogProps, IConfirmDialogState> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            messages: []
        };
    }
    
    render() {
        return (
            <div>
                <Dialog
                    modalProps={{className: "text size-dialog", isBlocking: false}}
                    hidden={!this.state.visible}
                    onDismiss={this._closeDialog.bind(this)}
                    title="Błędy walidacji"> 
                    {this.state.messages.map(x => <Label key={x.key}> {x.message} </Label>)}
                    <DialogFooter>
                        <PrimaryButton 
                            style={{float: "left"}}
                            onClick={this._onConfirmDialog.bind(this)}
                            text="Ok"
                            disabled={!this.state.visible}/>
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }

    private _showDialog() {
        this.setState({ visible: true });
    }

    private _onConfirmDialog() {
        this.setState({ visible: false });
    }

    private _closeDialog() {
        this.setState({ visible: false });
    }
}
