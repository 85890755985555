import * as React from "react";

export default class RedirectToHttps extends React.Component<any, any> {
    render() {
        if (window.location.protocol !== "https:") {
            location.href = `https:${window.location.href.substring(window.location.protocol.length)}`;
            return <noscript/>;
        }
        return this.props.children;
    }
}
