import Publisher from "./Publisher";
import MathHelper from "./../../../helpers/MathHelper";
import KeyModel from "./KeyModel";
import { CalculationActions } from "../actions";

export default class PositionModel extends Publisher {
    id: number;
    productTypeId: number;
    key: KeyModel;
    discountPercentage: number;
    discountInCurrency: number;
    quantity: number;
    netUnitPrice: number;
    netUnitPriceAfterDiscount: number;
    netValue: number;
    isCustom: boolean;
    remarks?: string;

    changeProductType(keyModel: KeyModel) {
        this.key = keyModel;
    }

    changeDiscountPercentage(value: number) {
        let newDiscount = value != null ? value : 0;
        this.discountPercentage = newDiscount;
        this.discountInCurrency = null;
        this.recalculateNetValue();
    }

    changeQuantity(newValue: number) {
        this.quantity = MathHelper.correctOr0(newValue);
        this.recalculateNetValue();
    }

    recalculate(netUnitPrice: number) {
        this.netUnitPrice = netUnitPrice;
        this.recalculateNetValue();
    }

    recalculateNetValue() {
        if (this.key.needCompleteCosts) {
            return;
        }
        
        CalculationActions.recalculatePositionNetValue(this)
            .then(x => {
                this.discountInCurrency = x.discountInCurrency;
                this.netUnitPriceAfterDiscount = x.netUnitPriceAfterDiscount;
                this.netValue = x.netValue;
                this.discountPercentage = x.discountPercentage;
                this.inform();
            });
    }

    changeNetUnitPrice(newValue: number) {
        this.netUnitPrice = newValue;
        this.recalculateNetValue();
    }

    changeRemarks(value: string) {
        debugger;
        this.remarks = value;
    }

    public clone() {
        let model = new PositionModel();
        model.id = undefined;
        if (this.productTypeId !== undefined) {
            model.productTypeId = this.productTypeId;
            model.key = this.key.clone();
        }
        model.discountInCurrency = this.discountInCurrency;
        model.discountPercentage = this.discountPercentage;
        model.quantity = this.quantity;
        model.netUnitPrice = this.netUnitPrice;
        model.netUnitPriceAfterDiscount = this.netUnitPriceAfterDiscount;
        model.netValue = this.netValue;
        model.isCustom = this.isCustom;
        model.remarks = this.remarks;
        return model;
    }

    public static create() {
        let model = new PositionModel();
        model.id = undefined;
        model.productTypeId = undefined;
        model.key = undefined;
        model.discountInCurrency = 0;
        model.discountPercentage = 0;
        model.quantity = 1;
        model.netUnitPrice = 0;
        model.netUnitPriceAfterDiscount = 0;
        model.netValue = 0;
        model.isCustom = false;
        return model;
    }
}
