import * as React from "react";
import { Label } from "office-ui-fabric-react/lib/Label";
import { DateInput } from "../../../../components";
import { OfferContactInfo } from "../../models/interfaces";
import { autobind, DefaultButton } from "office-ui-fabric-react";
import "./ContactInfo.less";
import { threadId } from "worker_threads";

interface Props {
    contactInfo: OfferContactInfo;
    onSave?: (contactInfo: OfferContactInfo) => void;
}

interface State extends OfferContactInfo {
}

export default class ContactInfo extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            nextContactDate: props.contactInfo.nextContactDate,
            lastContactDate: props.contactInfo.lastContactDate
        };
    }

    render() {
        return <div className="contact-info">
            <div className="ms-Grid">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                        <Label>Ostatni kontakt</Label>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                        <DateInput
                            value={this.state.lastContactDate}
                            onSelectDate={date => this.setState({...this.state, lastContactDate: date})}/>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                        <Label>Następny kontakt</Label>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                        <DateInput
                            value={this.state.nextContactDate}
                            onSelectDate={date => this.setState({...this.state, nextContactDate: date})}/>
                    </div>
                </div>
            </div>
            <DefaultButton
                className="btn-comments-save"
                text="Zapisz"
                onClick={this._onClick}
                disabled={this.saveDisabled}/>
        </div>;
    }

    private get saveDisabled() {
        return this.state.lastContactDate === this.props.contactInfo.lastContactDate
            && this.state.nextContactDate === this.props.contactInfo.nextContactDate;
    }

    @autobind
    private _onClick() {
        if (this.props.onSave) {
            this.props.contactInfo.lastContactDate = this.state.lastContactDate;
            this.props.contactInfo.nextContactDate = this.state.nextContactDate;
            this.props.onSave(this.state);
        }
    }
}
