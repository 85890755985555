import { Tag } from "../../models/interfaces";

export const enum TagsActionTypes {
    LOAD = "Admin/Tags/LOAD",
    DELETE = "Admin/Tags/DELETE",
    UPDATE = "Admin/Tags/UPDATE"
}

export class TagsListState {
    tags: Tag[] = [];
}
