import * as React from "react";
import "./TextPreview.less";

interface Props {
    content: string;
}

export class TextPreview extends React.Component<Props, any> {
    render() {
        return <div className="preview-notes" dangerouslySetInnerHTML={{ __html: this.props.content }}/>;
    }
}
