import OfferModel from "../../models/OfferModel";

export const enum Types {
    POSITION_ADD = "@@Offer/Editor/POSITION_ADD",
    POSITION_COPY = "@@Offer/Editor/POSITION_COPY",
    POSITION_ADD_DUPLICATE = "@@Offer/Editor/POSITION_ADD_DUPLICATE",
    POSITION_DELETE = "@@Offer/Editor/POSITION_DELETE",
    ON_LOAD = "@@Offer/Editor/ON_LOAD",
    TAGS_SUGGESTIONS_ON_LOAD = "@@Offer/Editor/Tags/Suggestions/ON_LOAD",
    POSITION_ADD_CUSTOM = "@@Offer/Editor/POSITION_ADD_CUSTOM",
    ADD_TAG = "@@Offer/Editor/AddTag",
    REMOVE_TAG = "@@Offer/Editor/RemoveTag",
    SET_NUMBER = "@@Offer/Editor/SetNumber",
    SET_VERSION = "@@Offer/Editor/SetVersion",
    CHANGE_PROJECT = "@@Offer/Editor/ChangeProject",
    CHANGE_CUSTOMER = "@@Offer/Editor/ChangeCustomer",
    CHANGE_CONTACT_PERSON = "@@Offer/Editor/ChangeContactPerson",
    CHANGE_COMMENTS_VISIBILITY = "@@Offer/Editor/ChangeCommentsVisibility",
    CHANGE_INITIAL_COMMENT = "@@Offer/Editor/ChangeInitialComment",
    SET_COMMENTS_COUNT = "@@Offer/Editor/SetCommentsCount",
    CHANGE_CONTACT_INFO_VISIBILITY = "@@Ofer/Editor/ChangeCommentsVisibility"
}

export class OfferEditorModel {
    offer: OfferModel;
    tagsSuggestions: string[] = [];
    commentsAreVisible: boolean = false;
    initialComment: string;
    commentsCount: number = 0;
    contactInfoIsVisible: boolean = false;
}
