import SelectKeyItem, { IOption } from "./SelectKeyItemBase";
import { IKeyItemValue } from "../../../../../models/interfaces";

export default class RalKeyItem extends SelectKeyItem {
    protected handleSelectChange(newValue: IOption): void {
        this.setState({value: newValue});
        this.props.onChange({value: newValue.value as number});
    }

    protected get selectClassName(): string {
        return "ralKeyItem";
    }

    protected mapToOption(v: IKeyItemValue): IOption {
        return { label: v.name, value: v.value, title: v.name };
    }
}
