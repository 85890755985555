import * as React from "react";
import ProductType from "../ProductType";
import ProductKey from "../key/ProductKey";
import KeyModel from "../../../models/KeyModel";
import KeyItemModel from "../../../models/KeyItemModel";
import DisplayHelper from "../../../../../helpers/DisplayHelper";
import { PositionBase, Props, State } from "./PositionBase";
import { IProductType } from "../../../../../models/interfaces";
import { IKeyItemMetadata, IKeyMetadata } from "../../../models/interfaces";

export interface PositionProps extends Props {
    getMetadata?: (keyId: number) => Promise<IKeyMetadata>;
    productTypes?: IProductType[];
}

export default class Position extends PositionBase<PositionProps, State> {
    private _productKey: ProductKey;
    
    constructor(props: PositionProps) {
        super(props);
        this.state = {editing: false};
        
        this.subscribeKey(this.props.model.key);
    }

    private onProductTypeChange(newProductType: IProductType) {
        this._productKey.setState({ ...this._productKey.state, isLoading: true });

        this.props.getMetadata(newProductType.keyId).then(keyMetadata => {
            let key: KeyModel = null;
            if (keyMetadata.productTypes.find(p => this.props.model.productTypeId === p.id)) {
                key = this.props.model.key;
            }

            let items = keyMetadata.items.map(itemMetadata => this.createKeyItem(itemMetadata, key));

            this.props.model.productTypeId = newProductType.id;
            key = new KeyModel(newProductType.id, keyMetadata.id, items, newProductType.needCompleteCosts);
            this.subscribeKey(key);
            this.props.model.changeProductType(key);
            this.onProductKeyChange();
            this._productKey.setState({ model: key, isLoading: false });
        });
    }

    protected renderNetUnitPrice() {
        return <span>{DisplayHelper.fixed(this.props.model.netUnitPrice)}</span>;
    }

    private createKeyItem(metadata: IKeyItemMetadata, key: KeyModel): KeyItemModel {
        return new KeyItemModel(metadata,
            key === null
                ? null
                : key.items
                    .find(x => x.metadata.id === metadata.id)
                    .getCurrent()
        );
    }

    protected renderPositionName(): JSX.Element {
        return (
            <span className="flex">
                <ProductType
                    key={`${this.props.model.id}-productType`}
                    productTypeId={this.props.model.productTypeId}
                    onChange={this.onProductTypeChange.bind(this)}
                    productTypes={this.props.productTypes}/>
                <ProductKey
                        ref={pKey => this._productKey = pKey}
                        key={`${this.props.model.id}-productKey`}
                        model={this.props.model.key}
                        onChange={this.onProductKeyChange.bind(this)}/>
            </span>
        );
    }

    protected subscribeKey(key: KeyModel) {
        if (key === undefined) {
            return;
        }
        key.subscribe(this.onProductKeyChange.bind(this));
    }
}
