export const enum KeyListActionTypes {
    DELETE = "Admin/Keys/DELETE_ITEM",
    LOAD = "Admin/Keys/KEYS_LOAD",
}

export class ProductKeyListItem {
    id: number;
    name: string;
    modifiedDate: Date;
    needCompleteCosts: boolean;
}
