import * as React from "react";
import { CheckboxVisibility, Spinner, SpinnerSize } from "office-ui-fabric-react/lib";
import { DetailsList, DetailsListLayoutMode, IColumn, Selection } from "office-ui-fabric-react/lib/DetailsList";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";

interface Props<TItem> {
    columns: IColumn[];
    onRenderItemColumn?: (item?: TItem, index?: any, column?: any, menu?: any) => void;
    menu?: any;

    onLoad: (length: number) => void;
    onDownload: (length: number) => void;
    onItemInvoked?: (item: TItem) => void;
    //onChangeStatus: (item: {id: number, newStatus: OfferStatus}) => void;
}

interface State<TItem> {
    isLoaded: boolean;
    isFull: boolean;
    items: TItem[];
}

export class LazyList<TItem> extends React.Component<Props<TItem>, State<TItem>> {
    //private _contextualMenu: ContextualMenu;
    private _selection: Selection;

    constructor(props: Props<TItem>) {
        super(props);
        this._handleContextualMenu = this._handleContextualMenu.bind(this);
        this.state = {
            isLoaded: false,
            isFull: false,
            items: []
        };
    }

    render() {
        return (
            <div>
                <MarqueeSelection selection={this._selection}>
                    <DetailsList
                        items={this.state.items}
                        columns={this.props.columns}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={this._onItemInvoked}
                        onItemContextMenu={(item?: TItem, index?: number, ev?: MouseEvent) => this._handleContextualMenu(item, ev)}
                        onRenderItemColumn={this._onRenderItemColumn}/>
                    {!this.state.isLoaded && <Spinner size={SpinnerSize.large}/>}
                </MarqueeSelection>
            </div>
        );
    }

    componentDidMount() {
        let length = this.state.items === undefined ? 0 : this.state.items.length;
        this.props.onLoad(length);
        window.addEventListener("scroll", this.onScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll.bind(this));
    }

    onScroll() {
        if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight)
            && this.state.isLoaded
            && !this.state.isFull) {
            let length = this.state.items === undefined ? 0 : this.state.items.length;
            this.props.onDownload(length);
        }
    }

    private _handleContextualMenu(item: TItem, event: MouseEvent) {
        if (event.type !== "contextmenu") {
            return;
        }
        if (this.props.menu) {
            this.props.menu.setState({
                isVisible: true,
                target: { x: event.clientX, y: event.clientY },
                item
            });
        }
    }

    private _onRenderItemColumn(item?: any, index?: number, column?: IColumn) {
        if (this.props.onRenderItemColumn) {
            this.props.onRenderItemColumn(item, index, column, this.props.menu);
        }
    }

    private _onItemInvoked(item: TItem) {
        if (this.props.onItemInvoked) {
            this.props.onItemInvoked(item);
        }
    }
}
