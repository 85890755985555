import { createAction } from "redux-actions";
import { KeyEditorActionTypes } from "./types";
import { IKeyItem, IProductType } from "../../../../models/interfaces";
import { CustomColorEdited } from "../customColorsDialog/types";

export const updateKeyItem = createAction(
    KeyEditorActionTypes.KEY_ITEM_EDIT,
    (item: IKeyItem) => item
);

export const addKeyItem = createAction(
    KeyEditorActionTypes.KEY_ITEM_ADD,
    (item: IKeyItem) => item
);

export const deleteKeyItem = createAction(
    KeyEditorActionTypes.KEY_ITEM_DELETE,
    (item: IKeyItem) => item
);

export const addProductType = createAction(
    KeyEditorActionTypes.PRODUCT_TYPE_ADD,
    (productType: IProductType) => productType
);

export const productTypeDismissMenu = createAction(
    KeyEditorActionTypes.PRODUCT_TYPE_DISMISS_MENU
);

export const productTypeShowMenu = createAction(
    KeyEditorActionTypes.PRODUCT_TYPE_SHOW_MENU,
    (item: IProductType, event: MouseEvent) => {
        let menu = { item, target: { x: event.clientX, y: event.clientY } };
        return menu;
    }
);

export const deleteProductType = createAction(
    KeyEditorActionTypes.PRODUCT_TYPE_DELETE
);

export const updateProductType = createAction(
    KeyEditorActionTypes.PRODUCT_TYPE_EDIT,
    (item: IProductType) => item
);

export const changeKey = createAction(
    KeyEditorActionTypes.KEY_ON_CHANGE,
    (key: any) => key
);

export const moveKeyItem = createAction(
    KeyEditorActionTypes.KEY_ITEM_MOVE,
    (indexes: { oldIndex: number, newIndex: number }) => indexes
);

export const loadKey = createAction(
    KeyEditorActionTypes.KEY_LOAD,
    (key: any) => key
);

export const updateCustomColors = createAction(
    KeyEditorActionTypes.KEY_UPDATE_CUSTOM_COLORS,
    (customColors: CustomColorEdited[]) => customColors
);
