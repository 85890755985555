import * as React from "react";
import { ConfirmDialog } from "../../../../components/dialogs";
import { autobind } from "@uifabric/utilities";
import { ContextualMenuItemType, ContextualMenu } from "office-ui-fabric-react";

interface IKeyItemValueMenuProps {
    isContextMenuVisible: boolean;
    withoutDelete: boolean;
    target: any;
    onDismiss: () => void;
    onDelete: () => void;
    onMark: (isMark: boolean) => void;
}

export default class KeyItemValueMenu extends React.Component<IKeyItemValueMenuProps, any> {
    render() {
        return (
            <div>
                {this.props.isContextMenuVisible 
                && <ContextualMenu
                    onDismiss={this.props.onDismiss}
                    target={this.props.target}
                    items={this._items()}/>
                }
                <ConfirmDialog
                    onConfirmed={() => this.props.onDelete()}
                    text="Czy na pewno chcesz usunąć tą wartość elementu klucza?"/>
            </div>
        );
    }

    @autobind
    private _items() {
        let items = [
            {
                key: "mark",
                name: "Oznacz jako...",
                iconProps: { iconName: "Accept" },
                subMenuProps: {
                    items: [
                        {
                            key: "div-1",
                            itemType: ContextualMenuItemType.Divider
                        },
                        {
                            key: "colorDepends",
                            name: "Zależny w wyliczeniu ceny od koloru",
                            iconProps: { iconName: "Color" },
                            onClick: () => this.props.onMark(true)
                        },
                        {
                            key: "div-2",
                            itemType: ContextualMenuItemType.Divider
                        },
                        {
                            key: "colorIndepends",
                            name: "Niezależny w wyliczeniu ceny od koloru",
                            iconProps: { iconName: "Color" },
                            onClick: () => this.props.onMark(false)
                        }
                    ],
                },
            }
        ];
        let deleteItem = {
            key: "delete",
            name: "Usuń",
            iconProps: { iconName: "Delete" },
            onClick: () => this.props.onDelete()
        };

        return this.props.withoutDelete
            ? items
            : [deleteItem, ...items];
    }
}
