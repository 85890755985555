import * as React from "react";
import "./react-datasheet.less";

export default class ComponentCell extends React.PureComponent<any, any> {
    timeout: any;

    constructor(props: any) {
        super(props);
        this.state = { updated: false };
    }

    componentWillUpdate(nextProps: any) {
        if (nextProps.value !== this.props.value) {
            this.setState({ updated: true });
            this.timeout = setTimeout(() => {
                this.setState({ updated: false });
            }, 700);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        let {
              row, col, readOnly, forceComponent, rowSpan, colSpan, width, overflow, value, className, editing, selected,
                    onMouseDown, onMouseOver, onDoubleClick, onContextMenu
            } = this.props;
        const style = { width };

        return (
            <td
                className={[
                    className, "cell", overflow,
                    editing && "editing", selected && "selected",
                    this.state.updated && "updated"
                ].filter(a => a).join(" ")}
                onMouseDown={() => onMouseDown(row, col)}
                onDoubleClick={() => onDoubleClick(row, col)}
                onMouseOver={() => onMouseOver(row, col)}
                onContextMenu={(e) => onContextMenu(e, row, col)} colSpan={colSpan || 1}
                rowSpan={rowSpan || 1}
                style={style}
            >
                {((editing && !readOnly) || forceComponent) ? this.props.component : value}
            </td>
        );
    }
}
