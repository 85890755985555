import * as React from "react";
import { Pivot, PivotItem } from "office-ui-fabric-react/lib/Pivot";
import OffersList from "../components/list/OffersList";
import { OfferActions } from "../actions";
import { connect } from "react-redux";
import { loadOffers, isLoading, downloadItems, clearItems } from "../store/offersList/actions";
import { Dispatch } from "redux";
import { autobind } from "@uifabric/utilities";
import "../styles/searchBox.less";
import CommandBar from "../components/list/CommandBar";
import Filters from "../components/list/Filters";
import { IOffersListFilters } from "../store/offersListFilters/types";
import { setOnlyMy } from "../store/offersListFilters/actions";
import { Shortcut } from "@shopify/react-shortcuts";
import NavigationHelper from "../helpers/NavigationHelper";

interface Props {
    isLoaded: boolean;
    items: any[];
    dispatch: Dispatch<{}>;
    filtersAreVisible: boolean;
    filters: IOffersListFilters;
    onLoad: (items: any) => void;
    onDownload: (items: any) => void;
    isLoading: () => void;
    setOnlyMy: (onlyMy: boolean) => void;
    clearItems: () => void;
    search: (searchValue: string) => void;
}

class List extends React.Component<Props, any> {
    componentWillUnmount() {
        this.props.clearItems();
    }

    componentWillReceiveProps(props: any) {
        if (this.props.filters === props.filters) { return; }        
        this.onFiltersChange(props.filters);        
    }

    render() {
        const { onDownload, onLoad } = this.props;
        return (
            <div>
                <CommandBar/>
                <div className="body-container">
                    <Filters/>
                    <Pivot onLinkClick={this.onChangePivot}
                        initialSelectedIndex={this.getInitialSelectedIndex()}>
                        <PivotItem linkText="Moje">
                            <OffersList
                                onLoad={(length: number) => this.loadItems(onLoad, this.props.filters, length)}
                                onDownload={(length: number) => this.loadItems(onDownload, this.props.filters, length)}/>
                        </PivotItem>
                        <PivotItem linkText="Wszystkie">
                            <OffersList
                                onLoad={(length: number) => this.loadItems(onLoad, this.props.filters, length)}
                                onDownload={(length: number) => this.loadItems(onDownload, this.props.filters, length)}/>
                        </PivotItem>
                    </Pivot>
                </div>
                <Shortcut
                    ordered={["/", "n"]}
                    onMatch={NavigationHelper.goToNewOffer}
                    ignoreInput={true}/>
            </div>
        );
    }

    @autobind
    private getInitialSelectedIndex() {
        return this.props.filters.onlyMy ? 0 : 1;
    }

    private onFiltersChange(filters: IOffersListFilters) {
        this.props.clearItems();
        this.loadItems(this.props.onLoad, filters, 0);
    }

    @autobind
    private onChangePivot(item: any) {
        this.props.setOnlyMy(item.key === ".0");
    }

    @autobind
    private loadItems(load: (items: any) => void, filters: IOffersListFilters, skip: number) {
        this.props.isLoading();
        OfferActions.getOffersList(skip, 30, filters)
            .then(x => load(x));
    }
}

function mapStateToProps(state: any) {
    return {
        items: state.offersList.items,
        offersCount: state.offersList.offersCount,
        isLoaded: state.offersList.isLoaded,
        filtersAreVisible: state.offersListFilters.isVisible,
        filters: state.offersListFilters.data
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        onDownload: (items: any) => dispatch(downloadItems(items)),
        onLoad: (items: any) => dispatch(loadOffers(items)),
        isLoading: () => dispatch(isLoading()),
        setOnlyMy: (onlyMy: boolean) => dispatch(setOnlyMy(onlyMy)),
        clearItems: () => dispatch(clearItems())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
