import GlobalSpinner from "../components/spinners/GlobalSpinner";

export default class SpinnerStore {
    private static spinner: GlobalSpinner = null;

    static componentDoMonut(spinner: GlobalSpinner) {
        SpinnerStore.spinner = spinner;
    }

    static showSpinner(label: string) {
        if (SpinnerStore.spinner === null) { return; }
        SpinnerStore.spinner.setState({ isVisible: true, label });
    }

    static hideSpinner() {
        if (SpinnerStore.spinner === null) { return; }
        SpinnerStore.spinner.setState({ isVisible: false });
    }
}
