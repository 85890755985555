import { Types, OfferEditorModel } from "./types";
import { Action } from "redux-actions";

const initState = new OfferEditorModel();

export const offerEditor = (state: OfferEditorModel = initState, action: Action<any>) => {
    switch (action.type) {
        case Types.ON_LOAD: {
            return { ...state, offer: action.payload };
        }
        case Types.TAGS_SUGGESTIONS_ON_LOAD: {
            return { ...state, tagsSuggestions: action.payload };
        }
        case Types.ADD_TAG: {
            return { ...state, offer: { ...state.offer, tags: state.offer.tags.concat(action.payload)} };
        }
        case Types.REMOVE_TAG: {
            let tagIndex = action.payload;
            let tags = state.offer.tags;
            tags.splice(tagIndex, 1);
            return { ...state, offer: { ...state.offer, tags } };
        }
        case Types.SET_NUMBER: {
            let offerNumber = action.payload;
            return { ...state, offer: { ...state.offer, offerNumber } };
        }
        case Types.SET_VERSION: {
            let rowVersion = action.payload;
            return { ...state, offer: { ...state.offer, rowVersion } };
        }
        case Types.CHANGE_CONTACT_PERSON: {
            return { ...state, offer: { ...state.offer, contactPersonId: action.payload } };
        }
        case Types.CHANGE_CUSTOMER: {
            return { ...state, offer: { ...state.offer, customerId: action.payload } };
        }
        case Types.CHANGE_PROJECT: {
            return { ...state, offer: { ...state.offer, projectId: action.payload } };
        }
        case Types.CHANGE_COMMENTS_VISIBILITY: {
            return { ...state, commentsAreVisible: action.payload };
        }
        case Types.CHANGE_INITIAL_COMMENT: {
            return { ...state, initialComment: action.payload };
        }
        case Types.SET_COMMENTS_COUNT: {
            return { ...state, commentsCount: action.payload };
        }
        case Types.CHANGE_CONTACT_INFO_VISIBILITY: {
            return { ...state, contactInfoIsVisible: action.payload};
        }
        default: {
            return state;
        }
    }
};
