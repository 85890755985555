import * as React from "react";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import "./filter.less";
import { Callout, Label } from "office-ui-fabric-react/lib";
import OutsideClickHandler from "react-outside-click-handler";
import { FilterLabel } from "./FilterLabel";
import { Icon, autobind, Spinner, SpinnerSize } from "office-ui-fabric-react";

export interface FilterProps {
    name: string;
    isLoading?: boolean;
    onClear?: () => void;
}

export interface FilterState {
    label: string;
    menuIsVisible: boolean;
}

const defaultWidth = 200;

export class Filter<TProps extends FilterProps, TState extends FilterState> extends React.Component<TProps, TState> {
    private _filterButton: HTMLElement | null;

    render() {
        let element: JSX.Element = <IconButton iconProps={{ iconName: "ChevronDownMed" }}/>;
        if (this.props.isLoading) {
            element = <Spinner className="filter-spinner" size={SpinnerSize.small}/>;
        }
        return (
            <div className="filter"
                onClick={() => this._changeMenuVisibility()}
                ref={(ref) => this._filterButton = ref}>
                <FilterLabel label={this.props.name} selectedLabel={this.state.label}/>
                {element}
                { this.state.menuIsVisible &&
                    <Callout target={this._filterButton}
                        calloutWidth={this.getWidth()}
                        isBeakVisible={false}
                        coverTarget={false}>
                        <OutsideClickHandler onOutsideClick={() => this._hide()}>
                            {this.renderBody()}
                            <div className="filter-commands">
                                <Label onClick={() => this._clearSelected()}>
                                    <Icon iconName="Clear" title="Wyczyść" />
                                    Wyczyść
                                </Label>
                            </div>
                        </OutsideClickHandler>
                    </Callout>
                }
            </div>
        );
    }

    protected renderBody(): JSX.Element {
        throw new Error("Abstract method");
    }

    protected _clearSelected(): void {
        throw new Error("Abstract method");
    }

    @autobind
    private _changeMenuVisibility() {
        if (this.props.isLoading) {
            return;
        }
        this.setState({...this.state, menuIsVisible: !this.state.menuIsVisible});
    }

    @autobind
    private _hide() {
        this.setState({...this.state, menuIsVisible: false});
    }

    protected getWidth() {
        return defaultWidth;
    }
}
