import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ProjectsList } from "../components/list/ProjectsList";

class List extends React.Component<any, any> {
    render() {
        return (
            <div>
                <div className="body-container">
                    <ProjectsList/>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state: any) {
    return {
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
