/// <reference path="./interfaces.d.ts"/>

import jwt_decode = require("jwt-decode");

class User implements IUser {
    constructor(
        public firstName: string,
        public surname: string,
        public id: string,
        public roles: string[]) {
    }

    isInRole(...roles: string[]): boolean {
        return this.roles !== undefined
            && this.roles !== null
            && roles.some(role => this.roles.includes(role));
    }
}

export class AuthContext {
    private static contextInstance: AuthContext;
    private adalContext: adal.AuthenticationContext;
    private authData: OAuthData;

    private constructor(private options: IAuthOptions, private requireLogin?: boolean) {
        this.authData = new OAuthData();

        this.adalContext = new AuthenticationContext({
            tenant: options.tenant,
            clientId: options.clientId,
            redirectUri: options.redirectUri,
            extraQueryParameter: "nux=1",
            endpoints: options.endpoints
        });

        this.init();
    }

    login() {
        this.adalContext.login();
    }

    logout() {
        this.adalContext.logOut();
        this.authData = new OAuthData();
    }

    getBearerToken(resource: string) {
        return this.adalContext.getCachedToken(resource);
    }

    getResourceForEndpoint(endpoint: string) {
        return this.adalContext.getResourceForEndpoint(endpoint);
    }

    acquireToken(resource: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.adalContext.acquireToken(resource, (message, token) => {
                if (message) {
                    reject(message);
                }
                else {
                    resolve(token);
                }
            });
        });
    }

    get user(): IUser {
        let { given_name, family_name, unique_name }  = this.authData.profile;
        return new User(given_name, family_name, unique_name, this.authData.roles);
    }

    get isAuthenticated(): boolean {
        return this.authData.isAuthenticated;
    }

    public static get instance(): AuthContext { return AuthContext.contextInstance; }
    public static init(options: IAuthOptions, requireLogin?: boolean) {
        AuthContext.contextInstance = new AuthContext(options, requireLogin);
    }

    private init() {
        let hash = window.location.hash;
        if (hash === "#/error=access_denied") { 
            location.href = "/AccessDenied";
        }
        if (this.adalContext.isCallback(hash)) {
            this.adalContext.handleWindowCallback();
            history.pushState("", document.title, window.location.pathname);
        }

        this.loadUserFromCache();

        if (this.requireLogin === true && !this.authData.isAuthenticated) {
            this.login();
        }
    }

    private loadUserFromCache() {
        let user = this.adalContext.getCachedUser();
        if (user == null || user === undefined) {
            return;
        }

        let authData = new OAuthData();
        authData.isAuthenticated = true;
        authData.userName = user.userName;
        authData.profile = user.profile;

        let token = this.adalContext.getCachedToken(this.options.clientId);
        if (token === null) {
            authData.isAuthenticated = false;
            return;
        }
        let p: any = jwt_decode(token);
        authData.roles = p.roles;
        authData.loginError = this.adalContext.getLoginError();
        // mozna pobrać więcej danych z profilu
        this.authData = authData;
    }
}

export class OAuthData {
    isAuthenticated: boolean = false;
    userName: string = "";
    loginError: string = "";
    profile: IProfile = null;
    roles: string[] = [];
}
