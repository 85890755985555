interface Array<T> {
    equal(this: Array<T>, array: Array<T>): boolean;
}

Array.prototype.equal = function(this, array) {
    if (this.length !== array.length) {
        return false;
    }
    return JSON.stringify(this.slice().sort()) === JSON.stringify(array.slice().sort());
};
