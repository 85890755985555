import DataSheet from "../../../../components/Datasheet/DataSheet";
import * as React from "react";
import { connect } from "react-redux";
import { costLoad, costChangeProductType, costChangeCost, costChangeKey } from "../../store/costs/actions";
import ProductKeyActions from "../../actions/ProductKeyActions";
import { ProductTypeCost, CostGridCell, KeyCost } from "../../store/costs/types";
import { Pivot, PivotItem } from "office-ui-fabric-react";
import { RouteComponentProps } from "react-router";
import { FormBottomPanel } from "../../../../components";
import NavigationHelper from "../../helpers/NavigationHelper";

interface IEditorProps {
    costs: CostGridCell[][];
    productTypesCosts: ProductTypeCost[];
    currentProductType: number;
    keyCosts: KeyCost;
    isOverlayVisible: boolean;
    costLoad: (key: any) => void;
    changeProductType: (id: number) => void;
    changeKeyCost: (key: KeyCost) => void;
    onChange: (modifiedCell: CostGridCell, row: number, col: number, value: number) => void;
}

class Editor extends React.Component<IEditorProps & RouteComponentProps<any>, any> {
    constructor(props: IEditorProps & RouteComponentProps<any>) {
        super(props);
        this.state = { isOverlayVisible: true };
    }

    componentWillMount() {
        ProductKeyActions.getKeyWithCosts(this.props.match.params.keyId)
            .then(x => {
                this.props.costLoad(x);
                this.setState({ isOverlayVisible: false });
            });
    }

    render() {
        return (
            <div>
                <Pivot onLinkClick={x => this.props.changeProductType(Number(x.props.itemID))}>
                    {this.renderProductTypePivot()}
                </Pivot>
                <FormBottomPanel
                    onCancel={() => NavigationHelper.goToKeysList()}
                    onSave={() => this.saveCosts()}/>
            </div>
        );
    }

    renderProductTypePivot() {
        return this.props.productTypesCosts
            .map(x => {
                return (
                    <PivotItem linkText={x.symbol} itemID={x.id.toString()} key={`pt-${x.id}`}>
                        <DataSheet
                            data={this.props.costs}
                            onChange={(modifiedCell: any, row: number, col: number, value: string) =>
                                this.onChangeCost(modifiedCell, row, col, Number(value))
                            }
                            valueRenderer={(cell: any) => cell.value}/>
                    </PivotItem>
                );
            });
    }

    saveCosts() {
        this.setState({ isOverlayVisible: true });
        ProductKeyActions.updateCosts(this.props.keyCosts)
            .then(x => {
                this.onSaveEnd(x);
                this.setState({ isOverlayVisible: false });
            });
    }
    
    private onSaveEnd(request: any) {
        if (request.response.ok) {
            NavigationHelper.goToKeysList();
        }
    }

    onChangeCost(modifiedCell: CostGridCell, row: number, col: number, value: number) {
        value = Number.isNaN(value) ? 0 : value;
        this.props.onChange(modifiedCell, row, col, value);
    }
}

function mapStateToProps(state: any) {
    return {
        keyCosts: state.costEditorReducer.key,
        productTypesCosts: state.costEditorReducer.key.productTypes,
        costs: state.costEditorReducer.costs,
        currentProductType: state.costEditorReducer.currentProductType,
        isOverlayVisible: state.costEditorReducer.isOverlayVisible
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        costLoad: (key: any) => dispatch(costLoad(key)),
        changeProductType: (id: number) => dispatch(costChangeProductType(id)),
        changeKeyCost: (key: KeyCost) => dispatch(costChangeKey(key)),
        onChange: (modifiedCell: CostGridCell, row: number, col: number, value: number) => dispatch(costChangeCost(modifiedCell, row, col, value))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
