import * as React from "react";
import { Route, Switch } from "react-router";
import ProjectsList from "../modules/project/containers/List";
import OfferEditor from "../modules/offers/containers/Editor";
import OffersList from "../modules/offers/containers/List";
import OffersPreview from "../modules/offers/containers/Preview";
import { Header } from "./Header";
import AccessDenied from "./AccessDenied";
import NotFound from "../containers/NotFound";
import KeysList from "../modules/admin/containers/keys/List";
import TagsList from "../modules/admin/containers/tags/List";
import RalColorList from "../modules/admin/containers/ralColors/List";
import KeyEditor from "../modules/admin/containers/keys/Editor";
import CostEditor from "../modules/admin/containers/costs/Editor";
import AuthRoute from "../components/AuthRoute";
import AccessDeniedToApp from "./AccessDeniedToApp";
import { Roles } from "../tools/auth";

export default class Rooter extends React.Component<any, any> {
    render() {
        return (
            <div>
                <Header/>
                <Switch>
                    <Route exact path="/" component={OffersList}/>
                    <Route path="/id_token=*" component={OffersList}/>
                    <Route path="/offer/add" component={OfferEditor}/>
                    <Route path="/offer/:offerId/edit" component={OfferEditor}/>
                    <Route path="/offer/:offerId/read" component={OffersPreview}/>
                    <Route path="/offers" component={OffersList}/>
                    <Route path="/projects" component={ProjectsList}/>
                    <AuthRoute path="/admin/keys" component={KeysList} roles={[Roles.Administrator, Roles.AdminCostsEditor]}/>
                    <AuthRoute path="/admin/key/:keyId/edit" component={KeyEditor} roles={[Roles.Administrator]}/>
                    <AuthRoute path="/admin/key/:keyId/costs/edit" component={CostEditor} roles={[Roles.Administrator, Roles.AdminCostsEditor]}/>
                    <AuthRoute path="/admin/key/add" component={KeyEditor} roles={[Roles.Administrator]}/>
                    <AuthRoute path="/admin/rals" component={RalColorList} roles={[Roles.Administrator, Roles.AdminCostsEditor]}/>
                    <AuthRoute path="/admin/tags" component={TagsList} roles={[Roles.Administrator, Roles.AdminCostsEditor]}/>
                    <Route path="/AccessDeniedToApp" component={AccessDeniedToApp}/>
                    <Route path="/AccessDenied" component={AccessDenied}/>
                    <Route component={NotFound}/>
                </Switch>
            </div>
        );
    }
}
