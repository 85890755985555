export default class MathHelper {
    static round(value: number): number {
        return Math.round(value * 100) / 100;
    }

    static correctOr0(value: number) {
        return value != null && !isNaN(value) ? value : 0;
    }

    static numberOrNull(value: any) : number {
        if (value === null) {
            return null;
        }
        return Number(value);
    }
}
