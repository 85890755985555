import { IEvent, errorModel, succesModel } from "../NotificationModels";
import { Event } from "./Event";

export class TagDeleted extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: `Usunięto tag ${event.body.name}`
            }
        };
    }

    protected onError(event?: IEvent) {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się usunąć tagu"
            }
        };
    }
}

export class TagUpdated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: `Zaktualizowano tag ${event.body.name}`
            }
        };
    }

    protected onError(event?: IEvent) {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się zaktualizować tagu"
            }
        };
    }
}