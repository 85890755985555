import * as React from "react";
import CustomPosition from "./positions/CustomPosition";
import Position from "./positions/Position";
import OfferDetails from "./OfferDetails";
import OfferModel from "../../models/OfferModel";
import { NumberInput } from "../../../../components";
import DisplayHelper from "../../../../helpers/DisplayHelper";
import { IKeyMetadata } from "../../models/interfaces";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { Request } from "../../../../tools/requests";
import PositionModel from "../../models/PositionModel";
import KeyModel from "../../models/KeyModel";
import "../../../../styles/tables.less";
import TextHelper from "../../helpers/TextHelper";
import PositionContextualMenu from "./positions/PositionContextualMenu";
import { IPoint, autobind } from "@uifabric/utilities";
import { Pivot, PivotItem } from "office-ui-fabric-react";
import { RichTextEditor } from "../../../../components/editor/RichTextEditor";
import { IProductType } from "../../../../models/interfaces";
import { ProductTypesActions, OfferActions, ProductKeyActions } from "../../actions";

interface State {
    edited: boolean;
}

interface Props {
    model: OfferModel;
    onChange: (edited: boolean) => void;
}

export default class Offer extends React.Component<Props, State> {
    private _contextualMenu: PositionContextualMenu;
    private _discountInCurrency: NumberInput;
    private _discountPercentage: NumberInput;
    private _productKeyMetadata: IKeyMetadata[] = [];
    private _productTypes: IProductType[] = [];

    model: OfferModel;

    get max_positions(): number { return 30000; }

    constructor(props: any) {
        super(props);
        this.model = this.props.model;
        this.model.subscribe(() => this._onChange());
        this.state = { edited: false };
        this._productKeyMetadata = [];
        this._loadTypes();
        //todo this._loadMetadata(); load top 5?
    }

    componentWillReceiveProps(nextProps: Props) {
        this.model = nextProps.model;
        this._onChange();
        this.setState({ edited: false });
    }

    addPosition(index: number = undefined) {
        this.model.addPosition(null, false, index);
    }

    addCustomPosition(index: number = undefined) {
        ProductKeyActions.getCustomKey()
            .then(key => {
                let position = PositionModel.create();
                let productTypeId = key.productTypes[0].id;
                position.isCustom = true;
                position.productTypeId = productTypeId;
                position.key = KeyModel.create(productTypeId, key.id, key.items);
                this.model.addPosition(position, true, index);
            });
    }

    render() {
        let positions = this.renderPositions();
        return (
            <div>
                <OfferDetails/>
                <Pivot>
                    <PivotItem headerText="Pozycje">
                        {positions}
                    </PivotItem>
                    <PivotItem headerText="Notatki">
                        <RichTextEditor
                            content={this.model.notes}
                            onChange={this._onNotesChanged}/>
                    </PivotItem>
                </Pivot>
            </div>
        );
    }

    @autobind
    private duplicateToCustom(position: PositionModel) {
        ProductKeyActions.getCustomKey()
            .then(key => {
                OfferActions.duplicatePositionToCustom(position)
                    .then(x => {
                        let newPosition = position.clone();
                        let index = this.model.positions.indexOf(position);
                        let productTypeId = key.productTypes[0].id;
                        newPosition.isCustom = true;
                        newPosition.productTypeId = productTypeId;
                        newPosition.key = KeyModel.create(productTypeId, key.id, key.items);
                        newPosition.key.items[0].onValueChange({value: x});
                        this.model.addDuplicate(newPosition, index);
                    });
            });
    }

    private renderPositions() {
        let no = 0;
        let positions = this.model.positions.map(position => {
            no++;
            let key = `position[${position.id}]`;   
            if (position.isCustom) {
                return (
                    <CustomPosition
                        key={key}
                        model={position}
                        no={no}
                        onContextMenu={this._showPositionContextualMenu}/>
                );
            }
            return (
                <Position
                    key={key}
                    model={position}
                    no={no}
                    getMetadata={this._loadKey}
                    productTypes={this._productTypes}
                    onContextMenu={this._showPositionContextualMenu}/>
            );
        }, this);
        if (positions.length === 0) {
            return <MessageBar className="positions-empty">Oferta nie zawiera pozycji. Dodaj nową.</MessageBar>;
        }

        let currencyIso = this.props.model.currencyIso;
        return (
            <div className="positions">
                <PositionContextualMenu
                    ref={r => this._contextualMenu = r}
                    onDelete={this._onDelete}
                    onCopy={this._onCopy}
                    onCopyToCustom={this.duplicateToCustom}
                    onAddNew={this._onAddNew}/>
                <table className="table border bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th className="min-width400">Pozycja</th>
                            <th>Uwagi</th>
                            <th>Liczba [szt]</th>
                            <th>Cena netto [{currencyIso}]</th>
                            <th>Rabat [%]</th>
                            <th>Cena netto po rabacie [{currencyIso}]</th>
                            <th>Wartość netto [{currencyIso}]</th>
                        </tr>
                    </thead>
                    <tbody>
                        {positions}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>Suma:</td>
                            <td>{DisplayHelper.fixed(this.model.quantitySum, 0)}</td>
                            <td></td>
                            <td></td>
                            <td>Wartość rabatu [%]:</td>
                            <td>
                                <NumberInput
                                    ref={discountPercentage => this._discountPercentage = discountPercentage}
                                    className="text-right"
                                    value={this.model.discountPercentage}
                                    onChange={this.model.changeDiscountInPercentage.bind(this.model)}/>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Wartość rabatu [{currencyIso}]:</td>
                            <td>
                                <NumberInput
                                    ref={discountInCurrency => this._discountInCurrency = discountInCurrency}
                                    className="text-right"
                                    value={this.model.discountInCurrency}
                                    onChange={this.model.changeDiscountInCurrency.bind(this.model)}/>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Suma [{currencyIso}]:</td>
                            <td>{DisplayHelper.fixed(this.model.sum, 0)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
    
    @autobind
    private _loadKey(keyId: number) {
        let keys = this._productKeyMetadata.filter(m => m.id == keyId);
        if (keys.length > 0) {
            return Promise.resolve(keys[0]);
        }

        //TODO założyć że nie dostajmy symbolu tylko identyfikator klucza
        // wówczas sprawdzamy dodajemy do kolejcji metadaynch wpisu z id klucza ale bez metadanych
        // jak się pojawią to nadpisujemy
        // jeśli już klucz istniej (np. ktoś przy drugiej pozycji też wybrał ten sam) to pomijamy pobieranie.

        // jak się pojawi to trzeba zrobić odświeżenie na całej liście by pozycje dla których się klucz pojawił wyrenderowąły się w pełni
        // + trzeba założyć że TypyProduków mają pobrane od razu też klucz by można było po wybrniu symbolu łatwo rozeznać który klucz to jest i co z nim zrobić

        return Request.query<IKeyMetadata>("ReadProductKey", {keyId})
            .then(key => {
                this._productKeyMetadata = this._productKeyMetadata.concat(key);
                return key;
            });
    }

    private _onChange() {
        let discountPercentage = this.model.discountPercentage.toString();
        let discountInCurrency = this.model.discountInCurrency.toString();

        if (this._discountPercentage !== null
            && TextHelper.dotOrComma(discountPercentage, this._discountPercentage.state.value)) {
            this._discountPercentage.setState({ value: discountPercentage });
        }

        if (this._discountInCurrency !== null
            && TextHelper.dotOrComma(discountInCurrency, this._discountInCurrency.state.value)) {
            this._discountInCurrency.setState({ value: discountInCurrency });
        }

        this.setState({ edited: true });
        this.props.onChange(true);
    }

    @autobind
    private _showPositionContextualMenu(item: PositionModel, target: IPoint | HTMLElement) {
        this._contextualMenu.show(item, target);
    }

    @autobind
    private _onAddNew(item: PositionModel, before: boolean, custom: boolean) {
        let index = this.model.positions.indexOf(item);
        if (!before) {
            index++;
        }
        if (custom) {
            this.addCustomPosition(index);
        }
        else {
            this.addPosition(index);
        }
    }

    @autobind
    private _onDelete(item: PositionModel) {
        this.model.delete(item);
    }

    @autobind
    private _onCopy(item: PositionModel) {
        this.model.copyPosition(item);
    }

    @autobind
    private _onNotesChanged(notes: string) {
        this.model.notes = notes;
    }

    private _loadTypes() {
        ProductTypesActions.getProductTypes()
            .then(pt => {
                this._productTypes = pt;
                this.setState({ edited: true });
            });
    }
}
