import * as React from "react";
import { Persona, IPersonaStyles } from "office-ui-fabric-react/lib/Persona";
import { AuthContext, Roles, RolesHelper } from "../../tools/auth";
import { ContextualMenu, ContextualMenuItemType, PersonaSize, getTheme } from "office-ui-fabric-react";
import { MouseEvent } from "react";
import NavigationHelper from "../../modules/admin/helpers/NavigationHelper";

export class Person extends React.Component<any, any> {
    private _styles: Partial<IPersonaStyles>;

    constructor(props: any) {
        super(props);
        this.state = { isContextMenuVisible: false };
    }

    componentWillMount() {
        let theme = getTheme();
        this._styles = {
            root: [
                {
                    selectors: {
                        ":hover": {
                            selectors: {
                                $primaryText: {
                                    color: theme.semanticColors.menuItemTextHovered
                                }
                            }
                        }
                    }
                }],
            primaryText: 
            {
                color: theme.semanticColors.menuItemText
            },
            secondaryText: {
                color: theme.semanticColors.menuItemText,
                selectors: {
                    ":hover": {
                        color: theme.semanticColors.menuItemText
                    }
                }
            }
        };
    }

    render() {
        let user = AuthContext.instance.user;
        return (
            <div>
                <Persona
                    size={PersonaSize.size40}
                    text={user.firstName + " " + user.surname}
                    secondaryText={user.id.substring(user.id.indexOf("#") + 1)}
                    onClick={(ev) => this._handleOnClick(ev)}
                    onContextMenu={(ev) => this._handleOnClick(ev)}
                    styles={this._styles}/>
                {this.state.isContextMenuVisible &&
                    <ContextualMenu
                        target={document.querySelector(this.props.selector) as HTMLElement}
                        onDismiss={() => this.setState({ isContextMenuVisible: false })}
                        items={RolesHelper.filter(this.items)}/>
                }
            </div>
        );
    }

    private _handleOnClick(ev: MouseEvent<any>) {
        ev.preventDefault();
        this.setState({ point: { x: ev.currentTarget.context, y: ev.pageY }, isContextMenuVisible: true });
    }

    private items = [
        {
            key: "admin",
            name: "Administracja",
            iconProps: { iconName: "AdminALogoInverse32" },
            roles: [Roles.AdminCostsEditor, Roles.Administrator],
            onClick: () => NavigationHelper.goToKeysList()
        },
        {
            key: "divider_1",
            itemType: ContextualMenuItemType.Divider,
            roles: [Roles.AdminCostsEditor, Roles.Administrator]
        },
        {
            key: "logout",
            iconProps: {
                iconName: "OutOfOffice"
            },
            onClick: () => AuthContext.instance.logout(),
            name: "Wyloguj"
        }
    ];
}
