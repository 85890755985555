import * as React from "react";

interface FilterLabelProps {
    selectedLabel: string;
    label: string;
}

export class FilterLabel extends React.Component<FilterLabelProps, any> {
    private readonly Label: string = "Wybierz";

    render() {
        if (this.props.label !== undefined
                && this.props.selectedLabel !== undefined
                && this.props.selectedLabel !== "") {
            return (
                <p className="filter-label">{this.props.label}: <b>{this.props.selectedLabel}</b></p>
            );
        }

        let label = this.props.label !== undefined
            ? this.props.label
            : this.Label;

        return <p className="filter-label">{label}</p>;
    }
}
