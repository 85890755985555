import { Action } from "redux-actions";
import { OffersListTemplatesState, OffersListTemplatesActionTypes } from "./types";

const initState = new OffersListTemplatesState();

export const offersListTemplates = (state: OffersListTemplatesState = initState, action: Action<any>) => {
    switch (action.type) {
        case OffersListTemplatesActionTypes.SET_TEMPLATES: {
            return {
                ...state,
                isLoaded: true,
                templates: action.payload
            };
        }
        default: {
            return state;
        }
    }
};
