import { ItemType, AffectsPriceType } from "../models/Enums";

export const itemTypes = [
    {id: ItemType.RAL, name: "Kolor z palety RAL"},
    {id: ItemType.SimpleChoice, name: "Lista wartości"},
    {id: ItemType.Const, name: "Stała"},
    {id: ItemType.Number, name: "Liczba"}
];

export const affectsPriceType = [
    {id: AffectsPriceType.Adds, name: "Dodawanie"},
    {id: AffectsPriceType.None, name: "Brak"},
    {id: AffectsPriceType.Base, name: "Podstawa ceny"},
    {id: AffectsPriceType.BaseOption, name: "Opcja podstawy ceny"},
    {id: AffectsPriceType.MultipliesBySelf, name: "Mnożenie przez siebie"},
    {id: AffectsPriceType.MultipliesByFactor , name: "Mnożenie przez współczynik"},
];
