import * as React from "react";
import ProductKey from "../key/ProductKey";
import { PositionBase, Props, State } from "./PositionBase";
import { NumberInput } from "../../../../../components";

export default class CustomPosition extends PositionBase<Props, State> {    
    protected renderNetUnitPrice() {
        return (
            <NumberInput
                value={this.props.model.netUnitPrice}
                onChange={this.onNetUnitPriceChange.bind(this)}/>
        );
    }

    protected renderPositionName() {
        return (
            <ProductKey
                key={`${this.props.model.id}-productType`}
                model={this.props.model.key}
                onChange={this.onProductKeyChange.bind(this)}/>
        );
    }

    private onNetUnitPriceChange(newValue: number) {
        this.props.model.changeNetUnitPrice(newValue);
    }
}
