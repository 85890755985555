import * as React from "react";
import NavigationHelper from "../../helpers/NavigationHelper";
import { autobind } from "office-ui-fabric-react";
import CommentBadge from "../comments/CommentBadge";
import { renderBadgeButton } from "../../../../components/commands";
import OfferStatusService from "../../services/OffersStatusService";
import { PrintActions } from "../../actions";
import OfferReadModel from "../../models/OfferReadModel";
import { ICommandBarItemProps, IContextualMenuItem, CommandBar as FabricCommandBar } from "office-ui-fabric-react/lib";
import { OfferStatus } from "../../models/Enums";

interface Props {
    model: OfferReadModel;
    onClickStatusChange: (status: number) => void;
    onShowComments: () => void;
    onShowContactInfo: () => void;
}

interface State {
    comentsCount?: number;
    statusesAreImported: boolean;
}

export class CommandBar extends React.Component<Props, State> {
    private _offerStatusService: OfferStatusService = new OfferStatusService();

    constructor(props: any) {
        super(props);
        this.state = { comentsCount: undefined, statusesAreImported: false };
    }
    
    componentWillMount() {        
        this._offerStatusService.init(() => this.setState({...this.state, statusesAreImported: true}));
    }

    render() {
        return (
            <div>
                <FabricCommandBar
                    items={this._commandItems()}
                    farItems={this._farItems()}
                    buttonAs={renderBadgeButton}>
                </FabricCommandBar>
                <CommentBadge threadId={this.props.model.threadId} onCountChange={this._onCommentsCountChange}/>
            </div>
        );
    }

    @autobind
    private _onCommentsCountChange(count: number) {
        this.setState({...this.state, comentsCount: count});
    }

    private _commandItems() : ICommandBarItemProps[] {
        let items = [
            {
                name: "Edycja",
                key: "edit",
                iconProps: { iconName: "Edit" },
                disabled: this.props.model.status !== OfferStatus.InEditing,
                onClick: () => NavigationHelper.goToOfferEditor(this.props.model.id)
            }
        ];

        if (!this._offerStatusService.checkIfAnyStatuesAreAvailableFor(this.props.model.status)) {
            return items;
        }

        return [
            ...items, 
            {
                key: "changeStatus",
                name: "Zmień status na...",
                iconProps: { iconName: "BranchCommit" },
                subMenuProps: this._offerStatusService.getSubMenuToSetStatus(this.props.model.status, item => this._handleOnStatusChangeClick(item))
            }
        ];
    }

    private _farItems() {
        let { id, offerNumber } = this.props.model;

        let items = [
            {
                key: "print",
                iconProps: { iconName: "Print" },
                name: "Drukuj",
                onClick: () => PrintActions.print(id, offerNumber)
            },
            {
                title: "Informacje o kontakcie (/+I)",
                name: "Kontakt",
                key: "show-contact-info",
                iconProps: { iconName: "Contact" },
                onClick: this.props.onShowContactInfo
            },
            {
                title: "Komentarze (/+C)",
                key: "show-comments",
                iconProps: { iconName: "Comment"},
                onClick: this.props.onShowComments,
                badge: this.state.comentsCount,
                badgeIsLoading: this.state.comentsCount === undefined
            }
        ];

        return items;
    }

    private _handleOnStatusChangeClick(item: IContextualMenuItem) {
        let statusToSetStr = item.key.split("-")[1];
        let statusToSet = +statusToSetStr;
        this.props.onClickStatusChange(statusToSet);
    }
}
