import { Request } from "../../../tools/requests";
import { Tag } from "../models/interfaces";

export default class TagsActions {
    static getAll() {
        return Request.query<Tag[]>("ReadAllTags");
    }

    static deleteTag(tag: Tag) {
        return Request.command("DeleteTag", { id: tag.id });
    }

    static updateTag(tag: Tag) {
        return Request.command("UpdateTag", { id: tag.id, name: tag.name });
    }
}
