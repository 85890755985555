import * as React from "react";
import { DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { Label } from "office-ui-fabric-react/lib/Label";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Dialog } from "../../../../components/dialogs";
import "../../styles/editor.less";
import { Select } from "../../../../components";
import { IKeyItem } from "../../../../models/interfaces";
import ConstItem from "./items/ConstItem";
import NumberItem from "./items/NumberItem";
import SimpleChoiceItem from "./items/SimpleChoiceItem";
import { ItemType, AffectsPriceType } from "../../../../models/Enums";
import { itemTypes, affectsPriceType } from "../../../../constants/arrays";
import { connect, Dispatch } from "react-redux";
import { addKeyItem, updateKeyItem } from "../../store/keyEditor/actions";
import { keyItemOnChange, keyItemDismissDialog, keyItemValueAdd, keyItemValueDelete } from "../../store/keyItemMenu/actions";
import AffectPriceChoice from "./items/AffectPriceChoice";

interface IKeyItemDialogProps {
    onAddKeyItemValue: () => any;
    onDeleteValue: (index: number) => any;
    onConfirmed: (item: IKeyItem) => any;
    onChange: (item: IKeyItem) => any;
    onDismiss: () => void;
    onAdd: (item: IKeyItem) => any;
    onEdit: (item: IKeyItem) => any;
    item: IKeyItem;
    showDialog: boolean;
    isEditing: boolean;
}

class KeyItemDialog extends React.Component<IKeyItemDialogProps, any> {
    render() {
        if (this.props.item === undefined) { return null; }
        let { onChange, item } = this.props;
        return (
            <div>
                <Dialog
                    hidden={!this.props.showDialog}
                    modalProps={{ isBlocking: false, containerClassName: "ms-dialogMainOverride" }}
                    dialogContentProps={{ type: DialogType.normal, className: "text dialog-size" }}
                    onDismiss={this.props.onDismiss}
                    title="Element klucza produktu">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg8">
                                <TextField
                                    inputClassName="text-filed-height"
                                    label="Nazwa"
                                    defaultValue={item === undefined ? "" : item.name}
                                    onChanged={(name) => onChange({ ...item, name })}
                                    required/>
                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4">
                                <div className="inline without-buttons">
                                    <Label> Typ elementu  </Label>
                                    <Select noResultsText="Brak typow"
                                        className="without-buttons"
                                        placeholder="Wybierz"
                                        selectedId={item.type}
                                        onChange={(type) =>
                                            onChange({
                                                ...item, type,
                                                affectsPrice: type === ItemType.Const
                                                    ? AffectsPriceType.None : item.affectsPrice
                                            })
                                        }
                                        options={itemTypes}
                                        labelKey="name"
                                        isLoading={false}/>
                                </div>
                            </div>
                        </div>
                        {this.renderKeyItem()}
                    </div>
                    <DialogFooter>
                        <PrimaryButton
                            onClick={() => this._handleConfirmed()}
                            text="Zapisz"
                            disabled={!this.props.showDialog}/>
                        <DefaultButton
                            onClick={this.props.onDismiss}
                            text="Anuluj"
                            disabled={!this.props.showDialog}/>
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }

    private _setupAffectPrice() {
        switch (this.props.item.type) {
            case ItemType.RAL: {
                return affectsPriceType
                    .filter(x => x.id === AffectsPriceType.MultipliesByFactor
                        || x.id === AffectsPriceType.None
                    );
            }
            case ItemType.SimpleChoice: {
                return affectsPriceType
                    .filter(x => x.id === AffectsPriceType.Base
                        || x.id === AffectsPriceType.BaseOption
                        || x.id === AffectsPriceType.Adds
                        || x.id === AffectsPriceType.None
                    );
            }
            case ItemType.Number: {
                return affectsPriceType
                    .filter(x => x.id === AffectsPriceType.MultipliesBySelf
                        || x.id === AffectsPriceType.None
                    );
            }
        }
    }

    private _handleConfirmed() {
        let { type, name } = this.props.item;
        if (name === undefined
            || name === null
            || name === ""
            || type === undefined) {
            return;
        }
        if (this.props.isEditing) { this.props.onEdit(this.props.item); }
        else { this.props.onAdd(this.props.item); }
        this.props.onDismiss();
    }

    renderKeyItem() {
        if (this.props.item.type === undefined) { return; }
        let { item, onChange, onAddKeyItemValue, onDeleteValue } = this.props;
        switch (item.type) {
            case ItemType.Const: {
                return <ConstItem
                    item={item}
                    setupAffectPrice={this._setupAffectPrice()}
                    onChange={onChange}/>;
            }
            case ItemType.RAL: {
                return (
                    <div className="ms-Grid-row">
                        <AffectPriceChoice
                            setupAffectPrice={this._setupAffectPrice()}
                            onChange={onChange}
                            item={item}/>
                    </div>
                );
            }
            case ItemType.Number: {
                return <NumberItem
                    item={item}
                    setupAffectPrice={this._setupAffectPrice()}
                    onChange={onChange}/>;
            }
            case ItemType.SimpleChoice: {
                return <SimpleChoiceItem
                    item={item}
                    onChange={onChange}
                    setupAffectPrice={this._setupAffectPrice()}
                    onAddKeyItemValue={onAddKeyItemValue}
                    onDeleteItemValue={(index) => onDeleteValue(index)}/>;
            }
        }
    }
}

function mapStateToProps(state: any) {
    return {
        item: state.keyItemMenuReducer.item,
        showDialog: state.keyItemMenuReducer.showDialog,
        isEditing: state.keyItemMenuReducer.isEditing
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        onAddKeyItemValue: () => dispatch(keyItemValueAdd()),
        onChange: (item: IKeyItem) => dispatch(keyItemOnChange(item)),
        onDismiss: () => dispatch(keyItemDismissDialog()),
        onAdd: (item: IKeyItem) => dispatch(addKeyItem(item)),
        onEdit: (item: IKeyItem) => dispatch(updateKeyItem(item)),
        onDeleteValue: (index: number) => dispatch(keyItemValueDelete(index))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyItemDialog);
