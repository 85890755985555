import * as React from "react";
import {IBadgeButtonProps} from "./IBadgeButtonProps";
import { CommandBarButton, Spinner } from "office-ui-fabric-react";

export const renderBadgeButton = (props: IBadgeButtonProps) => {
    return (
        <CommandBarButton
            {...props}
            styles={{
              ...props.styles,
            }}>
            { props.badgeIsLoading && (
                <Spinner/>
            )}
            { props.badge > 0 && (
                <b>{props.badge}</b>
            )}
        </CommandBarButton>
    );
  };
