import * as React from "react";
import Select, { components } from "react-select";
import { SelectStyles, SelectThemeGenerator } from "../../../../../components";
import {IKeyItemMetadata} from "../../../models/interfaces";
import {IKeyItemValue} from "../../../../../models/interfaces";
import "../../../styles/key/styles.less";
import { Fragment } from "react";

export interface IOption {
    value: string | number;
    label: string;
    title?: string;
}

interface ISelectKeyItemProps {
    value: string | number;
    metadata: IKeyItemMetadata;
    hint?: string;
    onChange: (value: IKeyItemValue) => void;
}

interface ISelectKeyItemState {
    value: IOption;
}

export default abstract class SelectKeyItemBase extends React.Component<ISelectKeyItemProps, ISelectKeyItemState> {
    private options: IOption[];

    constructor(props: ISelectKeyItemProps) {
        super(props);
        this.options = this.mapToOptions(this.props.metadata);
        let val = this.props.value === null ? null : this.props.value.toString();
        let value = this.props.metadata.availableValues.filter(x => x.value === val)[0];
        if (value === undefined) {
            value = this.props.metadata.availableValues.filter(x => x.isDefault)[0];
        }
        let defaultValue = value === undefined ? null : this.mapToOption(value);
        this.state = {value: defaultValue};
    }

    render() {
        return (
            <div className={this.selectClassName} title={this.state.value.title}>
                <Select
                    styles={SelectStyles}
                    placeholder="Wybierz"
                    noOptionsMessage={() => "Brak dostępnych wartości"}
                    options={this.options}
                    value={this.state.value}
                    onChange={this.handleSelectChange.bind(this)}
                    components={{
                        Option: this._option
                    }}
                    theme={SelectThemeGenerator.prepareTheme}/>
            </div>
        );
    }

    private mapToOptions(metadata: IKeyItemMetadata): IOption[] {
        return metadata.availableValues.map(v => this.mapToOption(v), this);
    }

    private _option = (props: any) => {
        return (
            <Fragment>
                <components.Option {...props}>
                    <div className="react-select__option" title={props.data.title}>{props.data.label}</div>
                </components.Option>
            </Fragment>
        );
    }

    protected abstract handleSelectChange(newValue: IOption): void;
    protected abstract mapToOption(v: IKeyItemValue): IOption;
    protected abstract get selectClassName(): string;
}
