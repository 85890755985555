import * as React from "react";
import { Select } from "../../../../components";
import { CustomerActions } from "../../actions/CustomerActions";
import { IPersonListItem, IPerson } from "../../models/interfaces";
import { WorkerDialog } from "./WorkerDialog";
import { connect, Dispatch } from "react-redux";
import { onAddCustomerWorker, onDismissCustomerWorkerDialog, onEditCustomerWorker, onChangeCustomerWorker, onLoadCustomerWorkers } from "../../store/offerDetails/actions";
import "../../../../extensions/Number.Extensions";

interface Props {
    workerId: number;
    customerId: number;
    worker: IPerson;
    workers: IPersonListItem[];
    hidden: boolean;
    edited: boolean;
    title: string;
    onChangeCustomerWorker: (id: number) => void;
    onChange: (worker: IPerson) => void;
    onLoad: (workers: IPersonListItem[]) => void;
    onEdit: (worker: IPerson) => void;
    onDismiss: () => void;
    onAdd: () => void;
}

interface State {
    isLoading: boolean;
}

class WorkerSelect extends React.Component<Props, State>  {
    private select: Select;

    constructor(props: any) {
        super(props);
        this.state = { isLoading: false };
    }

    componentDidMount() {
        if (this.props.customerId <= 0) {
            return;
        }
        this._loadWorkers(this.props.customerId);
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.customerId === this.props.customerId) {
            return;
        }
        this._loadWorkers(nextProps.customerId);
    }

    render() {
        return (
            <div>
                <Select noResultsText="Brak pracownika" ref={select => this.select = select}
                    placeholder="Wybierz"
                    onAddClicked={this.props.onAdd}
                    onChange={this.props.onChangeCustomerWorker}
                    onEditClicked={this._onEditWorker.bind(this)}
                    options={this.props.workers}
                    labelKey="name"
                    buttonsAreVisible
                    withLoaded
                    disabled={!this.props.customerId.isExist()}
                    selectedId={this.props.workerId}
                    isLoading={this.state.isLoading}/>
                <WorkerDialog
                    {...this.props}
                    onSaved={this._onSaved.bind(this)}/>
            </div>
        );
    }

    private _onSaved(worker: IPersonListItem) {
        let workers = this.props.workers;
        let index = this.props.workers.findIndex(x => x.id === worker.id);
        if (index === -1) {
            this.props.onLoad([worker, ...workers]);
        }
        else {
            workers[index].name = worker.name;
            this.props.onLoad(workers);
        }
        this.props.onChangeCustomerWorker(worker.id);
    }

    private _loadWorkers(customerId: number) {
        this.setState({ isLoading: true });
        CustomerActions.getAllWorkers(customerId)
            .then(workers => {
                this.props.onLoad(workers);
                this.setState({ isLoading: false });
            });
    }

    private _onEditWorker() {
        let workerId = this.select.state.selected.id;
        CustomerActions.getWorker(workerId)
            .then(worker => {
                this.props.onEdit({ ...worker, customerId: this.props.customerId, id: this.props.workerId });
            });
    }
}

function mapStateToProps(state: any) {
    return {
        worker: state.offerDetails.customerWorker,
        workers: state.offerDetails.customerWorkers,
        hidden: state.offerDetails.customerWorkerDialogHidden,
        edited: state.offerDetails.edited,
        title: state.offerDetails.title,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        onEdit: (worker: IPerson) => dispatch(onEditCustomerWorker(worker)),
        onDismiss: () => dispatch(onDismissCustomerWorkerDialog()),
        onAdd: () => dispatch(onAddCustomerWorker()),
        onLoad: (workers: IPersonListItem[]) => dispatch(onLoadCustomerWorkers(workers)),
        onChange: (worker: IPerson) => dispatch(onChangeCustomerWorker(worker))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkerSelect);
