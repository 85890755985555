import * as React from "react";

interface FilterBarProps {
    isVisible: boolean;
}

export class FilterBar extends React.Component<FilterBarProps, any> {
    render() {
        return (
            <div className="filter-bar-shadow" hidden={!this.props.isVisible}>
                <div className="filter-bar-background">
                    <div className="filter-bar">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
