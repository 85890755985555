import * as React from "react";
import { Dialog, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Label } from "office-ui-fabric-react/lib/Label";
import "../styles/dialog.less";

export default class ValidateDialog extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showDialog: false,
            position: false,
            productType: false,
            customer: false,
            contactPerson: false,
            customPosition: false,
            quantity: false
        };
    }

    public render() {
        return (
            <div>
                <Dialog
                    modalProps={{ isBlocking: false}}
                    hidden={!this.state.showDialog}
                    onDismiss={this._closeDialog.bind(this)}
                    title={"Błąd zapisu!"}>
                {this.state.position && <div>
                    <i className="ms-Icon ms-Icon--Important red inline" aria-hidden="true"></i>
                    <Label className="inline">Brak pozycji</Label>
                </div>}
                 {this.state.productType && <div>
                    <i className="ms-Icon ms-Icon--Important red inline" aria-hidden="true"></i>
                    <Label className="inline">Brak wybranego produktu w pozycji</Label>
                </div>}
                 {this.state.customer && <div>
                    <i className="ms-Icon ms-Icon--Important red inline" aria-hidden="true"></i>
                    <Label className="inline">Brak wybranego klienta</Label>
                </div>}
                 {this.state.contactPerson && 
                 <div>
                    <i className="ms-Icon ms-Icon--Important red inline" aria-hidden="true"></i>
                    <Label className="inline">Brak wybranej osoby kontaktowej</Label>
                </div>}
                 {this.state.customPosition && 
                 <div>
                    <i className="ms-Icon ms-Icon--Important red inline" aria-hidden="true"></i>
                    <Label className="inline">Brak nazwy dla pozycji niestandardowej</Label>
                </div>}
                 {this.state.quantity && 
                 <div>
                    <i className="ms-Icon ms-Icon--Important red inline" aria-hidden="true"></i>
                    <Label className="inline">Liczba sztuk musi być większa od zera</Label>
                </div>}
                    <DialogFooter>
                        <PrimaryButton className=""
                            onClick={this._closeDialog.bind(this)}
                            text="OK"
                            disabled={!this.state.showDialog}/>
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }

    private _showDialog() {
        this.setState({ showDialog: true });
    }

    private _closeDialog() {
        this.setState({ showDialog: false });
    }
}
