import NavigationAdminHelper from "../../modules/admin/helpers/NavigationHelper";
import { IEvent, errorModel, succesModel } from "../NotificationModels";
import { Event } from "./Event";

export class ProductKeyCreated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            dialog: {
                title: `Utworzono klucz produktu ${event.body.name}`,
                hidden: false,
                action: () => NavigationAdminHelper.goToEditCosts(event.body.id),
                buttonTitle: "Wyedytuj ceny"
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się utworzyć klucza produktu"
            }
        };
    }
}

export class ProductKeyUpdated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Zaktualizowano klucz produktu " + event.body.name,
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się zaktualizować klucza produktu"
            }
        };
    }
}

export class ProductKeyDeleted extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Usunięto klucz produktu " + event.body.name,
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się usunąć klucza produktu"
            }
        };
    }
}

export class ProductKeyPricesUpdated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Zaktualizowano ceny klucza produktu " + event.body.name
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się zaktualizować cen"
            }
        };
    }
}

export class ProductKeyChanged extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            dialog: {
                title: "Klucz produktu " + event.body.name + " został zmieniony",
                hidden: false,
                action: () => NavigationAdminHelper.goToEditCosts(event.body.id),
                buttonTitle: "Wyedytuj ceny"
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się zaktualizować cen"
            }
        };
    }
}
