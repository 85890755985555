import * as React from "react";
import { PrimaryButton } from "office-ui-fabric-react";
import { Dialog, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { AuthContext } from "../tools/auth";

export default class AccessDeniedToApp extends React.Component<any, any> {
    render() {
        return (
            <div>
                <Dialog
                    modalProps={{ className: "text", isBlocking: false }}
                    hidden={false}
                    isDarkOverlay={false}
                    title={`Nie posiadasz praw dostępu do tej strony`}>
                    <DialogFooter>
                        <PrimaryButton
                            key={"admin"}
                            iconProps={{ iconName: "OutOfOffice" }}
                            onClick={() => AuthContext.instance.logout()}>
                            Wyloguj
                        </PrimaryButton>
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }
}
