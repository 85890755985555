import * as React from "react";
import { ConnectedRouter } from "react-router-redux";
import Authorize from "../components/Authorize";
import Rooter from "./Rooter";
import "../styles/main.less";
import "../styles/app.less";
import "../styles/react-tags.less";
import * as Notification from "react-notification-system";
import { NotificationStore } from "../tools/notifications";
import ValidationStore from "../tools/ValidationStore";
import SpinnerStore from "../tools/SpinnerStore";
import { NotificationDialog, ValidationDialog } from "../components/dialogs";
import RedirectToHttps from "../components/RedirectToHttps";
import GlobalSpinner from "../components/spinners/GlobalSpinner";
import { SessionScope } from "../tools/requests";
import LocalizationInitializer from "../tools/LocalizationInitializer";

interface IAppProps {
    routerId: number;
    history: any;
}

export default class App extends React.Component<IAppProps, any> {
    private globalSpinner: GlobalSpinner;
    private notificationDialog: NotificationDialog;
    private notification: Notification.System;
    private validationDialog: ValidationDialog;

    constructor(props: IAppProps) {
        super(props);

        LocalizationInitializer.init();
        SessionScope.init();
    }

    componentDidMount() {
        NotificationStore.componentDoMount(this.notification, this.notificationDialog);
        ValidationStore.componentDoMonut(this.validationDialog);
        SpinnerStore.componentDoMonut(this.globalSpinner);
    }

    render() {
        return (
            <RedirectToHttps>
                <Authorize>
                    <div className="app-wrapper">
                        <ConnectedRouter key={this.props.routerId} history={this.props.history}>
                            <Rooter/>
                        </ConnectedRouter>
                        <Notification ref={(notification: Notification.System) => this.notification = notification }/>
                        <ValidationDialog ref={(ref: any) => this.validationDialog = ref}/>
                        <NotificationDialog ref={(ref: any) => this.notificationDialog = ref}/>
                        <GlobalSpinner ref={(ref: any) => this.globalSpinner = ref}/>
                    </div>
                </Authorize>
            </RedirectToHttps>
        );
    }
}
