import * as React from "react";
import Select from "react-select";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import "./Select.less";
import { Styles } from "react-select/lib/styles";
import { getTheme } from "@uifabric/styling";
import { Theme } from "react-select/lib/types";

interface IOption {
    id: number;
    name: string;
}

interface ISelectProps {
    selectedId?: number;
    className?: string;
    noResultsText: string;
    isLoading: boolean;
    isClearable?: boolean;
    labelKey: string;
    disabled?: boolean;
    withLoaded?: boolean;
    placeholder: string;
    buttonsAreVisible?: boolean;
    options: IOption[];
    onEditClicked?: () => void;
    onChange: (id: number) => void;
    onAddClicked?: () => void;
}

interface ISelectState {
    selected: IOption;
}

class SelectInternal extends React.Component<ISelectProps, ISelectState> {
    constructor(props: ISelectProps) {
        super(props);
        let selected = this._handleSelectLoaded(props);
        this.state = { selected };
    }

    componentWillReceiveProps(nextProps: ISelectProps) {
        if (this.props.withLoaded) {
            let selected = this._handleSelectLoaded(nextProps);
            this.setState({ selected });
        }
    }

    render() {


        return (
            <div className={"select " + this.props.className}>
                <Select
                    className={"left width " + this._withOutButtonsClass + this.props.className}
                    styles={Styles}
                    noOptionsMessage={() => this.props.noResultsText}
                    placeholder={this.props.placeholder}
                    options={this.props.options}
                    value={this.state.selected}
                    getOptionLabel={o => o.name}
                    getOptionValue={o => o.id.toString()}
                    isLoading={this.props.isLoading}
                    onChange={this._handleSelectChange.bind(this)}
                    theme={SelectThemeGenerator.prepareTheme}
                    isClearable={this.props.isClearable}
                    loadingMessage={() => "Ładowanie"}
                    isDisabled={this.props.disabled}/>
                {this.props.buttonsAreVisible &&
                    <IconButton className="button left"
                        iconProps={{ iconName: "Edit" }}
                        ariaLabel="Edit"
                        onClick={this.props.onEditClicked}
                        disabled={this._disabled(this.state.selected)}/>}
                {this.props.buttonsAreVisible &&
                    <IconButton className="button left"
                        iconProps={{ iconName: "Add" }}
                        ariaLabel="Add"
                        onClick={this.props.onAddClicked}
                        disabled={this.props.disabled}/>}
            </div>
        );
    }

    private _handleSelectChange(newValue: IOption) {
        this.setState({ selected: newValue });
        this.props.onChange(newValue === null ? undefined : newValue.id);
    }

    private _handleSelectLoaded(nextProps: ISelectProps) {
        let selectedItems = nextProps.options !== undefined
            ? nextProps.options.filter(i => i.id === nextProps.selectedId)
            : [];
        return selectedItems.length === 1 ? selectedItems[0] : undefined;
    }

    private _disabled(value: any) : boolean {
        return value === undefined || value === 0  || value === null ? true : false;
    }    

    private get _withOutButtonsClass() {
        return this.props.buttonsAreVisible ? "" : " without-buttons ";
    }

    static isExist(value: any) : boolean {
        return value === undefined || value === 0  || value === null ? true : false;
    }
}

export class SelectThemeGenerator {
    static prepareTheme(theme: Theme): Theme {
        let uiTheme = getTheme();
        return {
            ...theme,
            borderRadius: 0,
            colors: {
                ...theme.colors,
                primary25: uiTheme.palette.themeTertiary,
                primary: uiTheme.palette.themePrimary,
            },
        };
    }
}

const Styles: Partial<Styles> = {
    control: base => ({
        ...base,
        height: 32,
        minHeight: 32,
        borderRadius: 0,
        boxShadow: "none",
        borderColor: "#d7d7d7",
        margin: 0
    }),
    menu: base => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
        marginLeft: 1                            
    }),
    container: base => ({
        ...base,
        minHeight: 32,
        height: 32,
    }),
    input: base => ({
        ...base,
        minHeight: 30,
        height: 30,
        paddingTop: 0,
    }),
    valueContainer: base => ({
        ...base,
        minHeight: 30,
        height: 30,
        position: "unset"
    }),
    dropdownIndicator: base => ({
        ...base,
        minHeight: 30,
        height: 30,
        width: 32
    })
};

export { SelectInternal as Select, Styles as SelectStyles };
