import * as React from "react";
import { RouteProps, Route, RouteComponentProps, Redirect } from "react-router";
import { AuthContext } from "../tools/auth";

interface AuthRouteProps extends RouteProps {
	roles: string[];
}

export default class AuthRoute extends React.PureComponent<AuthRouteProps> {
	renderRoute = (routeProps: RouteComponentProps<{}>) => {
		const { component, roles } = this.props;
		if (AuthContext.instance.user.isInRole(...roles)) {
			const Component = component as any;
			return <Component {...routeProps} />;
		}
		return <Redirect to="/AccessDenied" />;
	}

	render() {
		const { component: __, ...rest } = this.props;
		return <Route {...rest} render={this.renderRoute} />;
	}
}
