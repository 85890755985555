import * as Notifications from "react-notification-system";
import { EventHandlerFactory } from "./EventHandlerFactory";
import { NotificationDialog } from "../../components/dialogs";
import { IEvent } from "../../models/NotificationModels";

export class NotificationStore {
    private static _dialog: NotificationDialog;
    private static _notification: Notifications.System = null;
    private static _factory = new EventHandlerFactory();

    static componentDoMount(notification: Notifications.System, dialog: any) {
        NotificationStore._notification = notification;
        NotificationStore._dialog = dialog;
    }

    static isConnectError() {
        return this._notification
            .state.notifications
            .filter(x => x.title === "Błąd połączenia")
            .length > 0;
    }

    static showEventNotifications(request: any, eventName: string) {
        request.body.forEach((event: IEvent) => {
            this.show({ ...event, ok: request.response.ok });
        });
    }

    static show(event: IEvent) {
        let ev = this._factory.getEventHandler(event);
        let { notification, dialog } = ev.result;
        if (notification !== undefined) {
            this.add(notification);
        }
        if (dialog !== undefined) {
            this._dialog.setState({ ...dialog });
        }
    }

    static add(notification: Notifications.Notification) {
        NotificationStore._notification.addNotification(notification);
    }

    static addNoPermissionToCallAction() {
        NotificationStore.add({
            title: "Błąd wykonania akcji",
            message: "Nie masz uprawnień do wykonania tej akcji",
            level: "error",
            position: "tc",
            autoDismiss: 20
        });
    }
}
