import { Action } from "redux-actions";
import { KeyEditorActionTypes, KeyEditorState } from "./types";
import { arrayMove } from "react-sortable-hoc";
import ArrayExtensions from "../../helpers/ArrayExtensions";

const initState = new KeyEditorState();

export const keyEditorReducer = (state: KeyEditorState = initState, action: Action<any>) => {
    switch (action.type) {
        case KeyEditorActionTypes.PRODUCT_TYPE_ADD: { 
            let min = 0;
            state.productKey.productTypes.forEach(x => min = x.id <= min ? x.id : min);
            let productType = { ...action.payload, id: --min };
            let productTypes = state.productKey.productTypes.concat(productType);
            return { ...state, productKey: { ...state.productKey, productTypes } };
        }
        case KeyEditorActionTypes.PRODUCT_TYPE_EDIT: {
            let productTypes = state.productKey.productTypes
                .map(x =>
                    x.id === action.payload.id
                        ? action.payload
                        : x
                );
            return { ...state, productKey: { ...state.productKey, productTypes } };
        }
        case KeyEditorActionTypes.KEY_ON_CHANGE: {
            return { ...state, productKey: action.payload };
        }
        case KeyEditorActionTypes.KEY_ITEM_EDIT: {
            let items = state.productKey.items
                .map(x => { 
                    if ( x.id === action.payload.id ) {
                        x = action.payload;
                    }
                    return x;
            });
            return { ...state, productKey: { ...state.productKey, items }};
        }
        case KeyEditorActionTypes.KEY_ITEM_ADD: {
            let min = 0;
            state.productKey.items.forEach(x => min = x.id <= min ? x.id : min);
            let item = { ...action.payload, id: --min };
            let items = state.productKey.items.concat(item);
            return { ...state, productKey: { ...state.productKey, items }};
        }
        case KeyEditorActionTypes.PRODUCT_TYPE_DISMISS_MENU: {
            return {
                ...state,
                productTypeMenu: {
                    ...state.productTypeMenu,
                    isContextMenuVisible: false
                }
            };
        }
        case KeyEditorActionTypes.PRODUCT_TYPE_SHOW_MENU: {
            return {
                ...state,
                productTypeMenu: {
                    ...state.productTypeMenu,
                    isContextMenuVisible: true,
                    target: action.payload.target,
                    item: action.payload.item
                }
            };
        }
        case KeyEditorActionTypes.PRODUCT_TYPE_DELETE: {
            let productTypes = state.productKey.productTypes;
            let index = productTypes.findIndex(item => item.id === state.productTypeMenu.item.id);
            productTypes = productTypes.slice(0, index).concat(productTypes.slice(index + 1));
            return { ...state, productKey: { ...state.productKey, productTypes } };
        }
        case KeyEditorActionTypes.KEY_ITEM_DELETE: {
            let items = state.productKey.items;
            let index = items.findIndex(item => item.id === action.payload.id);
            items = ArrayExtensions.deleteItem(items, index);
            return { ...state, productKey: { ...state.productKey, items } };
        }
        case KeyEditorActionTypes.KEY_ITEM_MOVE: {
            let items = arrayMove(state.productKey.items, action.payload.oldIndex, action.payload.newIndex);
            return { ...state, productKey: { ...state.productKey, items } };
        }
        case KeyEditorActionTypes.KEY_LOAD: {
            return { ...state, productKey: action.payload };
        }
        case KeyEditorActionTypes.KEY_UPDATE_CUSTOM_COLORS: {
            let customColors = action.payload;
            let productKey = state.productKey;
            return { ...state, productKey: { ...productKey, customColors } };
        }
        default: {
            return state;
        }
    }
};
