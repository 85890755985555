import * as React from "react";
import { Label, DefaultButton, Link, Icon, TooltipHost } from "office-ui-fabric-react";
import "./LabelMenu.less";

interface ILabelMenuProps {
    text: string;
    buttonId: string;
    errorText?: string;
    linkText?: boolean;
    isError?: boolean;
    onLinkClick?: () => void;
    onClick?: () => void;
}

export class LabelMenu extends React.Component<ILabelMenuProps, any> {
    render() {
        let { buttonId, errorText, isError, linkText, onClick, onLinkClick, text } = this.props;
        return (
            <div className="ms-RowSize">
                {linkText &&
                    <Link onClick={onLinkClick} >
                        {text}
                    </Link>
                }
                {!linkText &&
                    <Label style={{ display: "inline" }}>
                        {text}
                    </Label>}
                {isError && <TooltipHost content={errorText} calloutProps={{ gapSpace: 0 }}>
                    <Icon
                        style={{ color: "red" }}
                        iconName="Error"/>
                </TooltipHost>}
                <DefaultButton
                    style={{ display: "inline" }}
                    id={buttonId}
                    className="label-button"
                    onClick={() => onClick()}
                    iconProps={{ iconName: "More" }}/>
            </div>
        );
    }
}
