import * as React from "react";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { IKeyItemValue, IKeyItem } from "../../../../../models/interfaces";

interface ConstItemProps {
    item: IKeyItem;
    setupAffectPrice: any[];
    onChange: (item: IKeyItem) => any;
}

export default class ConstItem extends React.Component<ConstItemProps, any> {
    render() {
        let { availableValues } = this.props.item;
        return (
            <div className="ms-Grid-row">
                <TextField
                    label="Wartość"
                    defaultValue={availableValues.length === 0 ? "" : availableValues[0].value.toString()}
                    required
                    onChanged={(value) => this.changeItemValue(value)}/>
            </div>
        );
    }

    private changeItemValue(value: string) {
        let currentValue = this.props.item.availableValues.length === 1
            ? { ...this.props.item.availableValues[0], value }
            : { value } as IKeyItemValue;
        this.props.onChange({
            ...this.props.item,
            availableValues: [currentValue]
        });
    }
}
