import * as React from "react";
import { NumberInput } from "../../../../../components";
import DisplayHelper from "../../../../../helpers/DisplayHelper";
import PositionModel from "../../../models/PositionModel";
import  { CalculationActions } from "../../../actions";
import TextHelper from "../../../helpers/TextHelper";
import { IPoint } from "@uifabric/utilities";
import { TextField } from "office-ui-fabric-react";

export interface Props {
    model: PositionModel;
    no: number;
    onContextMenu: (item: PositionModel, target: IPoint | HTMLElement) => void;
}

export interface State {
    editing: boolean;
}

export class PositionBase<TProps extends Props, TState extends State> extends React.Component<TProps, TState> {
    private _discountPercentage: NumberInput;
    
    constructor(props: TProps) {
        super(props);
        this.props.model.subscribe(() => {
            this.setState({ editing: true });
            this.reloadDiscounts();
        });
    }

    protected onProductKeyChange() {
        CalculationActions.calculatePositionNetValue(this.props.model)
            .then(netValue => {
                this.props.model.recalculate(netValue.value);
            });
    }

    private onDiscountPercentageChange(newValue: number) {
        this.props.model.changeDiscountPercentage(newValue);
    }

    private reloadDiscounts() {
        let discountPercentage = this.props.model.discountPercentage.toString();
        if (TextHelper.dotOrComma(discountPercentage, this._discountPercentage.state.value)) {
            this._discountPercentage.setState({ value: discountPercentage });
        }
    }

    render() {
        let netUnitPrice = this.renderNetUnitPrice();
        let positionName = this.renderPositionName();
        return (
            <tr onContextMenu={this._onContextMenu}>
                <td>{this.props.no}</td>
                <td>{positionName}</td>
                <td>
                    <TextField 
                        //className={classNames}
                        multiline
                        autoAdjustHeight
                        value={this.props.model.remarks === null ? undefined : this.props.model.remarks}
                        onChanged={event => {
                            //debugger;
                            this.props.model.changeRemarks(event);
                        }}/>
                </td>
                <td>
                    <NumberInput
                        value={this.props.model.quantity}
                        onChange={this.props.model.changeQuantity.bind(this.props.model)}/>
                </td>
                <td className="text-right">{netUnitPrice}</td>
                <td>
                    <NumberInput
                        ref={discountPercentage => this._discountPercentage = discountPercentage}
                        value={this.props.model.discountPercentage}
                        onChange={this.onDiscountPercentageChange.bind(this)}/>
                </td>
                <td className="text-right">{DisplayHelper.fixed(this.props.model.netUnitPriceAfterDiscount, 0)}</td>
                <td className="text-right">{DisplayHelper.fixed(this.props.model.netValue, 0)}</td>
            </tr>
        );
    }

    protected renderNetUnitPrice(): JSX.Element {
        throw new Error("Abstract method");
    }

    protected renderPositionName(): JSX.Element {
        throw new Error("Abstract method");
    }

    private _onContextMenu = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (this.props.onContextMenu === undefined) {
            return;
        }
        event.preventDefault();
        this.props.onContextMenu(this.props.model, { x: event.pageX, y: event.pageY});
    }
}
