import * as React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";

const Localizations = {
    pl: {
        weekdays: {
            first: 1,
            short: ["Nd", "Pn", "Wt", "Sr", "Cz", "Pt", "So"],
            long: [
                "Niedziela",
                "Poniedziałek",
                "Wtorek",
                "Środa",
                "Czwartek",
                "Piątek",
                "Sobota",
            ]
        },
        months: [
            "Styczeń",
            "Luty",
            "Marzec",
            "Kwiecień",
            "Maj",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień",
        ],
        lables: { nextMonth: "Następny miesiąc", previousMonth: "Poprzedni miesiąc" }
    }
};

interface Props {
    onChange?: (from: Date, to: Date) => void;
    numberOfMonths?: number;
    from?: Date;
    to?: Date;
}

interface State {
    from?: Date;
    to?: Date;
}

export class CalendarInline extends React.Component<Props, State> {
    static defaultProps = {
        numberOfMonths: 2
    };

    constructor(props: Props) {
        super(props);
        this.state = { from: props.from, to: props.to};
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
            this.setState({...this.state, from: this.props.from, to: this.props.to});
        }
    }

    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        return (
            <DayPicker
                className="Selectable"
                numberOfMonths={this.props.numberOfMonths}
                selectedDays={[from, { from, to }]}
                modifiers={modifiers}
                locale={"pl"}
                // tslint:disable-next-line:no-string-literal
                months={Localizations["pl"].months}
                // tslint:disable-next-line:no-string-literal
                weekdaysLong={Localizations["pl"].weekdays.long}
                // tslint:disable-next-line:no-string-literal
                firstDayOfWeek={Localizations["pl"].weekdays.first}
                // tslint:disable-next-line:no-string-literal
                labels={Localizations["pl"].lables}
                // tslint:disable-next-line:no-string-literal
                weekdaysShort={Localizations["pl"].weekdays.short}
                onDayClick={this.handleDayClick.bind(this)}
            />
        );
    }
    
    private handleDayClick(day: Date) {
        const range = DateUtils.addDayToRange(day, {from: this.state.from, to: this.state.to});        
        this.setState(range);
        if (this.props.onChange) {
            this.props.onChange(range.from, range.to);
        }
    }
}
