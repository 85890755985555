import { createAction } from "redux-actions";
import { TagsActionTypes } from "./types";
import { Tag } from "../../models/interfaces";

export const tagsLoad = createAction(
    TagsActionTypes.LOAD,
    (items: Tag[]) => items
);

export const deleteTag = createAction(
    TagsActionTypes.DELETE,
    (item: Tag) => item
);

export const updateTag = createAction(
    TagsActionTypes.UPDATE,
    (item: Tag) => item
);
