import { createAction } from "redux-actions";
import { RalColorsActionTypes } from "./types";
import RalColor from "../../../../models/RalColor";
import { IPoint } from "@uifabric/utilities";

export const ralLoad = createAction(
    RalColorsActionTypes.LOAD,
    (items: RalColor[]) => items
);

export const ralOnAdd = createAction(
    RalColorsActionTypes.ON_ADD,
    (item: RalColor) => item
);

export const ralOnMark = createAction(
    RalColorsActionTypes.ON_MARK,
    (item: RalColor) => item
);

export const ralShowMenu = createAction(
    RalColorsActionTypes.SHOW_MENU,
    (target: IPoint | HTMLElement, item: RalColor) => {
        return { target, item };
    }
);

export const ralEdit = createAction(
    RalColorsActionTypes.EDIT
);

export const ralAdd = createAction(
    RalColorsActionTypes.ADD
);

export const ralDelete = createAction(
    RalColorsActionTypes.DELETE
);

export const ralOnChange = createAction(
    RalColorsActionTypes.ON_CHANGE,
    (item: RalColor) => item
);

export const ralDismissMenu = createAction(
    RalColorsActionTypes.DISMISS_MENU
);

export const ralDismissDialog = createAction(
    RalColorsActionTypes.DISMISS_DIALOG
);

export const ralShowAddDialog = createAction(
    RalColorsActionTypes.SHOW_ADD_DIALOG
);

export const ralShowEditDialog = createAction(
    RalColorsActionTypes.SHOW_EDIT_DIALOG
);

export const ralShowInvokedDialog = createAction(
    RalColorsActionTypes.SHOW_INVOKED_DIALOG,
    (item: RalColor) => item
);
