import { createAction } from "redux-actions";
import { OffersListFiltersActionTypes } from "./types";
import { OfferStatus } from "../../models/Enums";
import { DateRange, Tag } from "../../models/interfaces";

export const changeFilterVisibility = createAction(
    OffersListFiltersActionTypes.CHANGE_VISIBILITY
);

export const clear = createAction(
    OffersListFiltersActionTypes.CLEAR
);

export const changeSearchText = createAction(
    OffersListFiltersActionTypes.CHANGE_SEARCH_TEXT,
    (value: string) => value
);

export const selectStatus = createAction(
    OffersListFiltersActionTypes.SELECT_STATUS,
    (status: OfferStatus) => status
);

export const clearSelectedStatuses = createAction(
    OffersListFiltersActionTypes.CLEAR_SELECTED_STATUSES
);

export const deselectStatus = createAction(
    OffersListFiltersActionTypes.DESELECT_STATUS,
    (status: OfferStatus) => status
);

export const setOnlyMy = createAction(
    OffersListFiltersActionTypes.SET_ONLY_MY,
    (onlyMy: boolean) => onlyMy
);

export const loadAvaliableTags = createAction(
    OffersListFiltersActionTypes.LOAD_TAGS,
    (tags: Tag[]) => tags
);

export const selectTag = createAction(
    OffersListFiltersActionTypes.SELECT_TAG,
    (tagId: number) => tagId
);

export const deselectTag = createAction(
    OffersListFiltersActionTypes.DESELECT_TAG,
    (tagId: number) => tagId
);

export const clearSelectedTags = createAction(
    OffersListFiltersActionTypes.CLEAR_SELECTED_TAGS
);

export const setNextContactDates = createAction(
    OffersListFiltersActionTypes.SET_NEXT_CONTACT_DATE_RANGE,
    (dateRange: DateRange) => dateRange
);
