import * as React from "react";
import { Dialog as FabricUIDialog, IDialogProps } from "office-ui-fabric-react/lib/Dialog";
import "./Dialog.less";

export class Dialog extends React.Component<IDialogProps> {
    render() {
        return (
            <FabricUIDialog {...this.props}>
                {this.props.children}
            </FabricUIDialog>
        );
    }
}
