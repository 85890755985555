import { OffersListActionTypes, OffersListState } from "./types";
import { Action } from "redux-actions";
import { OfferListItem } from "../../models/OfferListItem";

const initState = new OffersListState();

export const offersList = (state: OffersListState = initState, action: Action<any>) => {
    switch (action.type) {
        case OffersListActionTypes.LOAD_ITEMS: {
            return {
                ...state,
                items: action.payload,
                isLoaded: true,
                isFull: false
            };
        }
        case OffersListActionTypes.CLEAR_ITEMS: {
            return { ...initState };
        }
        case OffersListActionTypes.DOWNLOAD_ITEMS: {
            let items: any[] = action.payload;
            items = items.filter(x => !state.items.find(y => y.id === x.id));
            return {
                ...state,
                isLoaded: true,
                isFull: action.payload.length === 0,
                items: state.items.concat(items)
            };
        }
        case OffersListActionTypes.IS_LOADING: {
            return {
                ...state,
                isLoaded: false
            };
        }
        case OffersListActionTypes.PUSH_ITEM: {
            return { ...state, items: [ action.payload, ...state.items ] };
        }
        case OffersListActionTypes.DELETE_ITEM: {
            return { ...state, items: state.items.filter(i => i.id !== action.payload) };
        }
        case OffersListActionTypes.ITEM_CHANGE_STATE: {
            let items = state.items.map(i => {
                if (i.id === action.payload.id) {
                    i.status = action.payload.newStatus;
                }
                return i;
            });
            return {...state, items };
        }
        case OffersListActionTypes.SHOW_COMMENTS: {
            return {...state, comments: {areVisible: true, offer: action.payload}};
        }
        case OffersListActionTypes.HIDE_COMMENTS: {
            return {...state, comments: {areVisible: false, offer: new OfferListItem()}};
        }
        default: {
            return state;
        }
    }
};
