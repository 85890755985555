import { createAction } from "redux-actions";
import { CustomColorsDialogActionTypes, CustomColorEditing } from "./types";

export const open = createAction(
    CustomColorsDialogActionTypes.OPEN,
    (items: CustomColorEditing[]) => items
);

export const hide = createAction(
    CustomColorsDialogActionTypes.HIDE
);

export const changeCostFactor = createAction(
    CustomColorsDialogActionTypes.CHANGE_COST_FACTOR,
    (item: CustomColorEditing) => item
);

export const changeAll = createAction(
    CustomColorsDialogActionTypes.CHANGE_ALL,
    (items: CustomColorEditing[]) => items
);

export const mark = createAction(
    CustomColorsDialogActionTypes.MARK,
    (item: CustomColorEditing) => item
);

export const show = createAction(CustomColorsDialogActionTypes.SHOW);
