import { Action } from "redux-actions";
import { OfferDetailsModel, Customer, Project, CustomerWorker } from "../../models/OfferDetailsModel";
import { OffersDetailsActionTypes } from "./types";

const initState = new OfferDetailsModel();

export const offerDetails = (state: OfferDetailsModel = initState, action: Action<any>) => {
    switch (action.type) {
        case OffersDetailsActionTypes.CUSTOMER_ON_ADD: {
            return {
                ...state,
                customer: new Customer(),
                customerDialogHidden: false,
                edited: false,
                title: "Dodanie klienta"
            };
        }
        case OffersDetailsActionTypes.CUSTOMER_ON_EDIT: {
            return {
                ...state,
                customer: action.payload,
                customerDialogHidden: false,
                edited: true,
                title: "Edycja klienta"
            };
        }
        case OffersDetailsActionTypes.CUSTOMERS_ON_LOAD: {
            return {
                ...state,
                customers: action.payload,
                customerDialogHidden: true
            };
        }
        case OffersDetailsActionTypes.CUSTOMER_ON_CHANGE: {
            return { ...state, customer: action.payload };
        }
        case OffersDetailsActionTypes.CUSTOMER_DIALOG_DISMISS: {
            return { ...state, customerDialogHidden: true };
        }
        case OffersDetailsActionTypes.PROJECT_ON_ADD: {
            return { ...state, 
                projectDialogHidden: false, 
                edited: false,
                title: "Dodanie projektu",
                project: new Project()
            };
        }
        case OffersDetailsActionTypes.PROJECT_ON_EDIT: {
            return { ...state, 
                projectDialogHidden: false, 
                title: "Edycja projektu",
                edited: true,
                project: action.payload
            };
        }
        case OffersDetailsActionTypes.PROJECT_DIALOG_DISMISS: {
            return { ...state, projectDialogHidden: true };
        }
        case OffersDetailsActionTypes.PROJECT_ON_CHANGE: {
            return { ...state, project: action.payload };
        }
        case OffersDetailsActionTypes.PROJECTS_ON_LOAD: {
            return { ...state, projectDialogHidden: true, projects: action.payload };
        }
        case OffersDetailsActionTypes.CUSTOMER_WORKER_ON_LOAD: {
            return { ...state, customerWorkers: action.payload, customerWorkerDialogHidden: true, edited: false };
        }
        case OffersDetailsActionTypes.CUSTOMER_WORKER_ON_EDIT: {
            return { ...state, 
                customerWorkerDialogHidden: false, 
                edited: true,
                title: "Edycja osoby kontaktowej",
                customerWorker: action.payload
            };
        }
        case OffersDetailsActionTypes.CUSTOMER_WORKER_ON_ADD: {
            return { ...state, 
                customerWorkerDialogHidden: false, 
                edited: false,
                title: "Dodanie osoby kontaktowej",
                customerWorker: new CustomerWorker()
            };
        }
        case OffersDetailsActionTypes.CUSTOMER_WORKER_DIALOG_DISMISS: {
            return { ...state, customerWorkerDialogHidden: true };
        }
        case OffersDetailsActionTypes.CUSTOMER_WORKER_ON_CHANGE: {
            return { ...state, customerWorker: action.payload };
        }
        default: {
            return state;
        }
    }
};
