import * as React from "react";
import { DayOfWeek, IDatePickerStrings, DatePicker } from "office-ui-fabric-react";
import DisplayHelper from "../../helpers/DisplayHelper";

interface Props {
    label?: string;
    value?: Date;
    min?: Date;
    disabled?: boolean;
    onSelectDate?: (date: Date) => void;
}

interface State {
    value: string;
}

const firstDayOfWeek = DayOfWeek.Monday;

const DayPickerStrings: IDatePickerStrings = {
    months: [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwic",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień",
    ],
  
    shortMonths: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
  
    days: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czerwic", "Piątek", "Sobota"],
  
    shortDays: ["S", "M", "T", "W", "T", "F", "S"],
  
    goToToday: "Idź do dzisiaj",
    prevMonthAriaLabel: "Go to previous month",
    nextMonthAriaLabel: "Go to next month",
    prevYearAriaLabel: "Go to previous year",
    nextYearAriaLabel: "Go to next year",
    invalidInputErrorMessage: "Invalid date format.",
  };

export class DateInput extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <DatePicker
                //className={controlClass.control}
                isRequired={false}
                allowTextInput={true}
                //ariaLabel={desc}
                firstDayOfWeek={firstDayOfWeek}
                strings={DayPickerStrings}
                showWeekNumbers={true}
                minDate={this.props.min}
                value={this.props.value!}
                disabled={this.props.disabled}
                label={this.props.label}
                // eslint-disable-next-line react/jsx-no-bind
                onSelectDate={this.props.onSelectDate}
                formatDate={DisplayHelper.formatDate}
                
            />
        );
    }

    setValue(newValue: number) {
        this.setState({ value: newValue.toString() });
    }
}
