import OfferModel from "../models/OfferModel";
import PositionModel from "../models/PositionModel";

export default class Validate {
    static valueLength(value: string, maxLength: number) {
        if (value.length > maxLength) {
            return "Podany tekst nie powinien mieć więcej niż " + maxLength + " znaków";
        }
        return "";
    }

    static empty(value: string) {
        if (value === "") {
            return "Ta wartość jest wymagana";
        }
        return "";
    }

    static numberFormat(value: string) {
        let phoneNumber = /(^$|^\+?([0-9]{2})?[-. ]?([0-9]{2})?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{3})$)/;
        if (!value.match(phoneNumber)) {
            return "Format wprowadzonego numeru jest niepoprawny";
        }
        return "";
    }

    static eMail(value: string) {
        if (!value.match(/(^$|^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$)/)) {
            return "Podany e-mail jest niepoprawny";
        }
        return "";
    }

    static isNotNullOrEmpty(text: string) {
        return text !== undefined
            && text !== null
            && text !== "";
    }

    static offerModel(offer: OfferModel) {
        let validateObject = {
            position: Validate.positionsNumber(offer.positions),
            productType: Validate.productType(offer.positions),
            customer: Validate.person(offer.customerId),
            contactPerson: Validate.person(offer.contactPersonId),
            customPosition: Validate.customPosition(offer.positions),
            quantity: Validate.quantity(offer.positions)
        };
        return validateObject;
    }

    static offer(offer: OfferModel): boolean {
        let validateObject = this.offerModel(offer);
        return !validateObject.position
            && !validateObject.productType
            && !validateObject.customer
            && !validateObject.contactPerson
            && !validateObject.customPosition
            && !validateObject.quantity;
    }

    static positionsNumber(positions: PositionModel[]): boolean {
        return positions.length === 0;
    }

    static productType(positions: PositionModel[]): boolean {
        let position = positions.find(x => x.productTypeId === undefined);
        return position !== null && position !== undefined;
    }

    static person(id: number): boolean {
        return id === undefined || id === null || id === 0;
    }

    static quantity(positions: PositionModel[]): boolean {
        let isTrue = false;
        positions.forEach(x => {
            if (x.quantity <= 0) {
                isTrue = true;
            }
        });
        return isTrue;
    }

    static customPosition(positions: PositionModel[]): boolean {
        let isTrue = false;
        positions.forEach(x => {
            if (x.isCustom) {
                if (x.key.items[0].getValue() === null) {
                    isTrue = true;
                }
            }
        });
        return isTrue;
    }

    static textFields(textFields: any): boolean {
        for (let key in textFields) {
            if (textFields.hasOwnProperty(key)) {
                (textFields as any)[key].focus();
                if ((textFields as any)[key].state.errorMessage !== "") {
                    return false;
                }
                else if ((textFields as any)[key].props.required && (textFields as any)[key].value === "") {
                    (textFields as any)[key].focus();
                    return false;
                }
            }
        }
        return true;
    }
}
