import * as React from "react";
import { connect, Dispatch } from "react-redux";
import { DetailsList, DetailsListLayoutMode, CheckboxVisibility, DialogFooter, Selection, IColumn, Checkbox, DefaultButton } from "office-ui-fabric-react";
import { hide, changeAll, changeCostFactor, mark } from "../../../store/customColorsDialog/actions";
import "../../../styles/editor.less";
import RalColor from "../../../../../models/RalColor";
import { autobind } from "office-ui-fabric-react/lib/Utilities";
import { Dialog } from "../../../../../components/dialogs";
import { CustomColorEditing, CustomColorEdited } from "../../../store/customColorsDialog/types";
import { updateCustomColors } from "../../../store/keyEditor/actions";
import DisplayHelper from "../../../../../helpers/DisplayHelper";
import { NumberInput, FormBottomPanel } from "../../../../../components";

interface ICustomColorDialogProps {
    isHidden: boolean;
    items: CustomColorEditing[];
    save: () => void;
    changeAll: (items: CustomColorEditing[]) => void;
    onUpdateCustomColors: (customColors: CustomColorEdited[]) => void;
    changeCostFactor: (item: CustomColorEditing) => void;
    onHide: () => void;
    mark: (item: CustomColorEditing) => void;
}

interface CustomColorDialogState {
    markAll?: boolean;
}

class CustomColorDialog extends React.Component<ICustomColorDialogProps, CustomColorDialogState> {
    private _selection = new Selection();
    
    constructor(props: ICustomColorDialogProps) {
        super(props);
        this.state = {};
    }

    render() {
        let { isHidden, onHide, items } = this.props;
        let selectButtonText = items.every(x => x.assigned) ? "Odzaznacz wszystkie" : "Zaznacz wszystkie";
        return (
            <Dialog
                title="Przypisanie kolorów do klucza"
                modalProps={{ isBlocking: false, containerClassName: "ms-dialogMainOverride" }}
                hidden={isHidden}
                onDismiss={onHide}>
                <DefaultButton onClick={() => this._markSelection(!items.every(x => x.assigned), true)}>
                    {selectButtonText}
                </DefaultButton>
                <div className="is-scrollable">
                    <DetailsList
                        selection={this._selection}
                        className="off-padding"
                        items={items}
                        columns={CustomColorDialog.Columns}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        onRenderItemColumn={this._onRenderItemColumn}/>
                </div>
                <DialogFooter>                    
                    <FormBottomPanel onCancel={() => this.props.onHide()} onSave={this.save}/>
                </DialogFooter>
            </Dialog>
        );
    }

    @autobind
    private save() {
        let { onHide, onUpdateCustomColors, items } = this.props;
        let mapped = items.filter(i => i.assigned)
            .map(item => {
            let { colorId, isDefault, costFactor } = item;
            return { colorId, isDefault, costFactor}; 
        });
        onUpdateCustomColors(mapped);
        onHide();
    }

    @autobind
    private _markSelection(isMarked: boolean, markAll?: boolean) {
        markAll = this.state.markAll || markAll;
        let items = this.props.items.map((x: any) => {
            if (this._selection.getSelection().some(v => v === x) || markAll) {
                let isDefault = x.isDefault;
                if (!isMarked) {
                    isDefault = false;
                }
                return { ...x, assigned: isMarked, isDefault };
            }
            return x;
        });
        this.props.changeAll(items);
    }

    @autobind
    private _onRenderItemColumn(item: CustomColorEditing, index: any, column: IColumn) {
        if (column.fieldName === "assigned") {
            return <Checkbox onChange={(ev, isDefault) => this._markSelection(!item.assigned)} 
                checked={item.assigned}/>;
        }
        if (column.fieldName === "name") {
            return new RalColor(item.colorId, item.value, item.other).toLabel();
        }
        if (column.fieldName === "isDefault") {
            return <Checkbox onChange={(ev, isDefault) => this.props.mark({ ...item, isDefault } as CustomColorEditing)} 
                 checked={item.isDefault}/>;
        }
        if (column.fieldName === "originalCostFactor") {
            return DisplayHelper.fixed(item.originalCostFactor);
        }
        if (column.fieldName === "costFactor") {
            return <NumberInput value={item.costFactor} onChange={costFactor => this.props.changeCostFactor({ ...item, costFactor})}/>;
        }
        return (item as any)[column.fieldName];
    }

    private static Columns: IColumn[] = [
        {
            key: "column0",
            name: "Przypisany?",
            fieldName: "assigned",
            minWidth: 60,
            maxWidth: 60
        },
        {
            key: "column1",
            name: "Nazwa",
            fieldName: "name",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: "column3",
            name: "Domyślny?",
            fieldName: "isDefault",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: "column4",
            name: "Oryginalny wsp. kosztu",
            fieldName: "originalCostFactor",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true
        },
        {
            key: "column5",
            name: "Wsp. kosztu",
            fieldName: "costFactor",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true
        }
    ];
}

function mapStateToProps(state: any) {
    return {
        items: state.customColorReducer.items,
        isHidden: state.customColorReducer.isHidden,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        changeAll: (items: CustomColorEditing[]) => dispatch(changeAll(items)),
        changeCostFactor: (item: CustomColorEditing) => dispatch(changeCostFactor(item)),
        onHide: () => dispatch(hide()),
        mark: (item: CustomColorEditing) => dispatch(mark(item)),
        onUpdateCustomColors: (customColors: CustomColorEdited[]) => dispatch(updateCustomColors(customColors))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomColorDialog);
