import { KeyListActionTypes, ProductKeyListItem } from "./types";
import { Action } from "redux-actions";
import ArrayExtensions from "../../helpers/ArrayExtensions";

export const keyListReducer = (state: ProductKeyListItem[] = [], action: Action<any>) => {
    switch (action.type) {
        case KeyListActionTypes.LOAD: {
            return action.payload;
        }
        case KeyListActionTypes.DELETE: { 
            return ArrayExtensions.deleteItemById(state, action.payload);
        }
        default: {
            return state;
        }
    }
};
