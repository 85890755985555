import { CostGridCell, KeyCost, KeyItemCost, CostBase, CostVariant, CostOptionVariant } from "../store/costs/types";
import { AffectsPriceType } from "../../../models/Enums";

export default class CostTableBuilder {
    private rowsCount: number;
    private columnsCount: number;
    private baseItem: KeyItemCost;
    private baseOptionItem: KeyItemCost;
    private items: KeyItemCost[];
    private costBases: CostBase[];
    private costs: CostGridCell[][];

    constructor(key: KeyCost) {
        this.costBases = key.productTypes[0].costBases;
        this.baseItem = key.items.find(x => x.affectsPrice === AffectsPriceType.Base);
        this.baseOptionItem = key.items.find(x => x.affectsPrice === AffectsPriceType.BaseOption);
        this.items = key.items
            .filter(x => x.affectsPrice === AffectsPriceType.BaseOption || x.affectsPrice === AffectsPriceType.Adds)
            .sort((a, b) => a.order - b.order);
        this.columnsCount = this.items
            .map(i => i.availableValues.length)
            .reduce((a, b) => a + b, 0);
        if (this.baseOptionItem === undefined) {
            this.columnsCount++;
        }
        this.rowsCount = this.costBases.length + 2;
        this.costs = new Array(this.rowsCount);
        for (let row = 0; row < this.rowsCount; row++) { 
            this.costs[row] = new Array(this.columnsCount + 1); 
        }
    }

    buildTable() {
        this._buildBaseItemName()
            ._buildBaseItemValues()
            ._buildKeyItemName()
            ._buildKeyItemValues()
            ._buildKeyItemValueCosts();
        return this.costs;
    }

    changeCostsType(key: KeyCost, id: number) {
        this.costBases = key.productTypes.find(x => x.id === id).costBases;
        this._buildBaseItemValues()
            ._buildKeyItemValueCosts();
        return this.costs;
    }

    private _buildKeyItemValues() {
        let currentColumn = 1;
        if (this.baseOptionItem === undefined) {
            this.costs[1][currentColumn++] = { readOnly: true, value: "" };
        }
        this.items.map(x => {
                x.availableValues
                    .sort((a, b) => a.order - b.order)
                    .map(v => {
                        this.costs[1][currentColumn++] = { 
                            className: v.isAlwaysPresent ? "isAlwaysPresent" : "",
                            readOnly: true,
                            tooltip: v.isAlwaysPresent,
                            tooltipText: v.isAlwaysPresent ? "Dostępne dla wszystkich wariantów produktu" : undefined,
                            value: v.value, 
                            keyItemValueId: v.id, 
                            itemId: x.id 
                        };
                    });
            });
        return this;
    }

    private _buildKeyItemName() {
        let currentColumn = 1;
        if (this.baseOptionItem === undefined) {
            this.costs[0][currentColumn++] = { readOnly: true, value: "" };
        }
        this.items.map(x => {
                let colSpan = x.availableValues.length;
                this.costs[0][currentColumn++] = { readOnly: true, value: x.name, colSpan };
            });
        return this;
    }

    private _buildBaseItemName() {
        this.costs[1][0] = { readOnly: true, value: this.baseItem.name, colSpan: this.baseOptionItem === undefined ? 1 : 1 };
        this.costs[0][0] = { readOnly: true, value: "" };
        return this;
    }

    private _buildBaseItemValues() {
        if (this.baseOptionItem === undefined) {
            this.costs[2][0] = { readOnly: true, value: "" };
        }
        this.costBases
            .sort((a, b) => a.baseKeyItemValue.order - b.baseKeyItemValue.order)
            .map((x, i) => {
                this.costs[i + 2][0] = { readOnly: true, value: x.baseKeyItemValue.value, baseValueId: x.baseKeyItemValueId };
            });
        return this;
    }

    private _buildKeyItemValueCosts() {
        for (let row = 2; row < this.rowsCount; row++) {
            for (let column = 1; column <= this.columnsCount; column++) {
                let base = this.costBases.find(x => x.baseKeyItemValueId === this.costs[row][0].baseValueId);
                let variant: CostVariant;
                let option: CostOptionVariant;
                if (this.costs[1][column].itemId === undefined) {
                    variant = base.costVariants.find(x => x.keyItemId === null);
                    option = variant.optionVariants[0];
                }
                else {
                    variant = base.costVariants.find(x => x.keyItemId === this.costs[1][column].itemId);
                    option = variant.optionVariants.find(x => x.valueId === this.costs[1][column].keyItemValueId);
                }
                this.costs[row][column] = {
                    readOnly: false,
                    value: option.cost,
                    costOptionId: option.id,
                    baseValueId: base.baseKeyItemValueId,
                    itemId: variant.keyItemId
                };
            }
        }
        return this;
    }
}
