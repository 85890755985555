import Publisher from "./Publisher";
import KeyItemModel from "./KeyItemModel";
import { IKeyItemMetadata, IKey } from "./interfaces";

export default class KeyModel extends Publisher implements IKey {
    constructor(
        public productTypeId: number,
        public keyId: number, 
        public items: KeyItemModel[],
        public needCompleteCosts?: boolean) {
        super();
        this.items.forEach(x => x.subscribe(this.inform.bind(this)));
    }

    clone() {
        return new KeyModel(this.productTypeId, this.keyId, this.items.map(x => x.clone()), this.needCompleteCosts);
    }

    static create(productTypeId: number, keyId: number, items: IKeyItemMetadata[]) {
        let mapped = items.map(metadata => new KeyItemModel(metadata));
        return new KeyModel(productTypeId, keyId, mapped);
    }
}
