import { CostsActionTypes } from "./types";
import { createAction } from "redux-actions";
import { KeyCost, CostGridCell } from "./types";

export const costLoad =  createAction(
    CostsActionTypes.LOAD,
    (key: KeyCost) => key
);

export const costChangeProductType =  createAction(
    CostsActionTypes.CHANGE_PRODUCT_TYPE,
    (id: number) => id
);

export const costChangeCost =  createAction(
    CostsActionTypes.CHANGE_COST,
    (modifiedCell: CostGridCell, row: number, col: number, value: number) => {
       return  { modifiedCell, row, col, value };
    }
);

export const costChangeKey =  createAction(
    CostsActionTypes.CHANGE_KEY,
    (key: KeyCost) => key
);
