import numeral = require("numeral");

export default class LocalizationInitializer {
    static init() {
        // load a locale
        numeral.register("locale", "pl", {
            delimiters: {
                thousands: " ",
                decimal: ","
            },
            abbreviations: {
                thousand: "k",
                million: "m",
                billion: "b",
                trillion: "t"
            },
            ordinal : function (num: number) {
                return num === 1 ? "er" : "ème";
            },
            currency: {
                symbol: "PLN"
            }
        });

        // switch between locales
        numeral.locale("pl");
    }
}
