import * as React from "react";
import { Toggle, Label, TextField } from "office-ui-fabric-react";
import { NumberInput } from "../../../../../components";
import AffectPriceChoice from "./AffectPriceChoice";
import {  IKeyItem } from "../../../../../models/interfaces";

interface ISizeItemProps {
    item: IKeyItem;
    setupAffectPrice: any[];
    onChange: (item: IKeyItem) => any;
}

export default class SizeItem extends React.Component<ISizeItemProps, any> {
    render() {
        let { item, onChange } = this.props;
        return (
            <div className="ms-Grid-row">
                <AffectPriceChoice
                    setupAffectPrice={this.props.setupAffectPrice}
                    onChange={onChange}
                    item={item}/>
                <div className="ms-Grid-col ms-lg4">
                    <Label>Minimalna wartość do mnożenia</Label>
                    <NumberInput
                        className="text-right"
                        onChange={(minValueToMultiple) => onChange({ ...item, minValueToMultiple })}
                        value={item.minValueToMultiple}/>
                </div>
                <div className="ms-Grid-col ms-lg2">
                    <Toggle
                        className="toggle-size"
                        label="Wymiar?"
                        onText="Tak"
                        offText="Nie"
                        defaultChecked={item.isSize}
                        onChanged={isSize => onChange({ ...item, isSize })}/>
                </div>
                <div className="ms-Grid-col ms-lg2" >
                    {item.isSize && 
                        <TextField
                            label="Symbol wymiaru"
                            value={item.sizeSymbol}
                            required
                            onChanged={(sizeSymbol) => onChange({ ...item, sizeSymbol })}/>
                    }
                </div>
            </div>
        );
    }
}
