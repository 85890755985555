import * as React from "react";
import { IProductType } from "../../../../../models/interfaces";
import ProductTypeMenu from "./ProductTypeMenu";
import { productTypeShowMenu } from "../../../store/keyEditor/actions";
import { Dispatch, connect } from "react-redux";
import { Label } from "office-ui-fabric-react";
import { SortableList } from "../../../../../components";

interface Props {
    productTypes: IProductType[];
    showMenu: (type: IProductType, event: MouseEvent) => void;
}

class ProductTypeList extends React.Component<Props, any> {
    render() {
        return (
            <div>
                <Label className="label-bold">Typy produktów</Label>
                <SortableList
                    onContextualMenu={this.props.showMenu}
                    helperClass="SortableHelper"
                    axis="xy"
                    useDragHandle={false}
                    items={this.props.productTypes}
                    getItemTitle={item => item.symbol}/>
                <ProductTypeMenu/>
            </div>
        );
    }
}

function mapStateToProps(state: any, ownProps: any) {
    return {
        items: state.keyEditorReducer.productKey.keyItems,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: { productTypes: IProductType[] }) {
    return {
        showMenu: (item: IProductType, event: MouseEvent) => dispatch(productTypeShowMenu(item, event)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTypeList);
