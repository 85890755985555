import { Request, SessionScope } from ".";

/** Request that result will be keep at session. */
export class SessionRequest {
    public static query<TResult>(name: string, body: any = {}) : Promise<TResult> {
        let sessionScopeName = SessionScope.scope.nameAlias;
        let sessionItemName = `${sessionScopeName}${name}${JSON.stringify(body)}`;
        let itemFromStorageStr = sessionStorage.getItem(sessionItemName);
        if (itemFromStorageStr) {
            let o = JSON.parse(itemFromStorageStr);
            return Promise.resolve(o);
        }

        return Request.query<TResult>(name, body)
            .then(data => {
                let json = JSON.stringify(data);
                if (json === undefined) {
                    return null;
                }
                sessionStorage.setItem(sessionItemName, json);
                return data;
            });
    }
}
