import { IPoint } from "@uifabric/utilities";
import { Icon } from "office-ui-fabric-react";
import * as React from "react";
import { Select } from "../../../../../components";
import { CustomerActions } from "../../../actions";
import { IContact, ICustomerListItem, IRole } from "../../../models/interfaces";
import CustomerSelect from "../CustomerSelect";
import { StringHelper } from "../../../../../helpers";
import "./ContactRow.less";

export interface Props {
    roles: IRole[];
    contact: IContact;
    errorMessage: string;
    disabled?: boolean;
    onChange: (contact: IContact) => void;
    onContextMenu: (item: IContact, target: IPoint | HTMLElement) => void;
}

export class ContactRow extends React.Component<Props, any> {
    private _contacts: ICustomerListItem[];

    constructor(props: Props) {
        super(props);
        this._load();
    }

    render() {
        let roles = this.props.roles;
        let roleId = this.props.contact.role === undefined ? undefined : this.props.contact.role.id;
        let entityId = this.props.contact.entity === undefined ? undefined : this.props.contact.entity.id;
        return (
            <div onContextMenu={(item) => this._onContextMenu(item)}>
                <div className="list-item">
                    <CustomerSelect
                        onChange={this._onContactChange}
                        customerId={entityId}
                        disabled={this.props.disabled}/>
                </div>
                <div className="list-item">
                    <Select
                        noResultsText={"Nie zdefiniowano ról"}
                        isLoading={roles.length === 0}
                        labelKey={"name"}
                        placeholder={"Wybierz rolę"}
                        options={roles}
                        onChange={this._onRoleChange}
                        withLoaded
                        selectedId={roleId}
                        disabled={this.props.disabled}/>
                </div>
                <div className="error-message">
                    { StringHelper.any(this.props.errorMessage) &&
                        <div className="error-message">
                            <Icon
                                iconName="Error"
                                title={this.props.errorMessage}/>
                            Błąd
                        </div>
                    }                    
                </div>
            </div>
        )
    }

    private _onRoleChange = (id: number) => {
        let role = this.props.roles.find(r => r.id === id);
        let contact = this.props.contact;
        this.props.onChange({...contact, role})
    }

    private _onContactChange = (id: number) => {
        let contact = this.props.contact;
        let role: IRole = undefined;

        if (this._contacts !== undefined) {
            let contacts = this._contacts.filter(c => c.id == id);
            if (contacts.length === 1) {
                let id = contacts[0].defaultEntityTypeInProjectId;
                let roles = this.props.roles.filter(r => r.id == id);
                if (roles.length === 0) {
                    // take default from dictionary
                    roles = this.props.roles.filter(r => r.isDefault === true);
                }
                if (roles.length === 1) {
                    role = roles[0];
                }
            }
        }

        let entity = { id };
        this.props.onChange({...contact, entity, role});
    }

    private _onContextMenu(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (this.props.onContextMenu === undefined) {
            return;
        }
        event.preventDefault();
        this.props.onContextMenu(this.props.contact, { x: event.pageX, y: event.pageY });
    }

    private _load() {
        CustomerActions.getAllCustomers()
            .then(c => {
                this._contacts = c;
            });
    }
}
