import * as React from "react";
import Select, { components } from "react-select";
import { SelectStyles, SelectThemeGenerator } from "../../../../components";
import { IProductType } from "../../../../models/interfaces";
import { Icon, TooltipHost } from "office-ui-fabric-react";
import { Fragment } from "react";

interface Props {
    productTypes: IProductType[];
    productTypeId: number;
    onChange: (newProductKey: IProductType) => void;
}

interface State {
    value: IProductType;
}

export default class ProductType extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = this._getStateFromProps(props);
    }                
    
    componentWillReceiveProps(nextProps: Props) {
        this.setState(this._getStateFromProps(nextProps));
    }

    render() {
        let title = this.state.value === undefined ? "" : this.state.value.name;
        return (
            <div className="productKey" title={title}>
                <Select
                    styles={SelectStyles}
                    placeholder="Wybierz"
                    noOptionsMessage={() => "Brak dostępnych produktów"}
                    options={this.props.productTypes}
                    value={this.state.value}
                    getOptionLabel={productType => productType.symbol}
                    getOptionValue={productType => productType.id.toString()}
                    components={{
                        Option: this._option
                    }}
                    onChange={this._handleSelectChange.bind(this)}
                    isLoading={this.props.productTypes.length <= 0}
                    theme={SelectThemeGenerator.prepareTheme}/>
            </div>
        );
    }

    private _handleSelectChange(newValue: IProductType) {
        this.setState({ value: newValue });
        this.props.onChange(newValue);
    }

    private _getStateFromProps(props: Props): State {
        let value = undefined;
        if(props.productTypeId > 0 || props.productTypes.length > 0) {
            let items = props.productTypes.filter(x => x.id === props.productTypeId);
            if (items.length >= 0) {
                value = items[0];
            }
        }

        return { value };
    }

    private _option = (props: any) => {
        let value = props.data;
        return (
            <Fragment>
                <components.Option {...props}>
                    <div className="react-select__option" title={value.name}>
                        {value.symbol}
                        {value.needCompleteCosts && <TooltipHost content="Klucz produktu wymaga uzupełnienia cen" calloutProps={{ gapSpace: 0 }}>
                            <Icon
                                style={{ color: "red" }}
                                iconName="Error"/>
                        </TooltipHost>}
                    </div>
                </components.Option>
            </Fragment>
        );
    }
}
