import { ITemplateOffer } from "../../models/Templates";

export const enum OffersListTemplatesActionTypes {
    SET_TEMPLATES = "@@Offers/Templates/Set"
}

export class OffersListTemplatesState {
    isLoaded: boolean = false;
    templates: ITemplateOffer[] = [];
}
