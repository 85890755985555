export default class Publisher {
    private onChanges: Array<any>;

    constructor() {
        this.onChanges = new Array<any>();
    }

    subscribe(onChange: any) {
        this.onChanges.push(onChange);
    }

    protected inform() {
        this.onChanges.forEach(x => x());
    }
}
