import * as React from "react";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { IPoint } from "office-ui-fabric-react/lib/utilities/positioning";
import { IContextualMenuItem, ContextualMenuItemType } from "office-ui-fabric-react/lib";
import { RolesHelper } from "../../../tools/auth";

interface ItemContextualMenuProps {
    target: IPoint | HTMLElement;
    items?: any[];
    isContextMenuVisible: boolean;
    onDismiss: () => void;
    onEdit?: () => void;
    onDelete: () => void;
    roles?: string[];
}

export default class ItemContextualMenu extends React.Component<ItemContextualMenuProps, any> {
    render() {
        let items = this.prepareItems();
        return (
            <div>
                {this.props.isContextMenuVisible 
                    ? (
                    <ContextualMenu
                        shouldFocusOnMount
                        target={this.props.target}
                        onDismiss={() => this.props.onDismiss()}
                        items={items}/>
                    ) 
                    : (null)}
            </div>
        );
    }

    private prepareItems(): IContextualMenuItem[] {
        let items = RolesHelper.filter([
            {
                key: "edit",
                name: "Edytuj",
                iconProps: { iconName: "Edit" },
                onClick: () => this.props.onEdit(),
                roles: this.props.roles
            },
            {
                key: "div-0",
                itemType: ContextualMenuItemType.Divider,
                roles: this.props.roles
            },
            {
                key: "delete",
                name: "Usuń",
                iconProps: { iconName: "Delete" },
                onClick: () => this.props.onDelete(),
                roles: this.props.roles
            }
        ]);
        if (this.props.items === undefined) {
            return items;
        }
        return items.concat(this.props.items);
    }
}
