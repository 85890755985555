import * as React from "react";
import EditorCommandBar from "../../components/keys/EditorCommandBar";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import KeyItemList from "../../components/keys/KeyItemList";
import { connect, Dispatch } from "react-redux";
import "../../styles/editor.less";
import { loadKey, addProductType, addKeyItem, changeKey } from "../../store/keyEditor/actions";
import { IProductType, IKeyItem, IProductKey } from "../../../../models/interfaces";
import ProductTypeList from "../../components/keys/productType/ProductTypeList";
import CustomColorPanel from "../../components/keys/customColor/CustomColorPanel";
import { FormBottomPanel } from "../../../../components";
import { RouteComponentProps } from "react-router";
import ProductKeyActions from "../../actions/ProductKeyActions";
import { Label, autobind } from "office-ui-fabric-react";
import { keyItemShowAddDialog } from "../../store/keyItemMenu/actions";
import NavigationHelper from "../../helpers/NavigationHelper";
import { show, open } from "../../store/customColorsDialog/actions";
import { CustomColorEditing } from "../../store/customColorsDialog/types";
import CustomColorDialog from "../../components/keys/customColor/CustomColorDialog";
import CustomColorsActions from "../../actions/CustomColorsActions";

interface IEditorProps {
    productKey: IProductKey;
    onAddProductType: (productType: IProductType) => any;
    addKeyItem: (keyItem: IKeyItem) => any;
    showKeyItemDialog: () => void;
    showCustomColorsDialog: () => void;
    loadKey: (key: IProductKey) => any;
    openColorsDialog: (colors: CustomColorEditing[]) => any;
    onKeyChange: (key: IProductKey) => any;
}

class Editor extends React.Component<IEditorProps & RouteComponentProps<any>, any> {
    componentDidMount() {
        let keyId = this.props.match.params.keyId;
        let { productKey, loadKey } = this.props;
        if (keyId === undefined) { return; }
        if (!productKey.name) {
            ProductKeyActions.getProductKey(keyId)
                .then(loadKey);
        }
    }

    render() {
        let { onKeyChange, productKey, onAddProductType } = this.props;
        return (
            <div>
                <div className="fixed-command-bar">
                    <EditorCommandBar
                        onAddKeyItem={this.props.showKeyItemDialog}
                        onAssignColors={this._openCustomDialog}
                        onAddProductType={productType => onAddProductType(productType)}/>
                </div>
                <div className="body-container">
                    <TextField
                        className="text-field-align label-bold"
                        onChanged={(name: string) => onKeyChange({ ...productKey, name })}
                        value={productKey.name}
                        label="Nazwa"
                        required/>
                    <ProductTypeList productTypes={productKey.productTypes}/>
                    <Label className="label-bold">Elementy klucza</Label>
                    <KeyItemList/>
                    <CustomColorPanel
                        assignedColorsCount={productKey.customColors.length}
                        onClickColorsLink={this._openCustomDialog}/>
                    <FormBottomPanel
                        onCancel={() => NavigationHelper.goToKeysList()}
                        onSave={() => this._saveProductKey()}/>
                </div>
                <CustomColorDialog productKey={productKey}/>
            </div>
        );
    }

    private _saveProductKey() {
        let customColors = this.props.productKey.customColors;
        let key = { ...this.props.productKey, customColors };
        if (this.props.match.params.keyId === undefined) {
            ProductKeyActions.create(key)
                .then(request => {
                    if (request.response.ok) {
                        NavigationHelper.goToKeysList();
                    }
                });
        }
        else {
            ProductKeyActions.update(key)
                .then(request => {
                    if (request.response.ok) {
                        NavigationHelper.goToKeysList();
                    }
                });
        }
    }

    @autobind
    private _openCustomDialog() {
        CustomColorsActions
            .readPossibleCustomColors(this.props.productKey.customColors)
            .then(c => this.props.openColorsDialog(c));
    }
}

function mapStateToProps(state: any) {
    return {
        productKey: state.keyEditorReducer.productKey
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        loadKey: (key: IProductKey) => dispatch(loadKey(key)),
        openColorsDialog: (colors: CustomColorEditing[]) => dispatch(open(colors)),
        onAddProductType: (productType: IProductType) => dispatch(addProductType(productType)),
        showKeyItemDialog: () => dispatch(keyItemShowAddDialog()),
        showCustomColorsDialog: () => dispatch(show()),
        addKeyItem: (item: IKeyItem) => dispatch(addKeyItem(item)),
        onKeyChange: (key: IProductKey) => dispatch(changeKey(key))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
