import {IKeyItemValue} from "../../../../../models/interfaces";
import SelectKeyItemBase, { IOption } from "./SelectKeyItemBase";

export default class SelectKeyItem extends SelectKeyItemBase {
    protected handleSelectChange(newValue: IOption): void {
        this.setState({value: newValue});
        let itemValue = newValue === null ? null : newValue.value;
        let selectedValue = this.props.metadata.availableValues.filter(x => x.value === itemValue)[0];
        this.props.onChange(selectedValue);
    }

    protected get selectClassName(): string {
        return "selectKeyItem";
    }

    protected mapToOption(v: IKeyItemValue): IOption {
        let itemLabel = v.label === undefined || v.label === null || v.label.length === 0
            ? v.value.toString()
            : v.label;
        return { label: itemLabel, value: v.value, title: v.name };
    }
}
