import { IEvent, errorModel, succesModel } from "../NotificationModels";
import { Event } from "./Event";

export class RalColorCreated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Utworzono kolor  " + event.body.name
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się utworzyć koloru"
            }
        };
    }
}

export class RalColorUpdated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Zaktualizowano  kolor  " + event.body.name
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się zaktualizować koloru"
            }
        };
    }
}

export class RalColorDeleted extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Usunięto kolor " + event.body.name
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się usunąć koloru"
            }
        };
    }
}

export class RalColorMarkedAsDefault extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Kolor " + event.body.name + " oznaczony jako domyślny"
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się oznaczyć koloru"
            }
        };
    }
}
