import * as React from "react";
import { Editor, EditorOptions, EditorPlugin, UndoService } from "roosterjs-editor-core";
import { HtmlSanitizer } from "roosterjs-html-sanitizer";
import { ContentEdit, HyperLink, Paste } from "roosterjs-editor-plugins";
import { DefaultFormat } from "roosterjs-editor-types";
import { EditorViewState } from "roosterjs-react";
import "./ReactEditor.less";
import { autobind } from "office-ui-fabric-react";

export interface ReactEditorProps {
    viewState: EditorViewState;
    className?: string;
    plugins?: EditorPlugin[];
    updateViewState?: (viewState: EditorViewState, content: string, isInitializing: boolean) => void;
    undo?: UndoService;
    isRtl?: boolean;
    hyperlinkToolTipCallback?: (href: string) => string;
    defaultFormat?: DefaultFormat;
    onBlur?: (ev: React.FocusEvent<HTMLDivElement>) => void;
    onChange?: (message: string) => void;
    //onClick
}

export default class ReactEditor extends React.Component<ReactEditorProps, {}> {
    private contentDiv: HTMLDivElement;
    private editor: Editor;
    private updateViewStateWhenUnmount: boolean;

    render() {
        let { className, isRtl } = this.props;
        return <div dir={isRtl ? "rtl" : "ltr"}
            className={className}
            onBlur={this._handleBlur}
            ref={ref => this.contentDiv = ref}/>;
    }

    componentDidMount() {
        this.editor = new Editor(this.contentDiv, this._getEditorOptions());
        this.editor.addDomEventHandler("input", ev => this._handleTextChange(ev));
        this.updateViewStateWhenUnmount = true;
        this.updateContentToViewState(true);
    }

    componentWillUnmount() {
        if (this.updateViewStateWhenUnmount) {
            this.updateContentToViewState();
            this.updateViewStateWhenUnmount = false;
        }
        this.editor.dispose();
        this.editor = null;
    }

    updateContentToViewState(isInitializing?: boolean) {
        if (this.editor) {
            let updateViewState = this.props.updateViewState || this._updateViewState;
            updateViewState(this.props.viewState, this.editor.getContent(), isInitializing);
        }
    }

    setUpdateViewStateWhenUnmount(updateViewStateWhenUnmount: boolean) {
        this.updateViewStateWhenUnmount = updateViewStateWhenUnmount;
    }

    clean() {
        this.editor.setContent("");
        this.updateContentToViewState();
    }

    private _getEditorOptions(): EditorOptions {
        let { plugins, viewState, undo, hyperlinkToolTipCallback, defaultFormat } = this.props;
        let allPlugins: EditorPlugin[] = [new ContentEdit(), new HyperLink(hyperlinkToolTipCallback), new Paste(true /*useDirectPaste*/)];

        if (plugins) {
            allPlugins = allPlugins.concat(plugins);
        }

        let initialContent = HtmlSanitizer.convertInlineCss(viewState.content);
        let options: EditorOptions = {
            plugins: allPlugins,
            defaultFormat: defaultFormat,
            undo: undo,
            initialContent: initialContent
        };

        return options;
    }

    private _updateViewState(viewState: EditorViewState, content: string, isInitializing: boolean) {
        if (viewState.content !== content) {
            viewState.content = content;
            if (!isInitializing) {
                viewState.isDirty = true;
            }
        }
    }

    private _handleBlur = (ev: React.FocusEvent<HTMLDivElement>) => {
        this.updateContentToViewState();
        if (this.props.onBlur) {
            this.props.onBlur(ev);
        }
    }

    @autobind
    private _handleTextChange(event: UIEvent) {
        if (!this.props.onChange) {
            return;
        }
        let target = event.target as any;
        let message = target.innerHTML;
        this.props.onChange(message);
    }
}
