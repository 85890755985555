import { OfferStatus } from "./Enums";

export class OfferListItem {
    id: number;
    number: string;
    author: string;
    project: string;
    client: string;
    modifiedDate: Date;
    createdDate: Date;
    sum: number;
    status: OfferStatus;
    currency: string;
    tags: string[];
    lastContactDate: Date;
    nextContactDate: Date;
    commentsThreadId: string;
}
