import { OfferListItem } from "../../models/OfferListItem";

export const enum OffersListActionTypes {
    LOAD_ITEMS = "@@Offers/LOAD_ITEMS",
    IS_LOADING = "@@Offers/IS_LOADING",
    DOWNLOAD_ITEMS = "@@Offers/DOWNLOAD_ITEMS",
    CLEAR_ITEMS = "@@Offers/CLEAR_ITEMS",
    PUSH_ITEM = "@@Offers/PUSH_ITEM",
    DELETE_ITEM = "@@Offers/DELETE_ITEM",
    ITEM_CHANGE_STATE = "@@Offers/Item/ChangeState",
    SHOW_COMMENTS = "@@Offers/ITEM/SHOW_COMMENTS",
    HIDE_COMMENTS = "@@Offers/ITEM/HIDE_COMMENTS"
}

export class CommentsState {
    areVisible: boolean = false;
    offer: OfferListItem = new OfferListItem();
}

export class OffersListState {
    allItems: OfferListItem[] = [];
    offersCount: number = 0;
    items: OfferListItem[] = [];
    isLoaded: boolean = false;
    isFull: boolean = false;
    comments: CommentsState = new CommentsState();
}
