import { loadTheme, IPartialTheme } from "office-ui-fabric-react";

export interface ITheme extends IPartialTheme {
    name: string;
    link: string;
    logo: string;
}

export class Theme {
    private static _themeName = "nellia";

    static initialize(themeName?: string) {
        if (themeName !== undefined) {
            this._themeName = themeName;
        }
        loadTheme(Theme.currentTheme);
    }

    static get currentTheme() : ITheme {
        return this.Themes[this._themeName];
    }

    static Themes: { [id: string]: ITheme; } = {
        airidea: {
            name: "AirIDEA",
            link: "https://airidea.pl",
            logo: require("..//..//asserts//airidea.png").default,
            palette: {
              themePrimary: "#0078d4",
              themeLighterAlt: "#f3f9fd",
              themeLighter: "#d0e7f8",
              themeLight: "#a9d3f2",
              themeTertiary: "#5ca9e5",
              themeSecondary: "#1a86d9",
              themeDarkAlt: "#006cbe",
              themeDark: "#005ba1",
              themeDarker: "#004377",
              neutralLighterAlt: "#f8f8f8",
              neutralLighter: "#f4f4f4",
              neutralLight: "#eaeaea",
              neutralQuaternaryAlt: "#dadada",
              neutralQuaternary: "#d0d0d0",
              neutralTertiaryAlt: "#c8c8c8",
              neutralTertiary: "#bab8b7",
              neutralSecondary: "#a3a2a0",
              neutralPrimaryAlt: "#8d8b8a",
              neutralPrimary: "#323130",
              neutralDark: "#605e5d",
              black: "#494847",
              white: "#ffffff",
            },
            semanticColors: {
                menuHeader: "#D3D3D3",
                menuItemText: "#616161",
                menuItemTextHovered: "#727272",
            }
          },
        nellia: {
            name: "Nella",
            link: "http://nellia.pl",
            logo: require("..//..//asserts//nellia.svg").default,
            palette: {
                themePrimary: "#8d973f",
                themeTertiary: "#F2F3CC",
                themeDarkAlt: "#7e8739",
                neutralQuaternaryAlt: "#eeeeee",
            },
            semanticColors: {
                menuHeader: "#8d973f",
                menuItemText: "#eeeeee",
                menuItemTextHovered: "#DEDEDE",
            }
        }
    };
}
