import * as React from "react";
import { TextPreview } from "../../../components/preview";
import { OfferActions } from "../actions";
import OfferReadModel from "../models/OfferReadModel";
import { RouteComponentProps } from "react-router";
import { ConfirmDialog } from "../../../components/dialogs";
import { OfferStatus, OfferStatuses } from "../models/Enums";
import { CommandBar, OfferDetails, Offer } from "../components/preview";
import "../styles/view.less";
import { Pivot, PivotItem, autobind, Panel, PanelType } from "office-ui-fabric-react/lib";
import { Shortcut } from "@shopify/react-shortcuts";
import { CommentsThread } from "../components/comments";
import ContactInfo from "../components/contact-info/ContactInfo";
import { OfferContactInfo } from "../models/interfaces";
import SectionSpinner from "../../../components/spinners/SectionSpinner";

interface State {
    model: OfferReadModel;
    statusesAreImported: boolean;
    contactInfoIsVisible: boolean;
    statusToSet: OfferStatus;
    commentsAreVisible: boolean;
}

export default class Preview extends React.Component<any & RouteComponentProps<any>, State> {
    private _changeStatusConfirmDialog: ConfirmDialog;
    private _spinner: SectionSpinner;

    constructor(props: any & RouteComponentProps<any>) {
        super(props);
        this.state = { 
            model: undefined,
            statusesAreImported: false,
            statusToSet: undefined,
            commentsAreVisible: false,
            contactInfoIsVisible: false
        };
    }

    componentWillMount() {
        OfferActions.getOfferToRead(this.props.match.params.offerId)
            .then(model => {
                this.setState({ model });
            });
    }

    render() {
        if (this.state.model === undefined) { return null; }
        return (
            <div>
                <div className="fixed-command-bar">
                    <CommandBar
                        model={this.state.model}
                        onClickStatusChange={this._handleOnStatusChange}
                        onShowComments={this.showComments}
                        onShowContactInfo={this.showContactInfo}/>
                </div>
                <div className="body-container">
                    <OfferDetails model={this.state.model}/>
                    <Pivot>
                        <PivotItem linkText="Pozycje">
                            <Offer model={this.state.model}/>
                        </PivotItem>
                        <PivotItem linkText="Notatki">
                            <TextPreview content={this.state.model.notes}/>
                        </PivotItem>
                    </Pivot>
                    <Panel isOpen={this.state.commentsAreVisible} 
                        onDismiss={this.hideComments}
                        type={PanelType.medium}
                        headerText="Komentarze">
                        <CommentsThread threadId={this.state.model.threadId}/>
                    </Panel>
                    <Panel isOpen={this.state.contactInfoIsVisible}
                        type={PanelType.smallFixedFar}
                        headerText="Informacje o kontakcie"
                        onDismiss={this.hideContactInfo}>
                        <SectionSpinner
                            ref={(spinner) => this._spinner = spinner}
                            label="Zapisywanie...">
                            <ContactInfo
                                contactInfo={this.state.model}
                                onSave={this._onSaveContactInfo}/>
                        </SectionSpinner>
                    </Panel>
                </div>
                <ConfirmDialog
                    ref={ref => this._changeStatusConfirmDialog = ref}
                    text="Czy na pewno zmienić status oferty na '{0}'?"
                    onConfirmed={this._onChangeStatus}/>
                <Shortcut
                    ordered={["/", "c"]}
                    onMatch={this.showComments}
                    ignoreInput={true}/>
                <Shortcut
                    ordered={["/", "i"]}
                    onMatch={() => this.showContactInfo()}
                    ignoreInput={true}/>
            </div>
        );
    }

    @autobind
    showComments() {
        this.setState({...this.state, commentsAreVisible: true});
    }

    @autobind
    hideComments() {
        this.setState({...this.state, commentsAreVisible: true, contactInfoIsVisible: false});
    }

    @autobind
    showContactInfo() {
        this.setState({...this.state, commentsAreVisible: false, contactInfoIsVisible: true});
    }

    @autobind
    hideContactInfo() {
        this.setState({...this.state, contactInfoIsVisible: false});
    }

    @autobind
    private _handleOnStatusChange(statusToSet: number) {
        this.setState({...this.state, statusToSet });
        this._changeStatusConfirmDialog.show(OfferStatuses[statusToSet]);
    }

    @autobind
    private _onChangeStatus() {
        OfferActions.changeOfferStatus(this.state.model.id, this.state.statusToSet)
            .then(request => {
                if (request.response.ok) {
                    this.props.onStatusChange(this.state.model.id, this.state.statusToSet);
                }
            });
    }

    @autobind
    _onSaveContactInfo(contactInfo: OfferContactInfo) {
        this._spinner.show();
        OfferActions.updateContactInfo(this.state.model.id, contactInfo)
            .finally(() => {
                let model = this.state.model;
                model.nextContactDate = contactInfo.nextContactDate;
                model.lastContactDate = contactInfo.lastContactDate;
                this.setState({...this.state, contactInfoIsVisible: false, model});
                this._spinner.hide();
            });
    }
}
