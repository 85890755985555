import { connect } from "react-redux";
import * as React from "react";
import {
    DetailsList,
    Selection,
    MarqueeSelection,
    DetailsListLayoutMode,
    CheckboxVisibility,
    Label
} from "office-ui-fabric-react/lib";
import { Dispatch } from "redux";
import { ProductKeyListItem } from "../../store/keyList/types";
import KeyListActions from "../../actions/KeyListActions";
import { loadProductKeyList, productKeyDelete } from "../../store/keyList/actions";
import DisplayHelper from "../../../../helpers/DisplayHelper";
import KeysListMenu from "./KeysListMenu";
import { loadKey } from "../../store/keyEditor/actions";
import ProductKeyActions from "../../actions/ProductKeyActions";
import { ProductKey } from "../../store/keyEditor/types";
import NavigationHelper from "../../helpers/NavigationHelper";
import { LabelMenu } from "../../../../components";
import { autobind } from "@uifabric/utilities";

interface IKeyListProps {
    selection: Selection;
    items: ProductKeyListItem[];
    dispatch: Dispatch<{}>;
    loadProductKey: (items: ProductKeyListItem[]) => any;
    loadItemsToEdit: (key: ProductKey) => any;
    onDelete: (id: number) => any;
}

class KeysList extends React.Component<IKeyListProps, any> {
    constructor(props: any) {
        super(props);
        this.state = { isContextMenuVisible: false };
    }

    componentDidMount() {
        KeyListActions.getProductKeyList()
            .then(this.props.loadProductKey);
    }

    render() {
        return (
            <div>
                <MarqueeSelection selection={this.props.selection}>
                    <DetailsList
                        items={this.props.items}
                        columns={KeysList.Columns}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        selection={this.props.selection}
                        onItemInvoked={(item) => this._goToEditKey(item.id)}
                        onItemContextMenu={(item?: any, index?: number, ev?: MouseEvent) => this._handleContextualMenu(item, ev)}
                        onRenderItemColumn={this._onRenderItemColumn}/>
                </MarqueeSelection>
                <KeysListMenu
                    isContextMenuVisible={this.state.isContextMenuVisible}
                    target={this.state.target}
                    onDelete={this._deleteItem}
                    onEdit={() => this._goToEditKey(this.state.id)}
                    onEditCosts={() => this._gotoEditCosts(this.state.id)}
                    onCopy={() => this._onCopy()}
                    onDismiss={() => this.setState({ isContextMenuVisible: false })}/>
            </div>
        );
    }

    @autobind
    private _deleteItem() {
        KeyListActions.deleteProductKey(this.state.id)
            .then(request => {
                if (request.response.ok) {
                    this.props.onDelete(this.state.id);
                }
            });
    }

    private _onCopy() {
        KeyListActions.duplicateProductKey(this.state.id)
            .then((request: any) => {
                let items = [request.body[0].body, ...this.props.items];
                this.props.loadProductKey(items);
            });
    }

    private _handleContextualMenu(item: any, event: MouseEvent) {
        if (event.type === "contextmenu") {
            this.setState({
                isContextMenuVisible: true,
                target: { x: event.clientX, y: event.clientY },
                id: item.id
            });
        }
    }

    private _goToEditKey(id: number) {
        ProductKeyActions.getProductKey(id)
            .then(key => {
                this.props.loadItemsToEdit(key);
                NavigationHelper.goToEditKey(id);
            });
    }

    private _gotoEditCosts(id: number) {
        ProductKeyActions.getKeyWithCosts(id)
            .then(x => {
                NavigationHelper.goToEditCosts(id);
            });
    }

    private static Columns = [
        {
            key: "column1",
            name: "Nazwa",
            fieldName: "name",
            minWidth: 200,
            maxWidth: 300,
            isResizable: true
        },
        {
            key: "column2",
            name: "Data modyfikacji",
            fieldName: "modifiedDate",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true
        }
    ];

    private _showButtonMenu(item: any) {
        this.setState({
            isContextMenuVisible: true,
            target: document.querySelector("#menu-" + item.id) as HTMLElement,
            id: item.id
        });
    }

    @autobind
    private _onRenderItemColumn(item: ProductKeyListItem, index: any, column: any) {
        if (column.fieldName === "modifiedDate") {
            return <Label> {DisplayHelper.formatDateTime(item.modifiedDate)} </Label>;
        }
        return <LabelMenu
            buttonId={"menu-" + item.id}
            text={item.name}
            errorText="Klucz wymaga uzupełnienia cen"
            isError={item.needCompleteCosts}
            linkText={false}
            onClick={() => this._showButtonMenu(item)}/>;
    }
}

function mapStateToProps(state: any) {
    return {
        items: state.keyListReducer
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        loadItemsToEdit: (key: ProductKey) => dispatch(loadKey(key)),
        loadProductKey: (productKeys: ProductKeyListItem[]) => dispatch(loadProductKeyList(productKeys)),
        onDelete: (id: number) => dispatch(productKeyDelete(id))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(KeysList);
