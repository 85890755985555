import { IEvent, IEventResult, IEventType } from "../NotificationModels";

export abstract class Event implements IEventType {
    name: string;
    result: IEventResult;

    constructor(event: IEvent) {
        this.name = event.name;
        this.result = event.ok
            ? this.onSuccess(event)
            : this.onError(event);
    }

    protected onSuccess(event: IEvent): IEventResult {
        throw new Error("Abstract method");
    }

    protected onError(event: IEvent): IEventResult {
        throw new Error("Abstract method");
    }
}
