import * as React from "react";
import { CommandBar as FabricCommandBar } from "office-ui-fabric-react/lib/CommandBar";
import NavigationHelper from "../../helpers/NavigationHelper";
import { autobind } from "office-ui-fabric-react";
import CommentBadge from "../comments/CommentBadge";
import { renderBadgeButton } from "../../../../components/commands";

interface Props {
    addIsDisabled: boolean;
    onAddPosition: () => void;
    onAddCustomPosition: () => void;
    onShowComments: () => void;
    onShowContactInfo: () => void;
    onSave: () => void;
    threadId?: string;
}

interface State {
    isPossitionAddDialogVisible: boolean;
    comentsCount?: number;
}

export default class CommandBar extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { isPossitionAddDialogVisible: false, comentsCount: undefined };
    }

    render() {
        return (
            <div>  
                <FabricCommandBar
                    buttonAs={renderBadgeButton}
                    items={[
                        {
                            title: "Wróć (Esc)",
                            key: "back",
                            iconProps: { iconName: "back" },
                            onClick: () => NavigationHelper.goToOfferList()
                        },
                        {
                            name: "Zapisz",
                            title: "Zapisz (Alt+S)",
                            key: "save",
                            iconProps: { iconName: "Save" },
                            onClick: this.props.onSave
                        },
                        {
                            name: "Pozycja",
                            title: "Pozycja (/+N)",
                            key: "add-position",
                            iconProps: { iconName: "Add" },
                            disabled: this.props.addIsDisabled,
                            onClick: this.props.onAddPosition
                        },
                        {
                            name: "Pozycja niestandardowa",
                            title: "Pozycja niestandardowa (/+N)",
                            key: "add-custom-position",
                            iconProps: { iconName: "Add" },
                            onClick: this.props.onAddCustomPosition
                        }
                    ]}
                    farItems={[
                        {
                            title: "Informacje o kontakcie (/+I)",
                            name: "Kontakt",
                            key: "show-contact-info",
                            iconProps: { iconName: "Contact" },
                            onClick: this.props.onShowContactInfo
                        },
                        {
                            title: "Komentarze (/+C)",
                            key: "show-comments",
                            iconProps: { iconName: "Comment"},
                            onClick: this.props.onShowComments,
                            badge: this.state.comentsCount,
                            badgeIsLoading: this.props.threadId && this.state.comentsCount === undefined
                        }
                    ]}/>
                    <CommentBadge threadId={this.props.threadId} onCountChange={this._onCommentsCountChange}/>
            </div>
        );
    }

    @autobind
    private _onCommentsCountChange(comentsCount: number) {
        this.setState({...this.state, comentsCount});
    }
}
