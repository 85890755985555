import * as React from "react";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Toggle, Label, DetailsList, DetailsListLayoutMode, CheckboxVisibility, CommandBar, IColumn, Selection, Spinner, SpinnerSize } from "office-ui-fabric-react";
import "../../../styles/editor.less";
import { IKeyItem } from "../../../../../models/interfaces";
import KeyItemValueMenu from "../KeyItemValueMenu";
import SizeItem from "./SizeItem";
import { IDragDropEvents, IDragDropContext } from "office-ui-fabric-react/lib/utilities/dragdrop";
import { AffectsPriceType } from "../../../../../models/Enums";
import "./SimpleChoiceItem.less";
import ProductKeyActions from "../../../actions/ProductKeyActions";

interface Props {
    item: IKeyItem;
    setupAffectPrice: any[];
    onAddKeyItemValue: () => void;
    onDeleteItemValue: (index: number) => void;
    onChange: (item: IKeyItem) => any;
}

interface State {
    isContextMenuVisible: boolean;
    target: any;
    index: number;
    markAll: boolean;
    withoutDelete: boolean;
    isLoadingValues: boolean;
}

export default class SimpleChoiceItem extends React.Component<Props, State> {
    private _selection = new Selection();
    private _draggedItem: any = null;
    private _draggedIndex = -1;

    constructor(props: any) {
        super(props);
        this.state = {
            isContextMenuVisible: false,
            target: undefined,
            index: undefined,
            markAll: false,
            withoutDelete: undefined,
            isLoadingValues: this._needLoadKeyItemValues()
        };
    }

    componentDidMount() {
        if (!this._needLoadKeyItemValues()) {
            return;
        }

        ProductKeyActions.loadKeyItemValues(this.props.item.id)
            .then(values => {
                this.props.item.availableValues = values;
                this.setState({...this.state, isLoadingValues: false});
            });
    }

    render() {
        let { item, onChange, onAddKeyItemValue } = this.props;
        return (
            <div>
                <SizeItem
                    onChange={onChange}
                    item={item}
                    setupAffectPrice={this.props.setupAffectPrice} />
                <Label>Dostępne wartości</Label>
                <CommandBar items={[{
                    name: "Dodaj wartość",
                    disabled: this.state.isLoadingValues,
                    iconProps: {
                        iconName: "Add"
                    },
                    key: "add-custom-position",
                    onClick: () => onAddKeyItemValue()
                }]} />
                <div className="is-scrollable">
                    {
                        this.state.isLoadingValues
                        ? <Spinner className="loading-items-spinner" size={SpinnerSize.large}/>
                        : <DetailsList
                                selection={this._selection}
                                items={item.availableValues}
                                columns={this.Columns()}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                dragDropEvents={this._getDragDropEvents()}
                                onRenderItemColumn={(itemValue, index, column) => this._onRenderItemColumn(itemValue, index, column)}
                                onItemContextMenu={(itemValue?, index?, ev?: MouseEvent) => this._handleContextualMenu(index, ev)}
                                onColumnHeaderContextMenu={(column, ev) => this._handleContextualMenuColumn(column, ev)} />
                    }
                    <KeyItemValueMenu
                        isContextMenuVisible={this.state.isContextMenuVisible}
                        target={this.state.target}
                        withoutDelete={this.state.withoutDelete}
                        onMark={(isMark: boolean) => this._markSelection(isMark)}
                        onDelete={() => this.props.onDeleteItemValue(this.state.index)}
                        onDismiss={() => this.setState({ isContextMenuVisible: false, markAll: false })} />
                </div>
            </div>
        );
    }

    private _needLoadKeyItemValues() {
        return this.props.item.id > 0 && this.props.item.availableValues.length === 0;
    }

    private _getDragDropEvents(): IDragDropEvents {
        return {
            canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => { return true; },
            canDrag: (item?: any) => { return true; },
            onDragEnter: (item?: any, event?: DragEvent) => { return "dragEnter"; }, // return string is the css classes that will be added to the entering element.
            onDragLeave: (item?: any, event?: DragEvent) => { return; },
            onDrop: (item?: any, event?: DragEvent) => {
                if (this._draggedItem) {
                    this._insertBeforeItem(item);
                }
            },
            onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                this._draggedItem = item;
                this._draggedIndex = itemIndex!;
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                this._draggedItem = null;
                this._draggedIndex = -1;
            }
        };
    }

    private _insertBeforeItem(item: any) {
        const draggedItems = this._selection.isIndexSelected(this._draggedIndex) ? this._selection.getSelection() : [this._draggedItem];

        const availableValues = this.props.item.availableValues.filter((av) => draggedItems.indexOf(av) === -1);
        let insertIndex = availableValues.indexOf(item);

        if (insertIndex === -1) {
            insertIndex = 0;
        }

        availableValues.splice(insertIndex, 0, ...draggedItems);
        this.props.onChange({ ...this.props.item, availableValues });
    }

    _handleContextualMenuColumn(column: IColumn, ev: any) {
        this.setState({
            isContextMenuVisible: true,
            withoutDelete: true,
            target: { x: ev.clientX, y: ev.clientY },
            markAll: true
        });
    }

    private _markSelection(isMark: boolean) {
        this.props.onChange({
            ...this.props.item,
            availableValues: this.props.item.availableValues.map((x: any) => {
                if (this._selection.getSelection().some(v => v === x) || this.state.markAll) {
                    return { ...x, priceDependsOnColor: isMark };
                }
                return x;
            })
        });
    }

    private _handleContextualMenu(index: number, event: MouseEvent) {
        this.setState({
            isContextMenuVisible: true,
            withoutDelete: false,
            target: { x: event.clientX, y: event.clientY },
            index
        });
    }

    private _onRenderItemColumn(item: any, index: any, column: any) {
        if (column.fieldName === "name") {
            return (
                <TextField
                    value={item.name}
                    title={item.name}
                    onChanged={(name) => this.changeItemParameters(index, undefined, name)} />
            );
        }
        if (column.fieldName === "value") {
            return (
                <TextField
                    value={item.value}
                    onChanged={(value) => this.changeItemParameters(index, value)} />
            );
        }
        if (column.fieldName === "default") {
            return (
                <Toggle
                    onText="Tak"
                    offText="Nie"
                    checked={item.isDefault}
                    onChanged={isEnabled => this._switchToggl(index, true)} />
            );
        }
        if (column.fieldName === "isAlwaysPresent") {
            return (
                <Toggle
                    onText="Tak"
                    offText="Nie"
                    checked={item.isAlwaysPresent}
                    onChanged={isEnabled => this._switchToggl(index, false, isEnabled)} />
            );
        }
        if (column.fieldName === "priceDependsOnColor") {
            return (
                <Toggle
                    onText="Tak"
                    offText="Nie"
                    checked={item.priceDependsOnColor}
                    onChanged={isEnabled => this._markSelection(isEnabled)} />
            );
        }
    }

    private _switchToggl(index: number, forDefault: boolean, isEnable?: boolean) {
        this.props.onChange({
            ...this.props.item,
            availableValues: this.props.item.availableValues.map((x: any) => {
                return forDefault
                    ? { ...x, isDefault: this.props.item.availableValues[index] === x }
                    : { ...x, isAlwaysPresent: this.props.item.availableValues[index] === x && isEnable };
            })
        });
    }

    private changeItemParameters(index: number, value?: string, name?: string) {
        let currentValue = this.props.item.availableValues[index];
        this.props.onChange({
            ...this.props.item,
            availableValues: this.props.item.availableValues.map((x: any) => {
                if (currentValue === x) {
                    return value !== undefined ? { ...x, value } : { ...x, name };
                }
                return x;
            })
        });
    }

    private Columns() {
        let notBase = this.props.item.affectsPrice !== AffectsPriceType.Base;
        return [
            {
                key: "column1",
                name: "Nazwa",
                fieldName: "name",
                minWidth: 200,
                maxWidth: 350,
                isResizable: true
            },
            {
                key: "column2",
                name: "Wartość",
                fieldName: "value",
                minWidth: 50,
                maxWidth: 120,
                isResizable: true
            },
            {
                key: "column3",
                name: "Domyślna?",
                fieldName: "default",
                minWidth: 50,
                maxWidth: 90,
                isResizable: true
            },
            notBase && {
                key: "column4",
                name: "Zawsze dostępny?",
                fieldName: "isAlwaysPresent",
                minWidth: 50,
                maxWidth: 90,
                isResizable: true,
            },
            {
                key: "column5",
                name: "Cena zależy od koloru?",
                fieldName: "priceDependsOnColor",
                minWidth: 50,
                maxWidth: 130,
                isResizable: true
            }
        ].filter(x => typeof x === "object");
    } 
}
