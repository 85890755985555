import { createAction } from "redux-actions";
import { KeyListActionTypes, ProductKeyListItem } from "./types";

export const loadProductKeyList = createAction(
    KeyListActionTypes.LOAD,
    (items: ProductKeyListItem[]) => items
);

export const productKeyDelete = createAction(
    KeyListActionTypes.DELETE,
    (id: number) => id
);
