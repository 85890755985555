import * as React from "react";
import { DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import Validate from "../../helpers/Validate";
import { Dialog } from "../../../../components/dialogs";
import "../../styles/label.less";
import { ICustomer, ICustomerListItem, IRole, IAccountOwner } from "../../models/interfaces";
import { Label } from "office-ui-fabric-react";
import { CustomerActions } from "../../actions/CustomerActions";
import { Select } from "../../../../components";
import { EntityTypesActions, AccountOwnerActions } from "../../actions";

export interface Props {
    title: string;
    customer: ICustomer;
    customerId?: number;
    accountOwner?: IAccountOwner;
    disabledTextFields?: boolean;
    hidden: boolean;
    edited?: boolean;
    onDismiss: () => void;
    onChanged?: (customer: ICustomer) => void;
    onSaved?: (customer: ICustomerListItem) => void;
}

export interface State {
    roles: IRole[];
    accountOwners: IAccountOwner[];
}

export default class CustomerDialog extends React.Component<Props, State> {
    closeButton: any;
    textFields = {
        "name": undefined as TextField,
        "postCode": undefined as TextField,
        "postLocation": undefined as TextField,
        "location": undefined as TextField,
        "street": undefined as TextField,
        "county": undefined as TextField,
        "buildingNumber": undefined as TextField,
        "localNumber": undefined as TextField,
        "phoneNumber": undefined as TextField,
        "taxId": undefined as TextField,
    };

    constructor(props: Props) {
        super(props);
        this.state = { roles: [], accountOwners: [] };
        this._readRoles();      
        this._readAccountOwners();  
    }

    public render() {
        let { customer, onChanged, disabledTextFields } = this.props;
        if (customer === undefined) { return null; }
        let focus = { validateOnFocusOut: true, validateOnLoad: false };
        return (
            <Dialog
                modalProps={{ isBlocking: false, containerClassName: "ms-dialogMainOverride" }}
                {...this.props}>
                <div className="ms-Grid">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg8">
                            <TextField label="Nazwa"
                                ref={(name: any) => this.textFields.name = name}
                                autoFocus
                                defaultValue={this.props.customer.name}
                                onChanged={(name) => onChanged({ ...customer, name })}
                                onGetErrorMessage={v => Validate.empty(v)}
                                required
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4">
                            <TextField label="NIP" ref={(taxId: any) => this.textFields.taxId = taxId}
                                defaultValue={this.props.customer.taxId}
                                onChanged={(taxId) => onChanged({ ...customer, taxId })}
                                onGetErrorMessage={v => Validate.valueLength(v, 10)}
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg8">
                            <Label className="custom-label">Adres</Label>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4">
                            <Label className="custom-label">Dane - pozostałe</Label>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4">
                            <TextField label="Ulica"
                                ref={(street: any) => this.textFields.street = street}
                                defaultValue={this.props.customer.street}
                                onChanged={(street) => onChanged({ ...customer, street })}
                                onGetErrorMessage={v => Validate.valueLength(v, 50)}
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                            <TextField label="Numer domu"
                                ref={(buildingNumber: any) => this.textFields.buildingNumber = buildingNumber}
                                defaultValue={this.props.customer.buildingNumber}
                                onChanged={(buildingNumber) => onChanged({ ...customer, buildingNumber })}
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                            <TextField label="Numer lokalu"
                                ref={(localNumber: any) => this.textFields.localNumber = localNumber}
                                defaultValue={this.props.customer.localNumber}
                                onChanged={(localNumber) => onChanged({ ...customer, localNumber })}
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4">
                            <TextField label="Numer telefonu"
                                ref={(phoneNumber: any) => this.textFields.phoneNumber = phoneNumber}
                                defaultValue={this.props.customer.phoneNumber}
                                onChanged={(phoneNumber) => onChanged({ ...customer, phoneNumber })}
                                onGetErrorMessage={v => Validate.valueLength(v, 14)}
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4">
                            <TextField label="Miejscowość"
                                ref={(location: any) => this.textFields.location = location}
                                defaultValue={this.props.customer.location}
                                onChanged={(location) => onChanged({ ...customer, location })}
                                onGetErrorMessage={v => Validate.valueLength(v, 30)}
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4" />
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4">
                            <Label>Domyślna rola w projektach</Label>
                            <Select
                                noResultsText={"Nie zdefiniowano ról"}
                                isLoading={this.state.roles.length === 0}
                                isClearable
                                labelKey={"name"}
                                placeholder={"Nie zdefiniowano"}
                                options={this.state.roles}
                                onChange={(defaultRoleInProjectId) => onChanged({ ...customer, defaultRoleInProjectId })}
                                withLoaded
                                selectedId={this.props.customer.defaultRoleInProjectId}
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                            <TextField label="Kod pocztowy"
                                ref={(postCode: any) => this.textFields.postCode = postCode}
                                defaultValue={this.props.customer.postCode}
                                onChanged={(postCode) => onChanged({ ...customer, postCode })}
                                onGetErrorMessage={v => Validate.valueLength(v, 8)}
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <TextField label="Poczta"
                                ref={(postLocation: any) => this.textFields.postLocation = postLocation}
                                defaultValue={this.props.customer.postLocation}
                                onChanged={(postLocation) => onChanged({ ...customer, postLocation })}
                                onGetErrorMessage={v => Validate.valueLength(v, 30)}
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <TextField label="Kraj"
                                ref={(county: any) => this.textFields.county = county}
                                defaultValue={this.props.customer.county}
                                onChanged={(county) => onChanged({ ...customer, county })}
                                onGetErrorMessage={v => Validate.valueLength(v, 30)}
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4">
                            <Label>Opiekun</Label>
                            <Select
                                noResultsText={"Brak opiekunów"}
                                isLoading={this.state.accountOwners.length === 0}
                                isClearable
                                labelKey={"name"}
                                placeholder={"Nie zdefiniowano"}
                                options={this.state.accountOwners}
                                selectedId={this.props.customer.accountOwnerId}
                                onChange={(accountOwnerId) => this.props.onChanged({ ...customer, accountOwnerId })}
                                disabled={disabledTextFields}
                                withLoaded
                                {...focus}/>
                        </div>
                    </div>
                </div>
                {this.props.disabledTextFields 
                    ? (
                        <DialogFooter>
                            <PrimaryButton
                                onClick={this.props.onDismiss}
                                text="Ok"/>
                        </DialogFooter>
                    )
                    : (
                        <DialogFooter>
                            <PrimaryButton
                                onClick={this._validateOnSave.bind(this)}
                                text="Zapisz"
                                disabled={this.props.hidden}/>
                            <DefaultButton
                                onMouseEnter={() => this.closeButton.focus}
                                componentRef={ref => this.closeButton = ref}
                                onClick={this.props.onDismiss}
                                text="Anuluj"/>
                        </DialogFooter>
                    )
                }
            </Dialog>
        );
    }

    private _validateOnSave() {
        if (!Validate.textFields(this.textFields)) { return; }
        this._saveDialog();
    }

    private _saveDialog() {
        if (this.props.edited) {
            CustomerActions.editCustomer({ ...this.props.customer, id: this.props.customerId })
                .then(x => {
                    this.props.onSaved(x.body[0].body);
                });
        }
        else {
            CustomerActions.addCustomers(this.props.customer)
                .then(x => {
                    this.props.onSaved(x.body[0].body);
                });
        }
    }

    private _readRoles() {
        EntityTypesActions.getEntityTypes()
            .then(roles => {
                this.setState({...this.state, roles});
            });
    }

    private _readAccountOwners() {
        AccountOwnerActions.getAllAccountOwners()
            .then(accountOwners => {
                this.setState({...this.state, accountOwners});
            });
    }
}
