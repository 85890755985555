import * as React from "react";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { IPoint } from "office-ui-fabric-react/lib/utilities/positioning";
import PositionModel from "../../../models/PositionModel";
import { ConfirmDialog } from "../../../../../components/dialogs/ConfirmDialog";

interface State {
    target: IPoint | HTMLElement;
    item: PositionModel;
    isVisible: boolean;
}

interface Props {
    onDelete: (item: PositionModel) => void;
    onCopy: (item: PositionModel) => void;
    onCopyToCustom: (item: PositionModel) => void;
    onAddNew: (item: PositionModel, before: boolean, custom: boolean) => void;
}

export default class PositionContextualMenu extends React.Component<Props, State> {
    private _confirmDeletePositionDialog: ConfirmDialog;
    
    constructor(props: Props) {
        super(props);
        this.state = {
            isVisible: false,
            target: undefined,
            item: undefined
        };
        this.hide = this.hide.bind(this);
    }

    render() {
        return (
            <div>
                {this.state.isVisible ? (
                    <ContextualMenu
                        shouldFocusOnMount
                        target={this.state.target}
                        onDismiss={this.hide}
                        items={
                            ([
                                {
                                    key: "add",
                                    iconProps: {
                                        iconName: "Add"
                                    },
                                    name: "Dodaj",
                                    subMenuProps: {
                                        items: [
                                            {
                                                key: "add-standard",
                                                text: "Pozycje",
                                                subMenuProps: {
                                                    items: [
                                                        {
                                                            key: "add-standard-before",
                                                            text: "Przed zaznaczoną",
                                                            onClick: () => this.props.onAddNew(this.state.item, true, false)
                                                        },
                                                        {
                                                            key: "add-standard-after",
                                                            text: "Po zaznaczonej",
                                                            onClick: () => this.props.onAddNew(this.state.item, false, false)
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                key: "add-custom",
                                                text: "Pozycje niestandardową",
                                                subMenuProps: {
                                                    items: [
                                                        {
                                                            key: "add-standard-before",
                                                            text: "Przed zaznaczoną",
                                                            onClick: () => this.props.onAddNew(this.state.item, true, true)
                                                        },
                                                        {
                                                            key: "add-standard-after",
                                                            text: "Po zaznaczonej",
                                                            onClick: () => this.props.onAddNew(this.state.item, false, true)
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    key: "copy",
                                    iconProps: {
                                        iconName: "Copy"
                                    },
                                    name: "Kopiuj",
                                    onClick: () => this.props.onCopy(this.state.item)
                                },
                                this.state.item.isCustom ? null : {
                                    key: "copy",
                                    iconProps: {
                                        iconName: "FileTemplate"
                                    },
                                    name: "Kopiuj do niestandardowej",
                                    onClick: () => this.props.onCopyToCustom(this.state.item)
                                },
                                {
                                    key: "delete",
                                    iconProps: {
                                        iconName: "Delete"
                                    },
                                    name: "Usuń",
                                    onClick: () => this._confirmDeletePositionDialog.show()
                                },
                            ]).filter(i => i !== null)
                        }
                    />) : (null)}
                <ConfirmDialog
                    ref={ref => this._confirmDeletePositionDialog = ref}
                    text={"Czy na pewno chcesz usunąć pozycję oferty?"}
                    onConfirmed={() => this.props.onDelete(this.state.item)}/>
            </div>
        );
    }

    public hide() {
        this.setState({ isVisible: false });
    }

    public show(item: PositionModel, target: IPoint | HTMLElement) {
        this.setState({ isVisible: true, item, target });
    }
}
