import * as React from "react";
import { Person } from "../../components";
import "./Header.less";
import { Link, ILinkStyles, getTheme } from "office-ui-fabric-react";
import { Theme } from "../../tools/themes";

export class Header extends React.Component<any, any> {
    private _linkStyles: Partial<ILinkStyles>;
    private _backgroundColor: string;

    componentWillMount() {
        let theme = getTheme();
        this._backgroundColor = theme.semanticColors.menuHeader;
        this._linkStyles = {
            root: [
                {
                    color: theme.semanticColors.menuItemText,
                    selectors: {
                        "&:focus": {
                            color: theme.semanticColors.menuItemText
                        },
                        "&:active, &:hover, &:active:hover": {
                            color: theme.semanticColors.menuItemTextHovered
                        },
                    }
                }
            ]
        };
    }

    render() {
        //<Link href="#/projects" styles={this._linkStyles}>Projekty</Link>
        let theme = Theme.currentTheme;
        return (
            <header style={{backgroundColor: this._backgroundColor}}>
                <div className="header-banner">
                    <div className="header-logo">
                        <a className="logo-link" href={theme.link} aria-label={`Przejdź do strony ${theme.name}`}>
                            <img src={theme.logo} width="130" height="45" alt={theme.name} />
                        </a>
                    </div>
                    <div className="header-name">
                        <Link href="#" styles={this._linkStyles}>Oferty</Link>
                    </div>
                    <div className="header-persona" id="Person">
                        <Person selector="#Person"/>
                    </div>
                </div>
            </header>
        );
    }
}
