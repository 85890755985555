import { IPoint } from "office-ui-fabric-react/lib/utilities/positioning";
import { IKeyItem, IKeyItemValue } from "../../../../models/interfaces";
import { AffectsPriceType, ItemType } from "../../../../models/Enums";

export const enum KeyItemMenuActionTypes {
    DISMISS_MENU = "Admin/KeyItemMenu/DISMISS_MENU",
    SHOW_MENU = "Admin/KeyItemMenu/SHOW_MENU",
    ON_CHANGE = "Admin/KeyItemMenu/ON_CHANGE",
    SHOW_ADD_DIALOG = "Admin/KeyItemMenu/SHOW_ADD_DIALOG",
    SHOW_EDIT_DIALOG = "Admin/KeyItemMenu/SHOW_EDIT_DIALOG",
    DISMISS_DIALOG = "Admin/KeyItemMenu/DISMISS_DIALOG",
    VALUE_ADD = "Admin/KeyItemMenu/VALUE_ADD",
    VALUE_DELETE = "Admin/KeyItemMenu/VALUE_DELETE"
}

export class KeyItem implements IKeyItem {
    id: number = undefined;
    order: number = undefined;
    name: string = "";
    description?: string = "";
    factor?: number = undefined;
    type: ItemType = undefined;
    affectsPrice: AffectsPriceType;
    availableValues?: IKeyItemValue[] = [];
    default?: IKeyItemValue = {value: "", };
    min?: number = 0;
    isSize?: boolean = false;
    minValueToMultiple?: number;
    sizeSymbol?: string = "";
}

export class KeyItemMenuState {
    target: IPoint | HTMLElement = { x: undefined, y: undefined };
    item: IKeyItem = new KeyItem();
    isContextMenuVisible: boolean = false;
    showDialog: boolean = false;
    isEditing: boolean = false;
}

export class KeyItemValue implements IKeyItemValue {
    id?: number = null;
    name?: string = "";
    label?: string = null;
    selectedKeyItemValuesId?: number = null;
    value: string | number = "";
    isDefault?: boolean = false;
}
