import * as React from "react";
import ItemContextualMenu from "../ItemContextualMenu";
import { ConfirmDialog } from "../../../../components/dialogs";
import { ContextualMenuItemType } from "office-ui-fabric-react";
import { Roles, RolesHelper } from "../../../../tools/auth";

interface IKeysListMenuProps {
    isContextMenuVisible: boolean;
    target: any;
    onDismiss: () => void;
    onEdit: any;
    onDelete: () => void;
    onEditCosts: any;
    onCopy: any;
}

export default class KeysListMenu extends React.Component<IKeysListMenuProps, any> {
    private _confirmDialog: ConfirmDialog;

    render() {
        return (
            <div>
                <ItemContextualMenu
                    isContextMenuVisible={this.props.isContextMenuVisible}
                    onDelete={() => this._confirmDialog.show()}
                    onDismiss={this.props.onDismiss}
                    onEdit={this.props.onEdit}
                    items={RolesHelper.filter(this._items)}
                    target={this.props.target}
                    roles={[Roles.Administrator]}/>
                <ConfirmDialog
                    ref={ref => this._confirmDialog = ref}
                    onConfirmed={this.props.onDelete}
                    text="Czy na pewno chcesz usunąć ten klucz?"/>
            </div>
        );
    }

    private _items = [
        {
            key: "div-1",
            itemType: ContextualMenuItemType.Divider
        },
        {
            key: "editCost",
            name: "Edytuj ceny",
            iconProps: { iconName: "Money" },
            onClick: (item: any) => this.props.onEditCosts(item.id),
            roles: [Roles.Administrator, Roles.AdminCostsEditor]
        },
        {
            key: "div-2",
            itemType: ContextualMenuItemType.Divider,
            roles: [Roles.Administrator]
        },
        {
            key: "copy",
            iconProps: {
                iconName: "Copy"
            },
            name: "Duplikuj",
            onClick: (item: any) => this.props.onCopy(item.id),
            roles: [Roles.Administrator]
        }
    ];
}
