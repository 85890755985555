import { IEvent, errorModel, succesModel } from "../NotificationModels";
import { Event } from "./Event";

export class CustomerCreated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Utworzono klienta " + event.body.name,
            }
        };
    }

    protected onError(event?: IEvent) {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się utworzyć klienta"
            }
        };
    }
}

export class CustomerUpdated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Zaktualizowano klienta " + event.body.name,
            }
        };
    }

    protected onError(event?: IEvent) {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się zaktualizować klienta"
            }
        };
    }
}

export class CustomerWorkerCreated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Utworzono pracownika klienta " + event.body.name,
            }
        };
    }

    protected onError(event?: IEvent) {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się utworzyć pracownika klienta"
            }
        };
    }
}

export class CustomerWorkerUpdated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Zaktualizowano pracownika klienta " + event.body.name,
            }
        };
    }

    protected onError(event?: IEvent) {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się zaktualizować pracownika klienta"
            }
        };
    }
}

export class ProjectCreated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Utworzono projekt " + event.body.name,
            }
        };
    }

    protected onError(event?: IEvent) {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się utworzyć projektu"
            }
        };
    }
}

export class ProjectUpdated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Zaktualizowano projekt " + event.body.name,
            }
        };
    }

    protected onError(event?: IEvent) {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się zaktualizować projektu"
            }
        };
    }
}
