export enum Sex {
    Male,
    Female
}

export class SexEnumExtentions {
    public static getFromString(key: string) {
        return key === "male" ? Sex.Male : Sex.Female;
    }
}

export enum OfferStatus {
    InEditing = 0,
    Sent,
    Canceled,
    ArchivedCompleted,
    ArchivedRejected
}

export const OfferStatuses : { [id: number]: string; } = { 
    0: "W edycji",
    1: "Wysłana do klienta",
    2: "Anulowana",
    3: "Archiwalna (Zrealizowana)",
    4: "Archiwalna (Odrzucona)",
};

export enum PrintTemplate {
    Default = 0,
    Gif
}
