import * as React from "react";
import { Label, Link, MessageBar } from "office-ui-fabric-react";

interface ICustomColorEditorProps {
    assignedColorsCount: number;
    onClickColorsLink: () => void;
}

export default class CustomColorPanel extends React.Component<ICustomColorEditorProps, any> {
    render() {
        let { onClickColorsLink, assignedColorsCount } = this.props;
        return (
            <div>
                <Label className="label-bold">Kolory</Label>
                {
                    assignedColorsCount > 0 ?
                        <Label>
                            Przydzielono {assignedColorsCount} kolorów
                            (
                            <Link onClick={onClickColorsLink}>
                                pokaż
                            </Link>
                            )
                        </Label>
                        :
                        <MessageBar>
                            {"Nieprzydzielono niestandardowego zestawu kolorów"}
                        </MessageBar>
                }
            </div>
        );
    }
}
