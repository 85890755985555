import { StatusesActions } from "../actions";
import { OfferStatus, OfferStatuses } from "../models/Enums";
import { IContextualMenuProps, IContextualMenuItem } from "office-ui-fabric-react/lib/ContextualMenu";

export default class OfferStatusService {
    private _statuses: {[id: string]: OfferStatus[]};
    private _areImported: boolean;

    get areImported(): boolean {
        return this._areImported;
    }
    
    init(onInit: () => void) {
        StatusesActions.getStatusesMap().then(statuses => {
            this._statuses = statuses;
            this._areImported = true;
            onInit();
        });
    }

    checkIfAnyStatuesAreAvailableFor(status: OfferStatus) {
        return this.getSubMenuToSetStatus(status, () => {}).items.length > 0;
    }

    getSubMenuToSetStatus(currentStatus: OfferStatus, handleOnClick: (item: IContextualMenuItem) => void) : IContextualMenuProps {
        if (this._areImported) {
            let statuses = this._statuses[OfferStatus[currentStatus].toString().toCamelCase()];
            if (statuses === undefined) {
                statuses = [];
            }
            return {
                onItemClick: (ev, item) => handleOnClick(item),
                items: statuses.map(s => {
                    return {
                        key: `setStatus-${s}`, 
                        name: OfferStatuses[s]
                    };
                })
            };
        }

        return {
            items: [
                {
                    key: "Loading",
                    name: "Ładowanie..."
                }
            ]
        };
    }
}
