import { Request } from "../../../tools/requests";
import RalColor from "../../../models/RalColor";

export default class RalColorsActions {
    static getAll() { 
        return Request.query<RalColor[]>("ReadRalColors");
    }

    static create(ral: RalColor) {
        return Request.command("CreateRal", ral);
    }

    static update(ral: RalColor) {
        return Request.command("UpdateRal", ral);
    }

    static delete(ral: RalColor) {
        return Request.command("DeleteRal", {id: ral.id});
    }

    static setDefault(ral: RalColor) {
        return Request.command("SetDefaultRalColor", {id: ral.id});
    }

    static getDefaultColor() {
        return Request.query<RalColor>("ReadDefaultRalColor");
    }
}
