import * as React from "react";
import "../../../styles/key/styles.less";

interface IConstKeyItemProps {
    value: string | number;
    hint?: string;
}

export default class ConstKeyItem extends React.Component<IConstKeyItemProps, any> {
    render() {
        return (
            <div className="constKeyItem" title={this.props.hint}>
                {this.props.value}
            </div>
        );
    }
}
