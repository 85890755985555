import Publisher from "./Publisher";
import { IKeyItemValue } from "../../../models/interfaces";
import { IKeyItemMetadata, IKeyItemData } from "./interfaces";
import { ItemType } from "../../../models/Enums";

export default class KeyItemModel extends Publisher implements IKeyItemData {
    metadata: IKeyItemMetadata;
    current: IKeyItemValue;

    constructor(metadata: IKeyItemMetadata, current?: IKeyItemValue) {
        super();
        this.metadata = metadata;
        this.current = current;
    }

    /** Metoda zwraca wartość elementu. Jeśli nie ma ustawionej wartości to brana jest pod uwagę wartość domyślna. */
    getValue(): number | string {
        let current = this.getCurrent();
        return current === undefined || current === null ? null : current.value;
    }

    getSelectedId(): number {
        let current = this.getCurrent();
        return current === undefined || current === null ? null : current.selectedKeyItemValuesId;
    }

    getCurrent() {
        if (this.current !== null && this.current !== undefined) {
            return this.current;
        }
        if (this.metadata.default !== null && this.metadata.default !== undefined) {
            return this.metadata.default;
        }

        let defaultItemValue = this.metadata.availableValues.filter(x => x.isDefault)[0];
        return defaultItemValue === undefined ? null : defaultItemValue;
    }

    getCurrentId() {
        let current = this.getCurrent();
        if (current === null || this.metadata.type === ItemType.RAL) {
            return null;
        }
        return current.id;
    }

    toLabel() {
        return this.getValue().toString();
    }

    onValueChange(newValue: IKeyItemValue) {
        newValue.selectedKeyItemValuesId = this.getSelectedId();
        this.current = newValue;
        if (this.metadata.affectsPrice) {
            this.inform();
        }
    }

    clone() {
        return new KeyItemModel(this.metadata, this.current);
    }
}
