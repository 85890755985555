export enum AffectsPriceType {
    None,
    Base,
    BaseOption,
    Adds,
    MultipliesBySelf,
    MultipliesByFactor
}

export enum ItemType {
    Number,
    SimpleChoice,
    Const,
    RAL,
    CustomText
}
