import * as React from "react";
import { CheckboxVisibility, Spinner, SpinnerSize, IconButton, Panel, PanelType } from "office-ui-fabric-react/lib";
import { Label } from "office-ui-fabric-react/lib/Label";
import { DetailsList, DetailsListLayoutMode, IColumn, Selection } from "office-ui-fabric-react/lib/DetailsList";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
//import { OfferListItem } from "../../models/OfferListItem";
//import { ContextualMenu } from "../list/ContextualMenu";
import DisplayHelper from "../../../../helpers/DisplayHelper";
import { connect } from "react-redux";
import { Dispatch } from "redux";
//import NavigationHelper from "../../helpers/NavigationHelper";
import { LabelMenu, LazyList } from "../../../../components";
//import { OfferStatuses, OfferStatus } from "../../models/Enums";
import { WithContext as ReactTags } from "react-tag-input";
//import { CommentsThread } from "../comments";

interface Props {
}

interface ProjectItem {
    name: string;
}

export class ProjectsList extends React.Component<Props, any> { 
    render() {
        return (
            <div>
                <LazyList<ProjectItem>
                    columns={ProjectsList.Columns}
                    onLoad={() => {}}
                    onDownload={() => {}}
                    />
            </div>
        );
    }

    private static Columns : IColumn[] = [
        {
            key: "project-name",
            name: "Nazwa",
            fieldName: "number",
            minWidth: 100,
            maxWidth: 160,
            isResizable: true,
        }
    ]
}