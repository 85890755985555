interface String {
    toCamelCase(): string;
    isNumber(): boolean;
    ifEmpty(value: string): string;
}
  
String.prototype.toCamelCase = function() {
    return (this as string).replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
        if (+match === 0) {
            return ""; 
        }
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
};

String.prototype.isNumber = function () {
    return isNaN(Number((this as string))) === false;
};

String.prototype.ifEmpty = function(value: string) {
    if (value === null || value === undefined || value === "") {
        return this;
    }
    return value;
};
