import { IContact, ICustomer, IPerson, IProject } from "./interfaces";

export class OfferDetailsModel {
    customer: ICustomer;
    customerWorker: IPerson;
    project: IProject;
    customers: ICustomer[];
    customerDialogHidden: boolean;
    customerWorkerDialogHidden: boolean;
    projectDialogHidden: boolean;
    title: string;
}

export class Customer implements ICustomer {}

export class Project implements IProject {
    contacts?: IContact[] = [];
}

export class CustomerWorker implements IPerson {}
