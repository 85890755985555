import { OfferStatus } from "../../models/Enums";
import { OfferListOnlyMy } from "../../constants/StorageItems";
import { DateRange, Tag } from "../../models/interfaces";

export const enum OffersListFiltersActionTypes {
    CHANGE_VISIBILITY = "@@Offers/Filters/ChangeVisibility",
    CHANGE_SEARCH_TEXT = "@@Offers/Filters/ChangeSearchText",
    CLEAR = "@@Offers/Filters/Clear",
    SELECT_STATUS = "@@Offers/Filters/SelectStatus",
    CLEAR_SELECTED_STATUSES = "@@Offers/Filters/ClearSelectedStatuses",
    DESELECT_STATUS = "@@Offers/Filters/DeselectStatus",
    SET_ONLY_MY = "@@Offers/Filters/SetOnlyMy",
    LOAD_TAGS = "@@Offers/Filters/LoadTags",
    CLEAR_SELECTED_TAGS = "@@Offers/Filters/ClearSelectedTags",
    SELECT_TAG = "@@Offers/Filters/SelectTag",
    DESELECT_TAG = "@@Offers/Filters/DeselectTag",
    SET_NEXT_CONTACT_DATE_RANGE = "@@Offers/Filters/SetNextContactDateRange"
}

export class OffersListFiltersState {
    data: IOffersListFilters;
    areVisible: boolean = true;

    constructor() {
        if (localStorage.getItem(OfferListOnlyMy) === null) {
            localStorage.setItem(OfferListOnlyMy, "true");
        }
        this.data = {
            onlyMy: localStorage.getItem(OfferListOnlyMy) === "true",
            searchText: undefined,
            statuses: [ OfferStatus.InEditing, OfferStatus.Sent ],
            tags: [],
            avaliableTags: undefined,
            nextContactDates: {}
        };
    }
}

export interface IOffersListFilters {
    onlyMy: boolean;
    searchText: string;
    statuses: OfferStatus[];
    tags: number[];
    avaliableTags: Tag[];
    nextContactDates: DateRange;
}
