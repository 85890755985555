import { Sex } from "../models/Enums";

export default class TextHelper {
    static isNumber(value: number) : string {
        return value === undefined || value === null ? "" : value.toString();
    }

    static isEmpty(value: string) : number {
        return value === "" ? null : Number(value);
    }

    static isSex(value: Sex) : string {
        return value === undefined ? "male" :
             value === Sex.Male ? "male" : "female";
    }

    static sexType(value: string) : Sex {
        return value === "male" ? Sex.Male : Sex.Female;
    }

    static dotOrComma(value: string, expected: string) : boolean {
        return value + "." !== expected
            && value + "," !== expected;
    }

    static contains(a: string, b: string) {
        if (a === undefined || b === undefined) { return false; }
        let item1 = a.toLocaleLowerCase();
        let item2 = b.toLocaleLowerCase();
        return item1.includes(item2);
    }

    static include = (a: string, b: string ) => {
        return a === null 
            ? false 
            : TextHelper.contains(a, b);
    }
}
