import * as React from "react";
import { Nav } from "office-ui-fabric-react/lib/Nav";

export default class NavNavigator extends React.Component<any, any> {
    render() {
        return <Nav groups={[{links: this.links}]}/>;
    }

    private links = [
        {
            key: "keys",
            name: "Klucze produktu",
            url: "/#/admin/keys",
            iconProps: { iconName: "Permissions" },
            isExpanded: true
        },
        {
            key: "rals",
            name: "Kolory",
            url: "/#/admin/rals",
            iconProps: { iconName: "color" },
            isExpanded: true
        },
        {
            key: "tags",
            name: "Tagi",
            url: "/#/admin/tags",
            iconProps: { iconName: "tag" },
            isExpanded: true
        }
    ];
}
