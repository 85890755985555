import { OffersDetailsActionTypes } from "./types";
import { createAction } from "redux-actions";
import { ICustomer, IPerson, IProject, ICustomerListItem, IProjectListItem, IPersonListItem } from "../../models/interfaces";

// Customer
export const onDismissCustomerDialog = createAction(
    OffersDetailsActionTypes.CUSTOMER_DIALOG_DISMISS
);

export const onEditCustomer = createAction(
    OffersDetailsActionTypes.CUSTOMER_ON_EDIT,
    (customer: ICustomer) => customer
);

export const onAddCustomer = createAction(
    OffersDetailsActionTypes.CUSTOMER_ON_ADD
);

export const onChangeCustomer = createAction(
    OffersDetailsActionTypes.CUSTOMER_ON_CHANGE,
    (customer: ICustomer) => customer
);

export const onLoadCustomers = createAction(
    OffersDetailsActionTypes.CUSTOMERS_ON_LOAD,
    (customers: ICustomerListItem[]) => customers
);

// Customer worker
export const onDismissCustomerWorkerDialog = createAction(
    OffersDetailsActionTypes.CUSTOMER_WORKER_DIALOG_DISMISS
);

export const onEditCustomerWorker = createAction(
    OffersDetailsActionTypes.CUSTOMER_WORKER_ON_EDIT,
    (worker: IPerson) => worker
);

export const onAddCustomerWorker = createAction(
    OffersDetailsActionTypes.CUSTOMER_WORKER_ON_ADD
);

export const onLoadCustomerWorkers = createAction(
    OffersDetailsActionTypes.CUSTOMER_WORKER_ON_LOAD,
    (workers: IPersonListItem[]) => workers
);

export const onChangeCustomerWorker = createAction(
    OffersDetailsActionTypes.CUSTOMER_WORKER_ON_CHANGE,
    (worker: IPerson) => worker
);

// Project
export const onDismissProjectDialog = createAction(
    OffersDetailsActionTypes.PROJECT_DIALOG_DISMISS
);

export const onAddProject = createAction(
    OffersDetailsActionTypes.PROJECT_ON_ADD
);

export const onEditProject = createAction(
    OffersDetailsActionTypes.PROJECT_ON_EDIT,
    (project: IProject) => project
);

export const onLoadProjects = createAction(
    OffersDetailsActionTypes.PROJECTS_ON_LOAD,
    (projects: IProjectListItem[]) => projects
);

export const onChangeProject = createAction(
    OffersDetailsActionTypes.PROJECT_ON_CHANGE,
    (project: IProject) => project
);
