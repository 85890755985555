import * as React from "react";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { IPoint } from "office-ui-fabric-react/lib/utilities/positioning";
import { IContact } from "../../../models/interfaces";
import { ConfirmDialog } from "../../../../../components/dialogs/ConfirmDialog";

interface State {
    target: IPoint | HTMLElement;
    item: IContact;
    isVisible: boolean;
}

interface Props {
    onDelete: (item: IContact) => void;
}

export class ContactContextualMenu extends React.Component<Props, State> {
    private _confirmDeleteItemDialog: ConfirmDialog;
    
    constructor(props: Props) {
        super(props);
        this.state = {
            isVisible: false,
            target: undefined,
            item: undefined
        };
        this.hide = this.hide.bind(this);
    }

    render() {
        return (
            <div>
                {this.state.isVisible ? (
                    <ContextualMenu
                        shouldFocusOnMount
                        target={this.state.target}
                        onDismiss={this.hide}
                        items={
                            ([
                                {
                                    key: "delete",
                                    iconProps: {
                                        iconName: "Delete"
                                    },
                                    name: "Usuń",
                                    onClick: () => this._confirmDeleteItemDialog.show()
                                },
                            ]).filter(i => i !== null)
                        }
                    />) : (null)}
                <ConfirmDialog
                    ref={ref => this._confirmDeleteItemDialog = ref}
                    text={"Czy na pewno chcesz usunąć powiązanie podmiotu?"}
                    onConfirmed={() => this.props.onDelete(this.state.item)}/>
            </div>
        );
    }

    public hide() {
        this.setState({ isVisible: false });
    }

    public show = (item: IContact, target: IPoint | HTMLElement) => {
        this.setState({ isVisible: true, item, target });
    }
}
