import * as React from "react";
import { CommandBar as FabricCommandBar } from "office-ui-fabric-react/lib/CommandBar";
import { CommandButton } from "office-ui-fabric-react/lib/Button";
import { autobind } from "office-ui-fabric-react/lib/Utilities";
import { IContextualMenuItem } from "office-ui-fabric-react/lib";
import { changeFilterVisibility } from "../../store/offersListFilters/actions";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Request } from "../../../../tools/requests";
import { ITemplateOffer } from "../../models/Templates";
import { setTemplates } from "../../store/offersListTemplates/actions";

interface Props {
    templates: ITemplateOffer[];
    filtersAreVisible: boolean;
    changeFilterVisible: () => void;
    onLoadedTemplates: (templates: ITemplateOffer[]) => void;
}

class CommandBar extends React.Component<Props, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className="fixed-command-bar">
                <FabricCommandBar
                    items={[
                        this._addItem()
                    ]}
                    farItems={[{
                        key: "filters",
                        iconProps: {
                            iconName: this.props.filtersAreVisible ? "FilterSolid" : "Filter"
                        },
                        title: "Pokaż/ukryj filtry",
                        onClick: () => this.props.changeFilterVisible()
                    }]}>
                </FabricCommandBar>
            </div>
        );
    }

    componentDidMount() {
        Request.query<ITemplateOffer[]>("ReadTemplates").then(templates => {
            this.props.onLoadedTemplates(templates);
        });
    }

    @autobind
    private _renderSplitButtonMenuItem(item: IContextualMenuItem) {
        return (
            <div>
                <CommandButton
                    iconProps={{ iconName: "Add" }}
                    href={item.href}
                    title="Nowa (/+N)"
                    text="Nowa"/>
                <span style={{display: "inline-flex", paddingTop: 11}}>|</span>
                <CommandButton
                    onClick={this.onClickChevron}
                    menuProps={{
                        items: item.subMenuProps.items
                    }}/>
            </div>
        );
    }

    @autobind
    private onClickChevron(ev: any) {
        ev.stopPropagation();
        this.toggleDropDownMenuShown(ev);
    }

    @autobind
    private toggleDropDownMenuShown(ev: any) {
        this.setState({
            isContextMenuShown: !this.state.isContextMenuShown
        });
    }
    
    private _addItem() {
        let addItem: IContextualMenuItem = {
            name: "Nowa",
            key: "add",
            iconProps: { iconName: "Add" },
            href: "#/offer/add",
            };
        if (this.props.templates.length > 0) {
            addItem = {
                ...addItem,
                onRender: this._renderSplitButtonMenuItem,
                subMenuProps: {
                    items: this.props.templates.filter(t => !t.isDefault)
                        .map(t => { 
                            return {
                                name: t.name,
                                key: `add-template-${t.name}}`,
                                href: `#/offer/add?template=${t.id}`,
                            };
                        })
                }
            };
        }

        return addItem;
    }
}

function mapStateToProps(state: any) {
    return {
        filtersAreVisible: state.offersListFilters.areVisible,
        templates: state.offersListTemplates.templates
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        changeFilterVisible: () => dispatch(changeFilterVisibility()),
        onLoadedTemplates: (templates: ITemplateOffer[]) => dispatch(setTemplates(templates))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommandBar);
