import { TagsActionTypes, TagsListState } from "./types";
import { Action } from "redux-actions";

const initState = new TagsListState();

export const tagsListReducer = (state: TagsListState = initState, action: Action<any>) => {
    switch (action.type) {
        case TagsActionTypes.LOAD: {
            return { ...state, tags: action.payload };
        }
        case TagsActionTypes.DELETE: {
            let tags = state.tags.filter(t => t.id !== action.payload.id);
            return { ...state, tags };
        }
        case TagsActionTypes.UPDATE: {
            let tags = state.tags.map(t => {
                if (t.id === action.payload.id) {
                    t.name = action.payload.name;
                }
                return t;
            });
            return { ...state, tags };
        }
        default: {
            return state;
        }
    }
};
