import * as React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import "./sortable.less";

export interface SortableItemProps {
    index: number;
    value: { id: number, name: string };
    onContextualMenu: (item: any, event: any) => void;
    getItemTitle?: (item: any) => string;
}

export const SortableItem = SortableElement((item: SortableItemProps) => {
    let title = item.value.name;
    if (item.getItemTitle !== undefined) {
        title = item.getItemTitle(item.value);
    }

    return (
        <li className="SortableItem"
            onContextMenu={(event: any) => {
                event.preventDefault();
                item.onContextualMenu(item.value, event);
                }
            }>
            {title}
        </li>);
});

export interface SortableListProps {
    items: any[];
    onContextualMenu: (item: any, event: any) => void;
    getItemTitle?: (item: any) => string;
}

export const SortableList = SortableContainer((props: SortableListProps) => {
    return props.items !== undefined && (
        <ul className="SortableList">
            {props.items.map((value: any, index: number) => (
                <SortableItem
                    key={`item-${index}`}
                    index={index}
                    value={value}
                    onContextualMenu={props.onContextualMenu}
                    getItemTitle={props.getItemTitle}/>
            ))}
        </ul>
    );
});
