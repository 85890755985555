import * as React from "react";
import { connect, Dispatch } from "react-redux";
import { SortableList } from "../../../../components";
import { moveKeyItem } from "../../store/keyEditor/actions";
import KeyItemMenu from "./KeyItemMenu";
import { keyItemShowMenu } from "../../store/keyItemMenu/actions";
import { IKeyItem } from "../../../../models/interfaces";

interface IKeyItemListProps {
    items: IKeyItem[];
    moveItem: (item: any) => any;
    showMenu: (index: number, event: any) => void;
}

class KeyItemList extends React.Component<IKeyItemListProps, any> {
    render() {
        return (
            <div>
                <SortableList
                    onContextualMenu={this.props.showMenu}
                    helperClass="SortableHelper"
                    axis="xy"
                    items={this.props.items}
                    onSortEnd={(item: any) => this.props.moveItem(item)}/>
                <KeyItemMenu/>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        items: state.keyEditorReducer.productKey.items,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        showMenu: (item: IKeyItem, event: MouseEvent) => dispatch(keyItemShowMenu(item, event)),
        moveItem: (item: any) => dispatch(moveKeyItem(item)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyItemList);
