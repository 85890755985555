import * as React from "react";
import "./react-datasheet.less";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";

export default class DataCell extends React.PureComponent<any, any> {
    _input: any;
    timeout: any;
    constructor(props: any) {
        super(props);
        this.state = { updated: false };
    }

    componentWillUpdate(nextProps: any) {
        if (nextProps.value !== this.props.value) {
            this.setState({ updated: true });
            this.timeout = setTimeout(() => this.setState({ updated: false }), 700);
        }
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.editing === true && this.props.editing === false && this.props.reverting === false) {
            this.onChange(this._input.value);
        }
        if (prevProps.editing === false && this.props.editing === true) {
            if (this.props.clear) {
                this._input.value = "";
            } else {
                this._input.value = this.props.data === null ? this.props.value : this.props.data;
            }
            this._input.focus();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    onChange(value: any) {
        const initialData = this.props.data === null ? this.props.value : this.props.data;
        if ((value === "" || initialData !== value) && this.props.onChange(this.props.row, this.props.col, value.toString().replace(",", "."))) { }
    }

    render() {
        const { row, col, rowSpan, readOnly, colSpan, width, overflow, value, className, editing, selected, onMouseDown, onMouseOver, onDoubleClick, onContextMenu, tooltip, tooltipText } = this.props;
        const style = { width };
        return (
            <td
                className={[
                    className,
                    "cell", overflow,
                    selected && "selected",
                    editing && "editing",
                    readOnly && "read-only",
                    this.state.updated && "updated"
                ].filter(a => a).join(" ")}
                onMouseDown={() => onMouseDown(row, col)}
                onDoubleClick={() => onDoubleClick(row, col)}
                onMouseOver={() => onMouseOver(row, col)}
                onContextMenu={(e) => onContextMenu(e, row, col)}
                colSpan={colSpan || 1}
                rowSpan={rowSpan || 1}
                style={style}
            >
                {tooltip &&
                    <TooltipHost content={tooltipText} calloutProps={{ finalHeight: 30 }} >
                        <span style={{ display: (editing && selected) ? "none" : "block" }}>
                            {value}
                        </span>
                    </TooltipHost>}
                {!tooltip &&
                    <span style={{ display: (editing && selected) ? "none" : "block" }}>
                        {value.toString().replace(".", ",")}
                    </span>}
                <input style={{ display: (editing && selected) ? "block" : "none" }} ref={(input) => this._input = input} />
            </td>
        );
    }
}
