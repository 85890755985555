import * as React from "react";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { Resources } from "../../../../tools/Conf";
import { AuthContext } from "../../../../tools/auth";
import { autobind } from "office-ui-fabric-react";

interface Props {
    threadId: string;
    onCountChange: (count: number) => void;
}

interface State {
    connection: HubConnection;
    count?: number;
}

export default class CommentBadge extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            count: undefined,
            connection: undefined,
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps !== this.props) {
            this._initialize(nextProps.threadId);
        }
    }

    componentDidMount() {
        this._initialize(this.props.threadId);
    }

    render() {
        return <></>;
    }

    private _initialize(threadId: string) {
        if (!threadId || this.state.connection) {
            return;
        }
        
        let resource = Resources.getResource(null);
        let connection = new HubConnectionBuilder()
            .withUrl(`${resource.url}/hubs/comments`, { accessTokenFactory: () => AuthContext.instance.acquireToken(resource.id) })
            .configureLogging(LogLevel.Error)
            .withAutomaticReconnect([0, 0, 10000])
            .build();

        connection.start()
            .then(this._readCommentsCount)
            .catch(err => console.log("Error while establishing connection :("));
        
        connection.on("onCommentsCountChanged", this._commentsCountChange);

        this.setState({connection});
    }

    @autobind
    private _readCommentsCount() {
        this.state.connection.invoke<number>("ReadCommentsCount", this.props.threadId)
            .then(this._commentsCountChange);
    }

    @autobind
    private _commentsCountChange(count: number) {
        this.props.onCountChange(count);
        this.setState({ ...this.state, count });
    }
}
