import { ValidationDialog } from "../components/dialogs";

export default class ValidationStore {
    private static validationDialog: ValidationDialog = null;

    static componentDoMonut(validationDialog: ValidationDialog) {
        ValidationStore.validationDialog = validationDialog;
    }

    static showValidation(messages: any) {
        ValidationStore.validationDialog.setState({ visible: true, messages });
    }
}
