import moment = require("moment");
import numeral = require("numeral");

export default class DisplayHelper {
    static fixed(value: number, _default: any = ""): string {
        if (value === undefined || isNaN(value) || value == null) {
            if (typeof _default === "number") {
                value = <number>_default;
            }
            else {
                return _default.replace(".", ",");
            }
        }
        return value.toFixed(2).replace(".", ",");
    }

    static formatFixed(value: number): string {
        return numeral(value).format("0,0.00");
    }

    static formatDateTime(date: Date, format: string = "YYYY-MM-DD HH:mm") {
        if (date === null) {
            return "";
        }
        let localTime = moment.utc(date, format)
            .local().format(format);
        return localTime;
    }

    static formatDate(date: Date) {
        return DisplayHelper.formatDateTime(date, "YYYY-MM-DD");
    }
}
