import { Request } from "../../../tools/requests";
import { ICustomerListItem, IPersonListItem, ICustomer, IPerson, IProject, IProjectListItem } from "../models/interfaces";

export class CustomerActions {
    static getAllCustomers(): Promise<ICustomerListItem[]> {
        return Request.query<ICustomerListItem[]>("ReadCustomers");
    }

    static getAllWorkers(customerId: number): Promise<IPersonListItem[]> {
        return Request.query<IPersonListItem[]>("ReadCustomerWorkers", { customerId });
    }

    static getAllProjects(): Promise<IProjectListItem[]> {
        return Request.query<IProjectListItem[]>("ReadProjects");
    }

    static getCustomer(id: number): Promise<ICustomer> {
        return Request.freezeQuery<ICustomer>("ReadCustomer", { id });
    }

    static getWorker(id: number): Promise<IPerson> {
        return Request.freezeQuery<IPerson>("ReadCustomerWorker", { id });
    }

    static getProject(id: number): Promise<IProject> {
        return Request.freezeQuery<IProject>("ReadProject", { id });
    }

    static addCustomers(customer: ICustomer) {
        return Request.freezeCommand("CreateCustomer", customer);
    }

    static addWorkers(person: IPerson) {
        return Request.freezeCommand("CreateCustomerWorker", person);
    }

    static addProject(project: IProject) {
        return Request.freezeCommand("CreateProject", project);
    }

    static editWorker(person: IPerson) {
        return Request.freezeCommand("UpdateCustomerWorker", person);
    }

    static editProject(project: IProject) {
        return Request.freezeCommand("UpdateProject", project);
    }

    static editCustomer(customer: ICustomer) {
        return Request.freezeCommand("UpdateCustomer", customer);
    }
}
