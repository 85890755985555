import { createAction } from "redux-actions";
import { OffersListActionTypes } from "./types";
import { OfferListItem } from "../../models/OfferListItem";
import { OfferStatus } from "../../models/Enums";

export const loadOffers = createAction(
    OffersListActionTypes.LOAD_ITEMS,
    (items: OfferListItem[]) => items
);

export const isLoading = createAction(
    OffersListActionTypes.IS_LOADING
);

export const clearItems = createAction(
    OffersListActionTypes.CLEAR_ITEMS
);

export const changeStatus = createAction(
    OffersListActionTypes.ITEM_CHANGE_STATE,
    (input: {id: number, newStatus: OfferStatus}) => input
);

export const deleteOffer = createAction(
    OffersListActionTypes.DELETE_ITEM,
    (id: number) => id
);

export const pushItem = createAction(
    OffersListActionTypes.PUSH_ITEM,
    (item: OfferListItem) => item
);

export const downloadItems = createAction(
    OffersListActionTypes.DOWNLOAD_ITEMS,
    (items: OfferListItem[]) => items
);

export const showComments = createAction(
    OffersListActionTypes.SHOW_COMMENTS,
    (item: OfferListItem) => item
);

export const hideComments = createAction(
    OffersListActionTypes.HIDE_COMMENTS
);
