import * as React from "react";
import { Select } from "../../../../components";
import { CustomerActions } from "../../actions/CustomerActions";
import { ICustomerListItem } from "../../models/interfaces";
import { ICustomer } from "../../models/interfaces";
import CustomerDialog from "./CustomerDialog";
import { connect, Dispatch } from "react-redux";
import { onChangeCustomer, onEditCustomer, onDismissCustomerDialog, onAddCustomer, onLoadCustomers } from "../../store/offerDetails/actions";

interface Props {
    customerId: number;
    customer: ICustomer;
    customers: ICustomerListItem[];
    hidden: boolean;
    edited: boolean;
    title: string;
    disabled?: boolean;
    onChange: (id: number) => void;
    onChanged: (customer: ICustomer) => void;
    onLoad: (customers: ICustomerListItem[]) => void;
    onEdit: (customer: ICustomer) => void;
    onDismiss: () => void;
    onAdd: () => void;
}

class CustomerSelect extends React.Component<Props, any>  {
    private _select: Select;

    constructor(props: any) {
        super(props);
        this._load();
    }

    render() {
        return (
            <div>
                <Select noResultsText="Brak klienta"
                    ref={select => this._select = select}
                    placeholder="Wybierz"
                    buttonsAreVisible={!this.props.disabled}
                    withLoaded
                    onAddClicked={this.props.onAdd}
                    onChange={this.props.onChange}
                    onEditClicked={this._onEditCustomer.bind(this)}
                    options={this.props.customers as ICustomerListItem[]}
                    labelKey="name"
                    selectedId={this.props.customerId}
                    isLoading={this.props.customers === undefined}
                    disabled={this.props.disabled}/>
                <CustomerDialog
                    {...this.props}
                    onSaved={this._onSaved.bind(this)}/>
            </div>
        );
    }

    private _onSaved(customer: ICustomerListItem) {
        let customers = this.props.customers;
        let index = this.props.customers.findIndex(x => x.id === customer.id);
        if (index === -1) {
            this.props.onLoad([customer, ...customers]);
        }
        else {
            customers[index].name = customer.name;
            this.props.onLoad(customers);
        }
        this.props.onChange(customer.id);
    }

    private _onEditCustomer() {
        let customerId = this._select.state.selected.id;
        CustomerActions.getCustomer(customerId)
            .then(customer => {
                this.props.onEdit(customer);
            });
    }

    private _load() {
        CustomerActions.getAllCustomers()
            .then(c => {
                this.props.onLoad(c);
            });
    }
}

function mapStateToProps(state: any) {
    return {
        customer: state.offerDetails.customer,
        customers: state.offerDetails.customers,
        hidden: state.offerDetails.customerDialogHidden,
        edited: state.offerDetails.edited,
        title: state.offerDetails.title
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        onEdit: (customer: ICustomer) => dispatch(onEditCustomer(customer)),
        onDismiss: () => dispatch(onDismissCustomerDialog()),
        onAdd: () => dispatch(onAddCustomer()),
        onLoad: (customers: ICustomerListItem[]) => dispatch(onLoadCustomers(customers)),
        onChanged: (customer: ICustomer) => dispatch(onChangeCustomer(customer))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSelect);
