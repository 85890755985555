import * as React from "react";
import { DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { ChoiceGroup } from "office-ui-fabric-react/lib/ChoiceGroup";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import Validate from "../../helpers/Validate";
import { Dialog } from "../../../../components/dialogs";
import { IPerson, IPersonListItem } from "../../models/interfaces";
import TextHelper from "../../helpers/TextHelper";
import { CustomerActions } from "../../actions/CustomerActions";
import { SexEnumExtentions } from "../../models/Enums";

interface Props {
    title: string;
    edited?: boolean;
    customerId?: number;
    worker?: IPerson;
    hidden: boolean;
    disabledTextFields?: boolean;
    onDismiss: () => void;
    onChange?: (worker: IPerson) => void;
    onSaved?: (worker: IPersonListItem) => void;
}

export class WorkerDialog extends React.Component<Props, any> {
    closeButton: any;
    items = {
        firstname: undefined as TextField,
        surname: undefined as TextField,
        position: undefined as TextField,
        phoneNumber: undefined as TextField,
        mail: undefined as TextField,
    };

    public render() {
        let { worker, onChange, disabledTextFields } = this.props;
        if (worker === undefined) { return null; }
        let focus = { validateOnFocusOut: true, validateOnLoad: false };
        return (
            <Dialog
                {...this.props}
                modalProps={{ isBlocking: false, containerClassName: "dialog-min-width" }}>
                <div className="ms-Grid">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField label="Imie" ref={(firstname: any) => this.items.firstname = firstname}
                                autoFocus
                                {...focus}
                                onChanged={firstname => onChange({ ...worker, firstname })}
                                onGetErrorMessage={v => Validate.empty(v)}
                                defaultValue={worker.firstname}
                                disabled={disabledTextFields}
                                required/>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField label="Nazwisko" ref={(surname: any) => this.items.surname = surname}
                                {...focus}
                                onChanged={surname => onChange({ ...worker, surname })}
                                onGetErrorMessage={v => Validate.empty(v)}
                                defaultValue={worker.surname}
                                disabled={disabledTextFields}
                                required/>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField
                                label="Stanowisko"
                                {...focus}
                                onChanged={position => onChange({ ...worker, position })}
                                ref={(position: any) => this.items.position = position}
                                disabled={disabledTextFields}
                                defaultValue={worker.position}/>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <ChoiceGroup
                                className="inlineflex"
                                label="Płeć"
                                selectedKey={TextHelper.isSex(worker.sex)}
                                disabled={disabledTextFields}
                                onChange={(ev?, sex?) => onChange({ ...worker, sex: SexEnumExtentions.getFromString(sex.key) })}
                                options={[
                                    { key: "female", text: "Kobieta" },
                                    { key: "male", text: "Mężczyzna" }
                                ]}/>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField label="Numer telefonu" ref={(phoneNumber: any) => this.items.phoneNumber = phoneNumber}
                                defaultValue={worker.phoneNumber}
                                onChanged={phoneNumber => onChange({ ...worker, phoneNumber })}
                                onGetErrorMessage={v => Validate.valueLength(v, 14)}
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField label="E-mail" ref={(mail: any) => this.items.mail = mail}
                                defaultValue={worker.mail}
                                onChanged={mail => onChange({ ...worker, mail })}
                                onGetErrorMessage={v => Validate.eMail(v)}
                                disabled={disabledTextFields}
                                {...focus}/>
                        </div>
                    </div>
                </div>
                {this.props.disabledTextFields 
                    ? (
                        <DialogFooter>
                            <PrimaryButton
                                onClick={this.props.onDismiss}
                                text="Ok"/>
                        </DialogFooter>
                    )
                    : (
                        <DialogFooter>
                            <PrimaryButton
                                onClick={this._validateOnSave.bind(this)}
                                text="Zapisz"
                                disabled={this.props.hidden}/>
                            <DefaultButton
                                onMouseEnter={() => this.closeButton.focus}
                                componentRef={ref => this.closeButton = ref}
                                onClick={this.props.onDismiss}
                                text="Anuluj"/>
                        </DialogFooter>
                    )
                }
            </Dialog>
        );
    }

    private _validateOnSave() {
        this.items.surname.focus();
        if (!Validate.textFields(this.items)) { return; }
        this._saveDialog();
    }

    private _saveDialog() {
        if (this.props.edited) {
            CustomerActions.editWorker(this.props.worker)
                .then(x => {
                    this.props.onSaved(x.body[0].body);
                });
        }
        else {
            CustomerActions.addWorkers({ ...this.props.worker, customerId: this.props.customerId })
                .then(x => {
                    this.props.onSaved(x.body[0].body);
                });
        }
    }
}
