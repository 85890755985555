import * as React from "react";
import { Overlay, Spinner, SpinnerSize } from "office-ui-fabric-react";
import "./SectionSpinner.less";

interface State {
    isVisible: boolean;
    label: string;
}

interface Props {
    label?: string;
}

export default class SectionSpinner extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { isVisible: false, label: this.props.label };
    }

    render() {
        return <div>
            {this.props.children}
            {this.state.isVisible ? (
                <Overlay>
                    <Spinner
                        className="section-spinner"
                        size={SpinnerSize.large}
                        label={this.state.label}/>
                </Overlay>
            ) : null}
        </div>;
    }

    show() {
        this.setState({...this.state, isVisible: true});
    }

    hide() {
        this.setState({...this.state, isVisible: false});
    }
}
