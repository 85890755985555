import PositionReadModel from "./PositionReadModel";
import { IOfferReadContactPerson, IOfferReadCustomer, IProject } from "./interfaces";
import { OfferStatus } from "./Enums";

export default class OfferReadModel {
    id: number;
    positions: PositionReadModel[] = new Array<PositionReadModel>();
    discountInCurrency: number = 0;
    discountPercentage: number = 0;
    sum: number = 0;
    quantitySum: number = 0;
    offerNumber: string = "Nie przydzielono";
    customer?: IOfferReadCustomer;
    contactPerson?: IOfferReadContactPerson;
    project?: IProject;
    status: OfferStatus;
    currencyIso: string;
    tags: string[];
    notes?: string;
    threadId: string;
    nextContactDate: Date;
    lastContactDate: Date;
}
