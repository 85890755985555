import { Action } from "redux-actions";
import { OffersListFiltersActionTypes, OffersListFiltersState } from "./types";
import { OfferStatus } from "../../models/Enums";
import { OfferListOnlyMy } from "../../constants/StorageItems";

const initState = new OffersListFiltersState();

export const offersListFilters = (state: OffersListFiltersState = initState, action: Action<any>) => {
    switch (action.type) {
        case OffersListFiltersActionTypes.CHANGE_VISIBILITY: {
            return {
                ...state,
                areVisible: !state.areVisible
            };
        }
        case OffersListFiltersActionTypes.CLEAR: {
            return {
                ...state,
                data: {
                    ...state.data,
                    searchText: "",
                    statuses: [ OfferStatus.InEditing, OfferStatus.Sent ],
                    onlyMy: state.data.onlyMy,
                    tags: new Array<number>(),
                    nextContactDates: {}
                }
            };
        }
        case OffersListFiltersActionTypes.CHANGE_SEARCH_TEXT: {
            return {
                ...state,
                data: {
                    ...state.data,
                    searchText: action.payload
                }
            };
        }
        case OffersListFiltersActionTypes.SELECT_STATUS : {
            return {
                ...state,
                data: {
                    ...state.data,
                    statuses: [...state.data.statuses, action.payload]
                }
            };
        }
        case OffersListFiltersActionTypes.CLEAR_SELECTED_STATUSES: {
            return {
                ...state,
                data: {
                    ...state.data,
                    statuses: []
                }
            };
        }
        case OffersListFiltersActionTypes.DESELECT_STATUS : {
            return {
                ...state,
                data: {
                    ...state.data,
                    statuses: state.data.statuses.filter(s => s !== action.payload)
                }
            };
        }
        case OffersListFiltersActionTypes.SET_ONLY_MY: {
            localStorage.setItem(OfferListOnlyMy, action.payload.toString());
            return {
                ...state,
                data: {
                    ...state.data,
                    onlyMy: action.payload
                }
            };
        }
        case OffersListFiltersActionTypes.LOAD_TAGS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    avaliableTags: action.payload
                }
            };
        }
        case OffersListFiltersActionTypes.CLEAR_SELECTED_TAGS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    tags: []
                }
            };
        }
        case OffersListFiltersActionTypes.SELECT_TAG : {
            return {
                ...state,
                data: {
                    ...state.data,
                    tags: [...state.data.tags, action.payload]
                }
            };
        }
        case OffersListFiltersActionTypes.DESELECT_TAG : {
            return {
                ...state,
                data: {
                    ...state.data,
                    tags: state.data.tags.filter(t => t !== action.payload)
                }
            };
        }
        case OffersListFiltersActionTypes.SET_NEXT_CONTACT_DATE_RANGE : {
            return {
                ...state,
                data: {
                    ...state.data,
                    nextContactDates: action.payload
                }
            };
        }
        default: {
            return state;
        }
    }
};
