import * as React from "react";
import { connect, Dispatch } from "react-redux";
import { deleteKeyItem, updateKeyItem } from "../../store/keyEditor/actions";
import { keyItemDismissMenu, keyItemShowEditDialog } from "../../store/keyItemMenu/actions";
import ItemContextualMenu from "../ItemContextualMenu";
import { ConfirmDialog } from "../../../../components/dialogs";
import KeyItemDialog from "./KeyItemDialog";
import { IKeyItem } from "../../../../models/interfaces";

class KeyItemMenu extends React.Component<any, any> {
    private _confirmDialog: ConfirmDialog;

    render() {
        return (
            <div>
                <ItemContextualMenu
                    isContextMenuVisible={this.props.isContextMenuVisible}
                    onDelete={() => this._confirmDialog.show()}
                    onDismiss={this.props.onDismiss}
                    onEdit={() => this.props.onEdit()}
                    target={this.props.target}/>
                <ConfirmDialog
                    ref={(ref: any) => this._confirmDialog = ref}
                    onConfirmed={() => this.props.onDelete(this.props.item)}
                    text="Czy na pewno chcesz usunąć ten element klucza?"/>
                <KeyItemDialog/>
            </div>
        );
    }
}

function mapStateToProps(state: any, ownProps: { onConfirmed: (item: any) => any }) {
    return {
        isContextMenuVisible: state.keyItemMenuReducer.isContextMenuVisible,
        item: state.keyItemMenuReducer.item,
        target: state.keyItemMenuReducer.target
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        onDismiss: () => dispatch(keyItemDismissMenu()),
        onEdit: () => dispatch(keyItemShowEditDialog()),
        onDelete: (item: IKeyItem) => dispatch(deleteKeyItem(item)),
        onConfirmed: (item: IKeyItem) => dispatch(updateKeyItem(item))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyItemMenu);
