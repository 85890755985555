import { AuthContext } from "../auth";
import { Resources } from "../Conf";
import { NotificationStore } from "../notifications";
import ValidationStore from "../ValidationStore";
import SpinnerStore from "../SpinnerStore";
import * as FileSaver from "file-saver";

export class Request {
    static call(onCall: (token: string) => Promise<any>, resourceName: string = null): Promise<any> {
        let resource = Resources.getResource(resourceName);
        let cachedToken = AuthContext.instance.getBearerToken(resource.id);
        if (cachedToken) {
            try {
                return onCall(cachedToken);
            } catch (e) {
            }
        }

        return AuthContext.instance.acquireToken(resource.id)
            .then(token => {
                return onCall(token);
            })
            .catch(error => {
                console.error(`Error during aquire token: ${error}`);
            });
    }

    static command(name: string, sendBody: any) {
        return Request.fetchSth(`command/${name}`, sendBody)
            .then(response => {
                if (response.status === 403) {
                    return Promise.reject(403);
                }
                return response.json().then((body: any) => {
                    return { response, body };
                }).then((r: any) => {
                    if (r.response.status === 400) {
                        ValidationStore.showValidation(r.body);
                    }
                    else {
                        NotificationStore.showEventNotifications(r, name);
                    }
                    return r;
                }).catch((error: any) => {
                    if (error.message === "Unexpected end of JSON input") {
                        return response;
                    }
                    SpinnerStore.hideSpinner();
                    NotificationStore.show({ name, body: {}, ok: false });
                    return Promise.reject(name);
                });
            })
            .catch(error => {
                SpinnerStore.hideSpinner();
                let message = `Problem during call command ${name}: ${error.message}`;
                if (error === 403) {
                    message = `Unauthorized access to ${name}.`;
                    NotificationStore.addNoPermissionToCallAction();
                }
                return Promise.reject(message);
            });
    }

    static freezeCommand(name: string, body: any, message: string = "Zapisywanie") {
        SpinnerStore.showSpinner(message);
        return this.command(name, body)
            .then(x => {
                SpinnerStore.hideSpinner();
                return x;
            });
    }

    static freezeQuery<TResult>(name: string, body: any = {}): Promise<TResult> {
        SpinnerStore.showSpinner("Ładowanie");
        return this.query<TResult>(name, body)
            .then(x => {
                SpinnerStore.hideSpinner();
                return x;
            });
    }

    static query<TResult>(name: string, body: any = {}): Promise<TResult> {
        return <Promise<TResult>>Request.fetchSth(`query/${name}`, body)
            .then(response => {
                if (response.status === 403) {
                    return <Promise<TResult>>Promise.reject(403);
                }
                if (response.ok) {
                    return <TResult>response.json();
                }
            })
            .catch(error => {
                SpinnerStore.hideSpinner();
                let message = `Problem during call query ${name}: ${error.message}`;
                if (error === 403) {
                    message = `Unauthorized access to ${name}.`;
                    NotificationStore.addNoPermissionToCallAction();
                }
                return Promise.reject(message);
            });
    }

    static download(filename: string, url: string, body: any = {}, resourceName: string = null): Promise<any> {
        SpinnerStore.showSpinner("Pobieranie...");
        return <Promise<any>>this.fetchSth(url, body, resourceName, "GET")
            .then(response => {
                if (response.ok) {
                    SpinnerStore.hideSpinner();                    
                    return response.blob();
                }
            })
            .then(blob => {
                FileSaver.saveAs(blob, filename);
            })
            .catch(error => {
                SpinnerStore.hideSpinner();
                console.error(`Problem during download ${name}: ${error.message}`);
            });
    }

    static downloadQuery(filename: string, name: string, body: any = {}, resourceName: string = null): Promise<any> {
        SpinnerStore.showSpinner("Pobieranie...");
        return <Promise<any>>this.fetchSth(`query/${name}`, body, resourceName)
            .then(response => {
                if (response.ok) {
                    SpinnerStore.hideSpinner();                    
                    return response.blob();
                }
            })
            .then(blob => {
                FileSaver.saveAs(blob, filename);
            })
            .catch(error => {
                SpinnerStore.hideSpinner();
                console.error(`Problem during download ${name}: ${error.message}`);
            });
    }

    private static fetchSth(route: string, body: any, resourceName: string = null, method: string = "POST"): Promise<any> {
        return Request.call(token => {
            let resource = Resources.getResource(resourceName);
            return fetch(`${resource.url}/${route}`,
                {
                    method: method,
                    mode: "cors",
                    body: method === "GET" ? null : JSON.stringify(body),
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }).catch(error => {
                    if (!NotificationStore.isConnectError()) {
                        NotificationStore.add({
                            title: "Błąd połączenia",
                            message: "Nie ma odpowiedzi z serwera",
                            level: "error",
                            position: "tc",
                            autoDismiss: 20
                        });
                    }
                });
        }, resourceName);
    }
}
