import { combineReducers, Reducer } from "redux";
import { keyListReducer } from "../modules/admin/store/keyList/reducer";
import { offersList } from "../modules/offers/store/offersList/reducer";
import { offersListFilters } from "../modules/offers/store/offersListFilters/reducer";
import { offersListTemplates } from "../modules/offers/store/offersListTemplates/reducer";
import { offerEditor } from "../modules/offers/store/offerEditor/reducer";
import { offerDetails } from "../modules/offers/store/offerDetails/reducer";
import { keyEditorReducer } from "../modules/admin/store/keyEditor/reducer";
import { keyItemMenuReducer } from "../modules/admin/store/keyItemMenu/reducer";
import { ralColorReducer } from "../modules/admin/store/ralColors/reducer";
import { costEditorReducer } from "../modules/admin/store/costs/reducer";
import { customColorReducer } from "../modules/admin/store/customColorsDialog/reducer";
import { tagsListReducer } from "../modules/admin/store/tags/reducer";
import { projectsList } from "../modules/project/store/list/reducer";

const rootReducer: Reducer<any> = combineReducers({
    keyListReducer,
    offersList,
    offersListFilters,
    offersListTemplates,
    keyEditorReducer,
    keyItemMenuReducer,
    ralColorReducer,
    costEditorReducer,
    offerEditor,
    offerDetails,
    customColorReducer,
    tagsListReducer,
    projectsList
});

export default rootReducer;
