import * as React from "react";
import { connect, Dispatch } from "react-redux";
import { productTypeDismissMenu, updateProductType, deleteProductType } from "../../../store/keyEditor/actions";
import ItemContextualMenu from "../../ItemContextualMenu";
import ProductTypeDialog from "./ProductTypeDialog";
import { ConfirmDialog } from "../../../../../components/dialogs";
import { IProductType } from "../../../../../models/interfaces";

class ProductTypeMenu extends React.Component<any, any> {
    private _editDialog: ProductTypeDialog;
    private _confirmDialog: ConfirmDialog;

    public render() {
        return (
            <div>
                <ItemContextualMenu
                    isContextMenuVisible={this.props.isContextMenuVisible}
                    onDelete={() => this._confirmDialog.show()}
                    onDismiss={this.props.onDismiss}
                    onEdit={() => this._editDialog.show(this.props.item)}
                    target={this.props.target}/>
                <ConfirmDialog
                    ref={ref => this._confirmDialog = ref}
                    onConfirmed={this.props.onDelete}
                    text="Czy na pewno chcesz usunąć ten typ produktu?"/>
                <ProductTypeDialog
                    ref={ref => this._editDialog = ref}
                    onConfirmed={(item: IProductType) => this.props.onEdit(item)}
                    text="Typ produktu"/>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        isContextMenuVisible: state.keyEditorReducer.productTypeMenu.isContextMenuVisible,
        item: state.keyEditorReducer.productTypeMenu.item,
        target: state.keyEditorReducer.productTypeMenu.target
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        onDismiss: () => dispatch(productTypeDismissMenu()),
        onEdit: (item: IProductType) => dispatch(updateProductType(item)),
        onDelete: () => dispatch(deleteProductType()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTypeMenu);
