import { IPoint } from "office-ui-fabric-react/lib/utilities/positioning";
import { IProductType, IKeyItem } from "../../../../models/interfaces";
import { CustomColorEdited } from "../customColorsDialog/types";

export const enum KeyEditorActionTypes {
    LOAD = "Admin/Key/LOAD",
    ON_CHANGE = "Admin/Key/ON_CHANGE",
    DELETE = "Admin/Key/DELETE",
    UPDATE_CUSTOM_COLORS = "Admin/Key/UPDATE_CUSTOM_COLORS",
    PRODUCT_TYPE_ADD = "Admin/Key/PRODUCT_TYPE_ADD",
    PRODUCT_TYPE_DISMISS_MENU = "Admin/Key/PRODUCT_TYPE_DISMISS_MENU",
    PRODUCT_TYPE_SHOW_MENU = "Admin/Key/PRODUCT_TYPE_SHOW_MENU",
    PRODUCT_TYPE_EDIT = "Admin/Key/PRODUCT_TYPE_EDIT",
    PRODUCT_TYPE_DELETE = "Admin/Key/PRODUCT_TYPE_DELETE",
    KEY_LOAD = "Admin/Key/KEY_LOAD",
    KEY_ON_CHANGE = "Admin/Key/KEY_ON_CHANGE",
    KEY_DELETE = "Admin/Key/KEY_DELETE",
    KEYS_LOAD = "Admin/Key/KEYS_LOAD",
    KEY_UPDATE_CUSTOM_COLORS = "Admin/Key/UPDATE_CUSTOM_COLORS",
    KEY_ITEM_EDIT = "Admin/Key/KEY_ITEM_EDIT",
    KEY_ITEM_ADD = "Admin/Key/KEY_ITEM_ADD",
    KEY_ITEM_DELETE = "Admin/Key/KEY_ITEM_DELETE",
    KEY_ITEM_MOVE = "Admin/Key/KEY_ITEM_MOVE"
}

export class ProductKey {
    id: number = null;
    name: string = undefined;
    shortName: string = null;
    rowVersion: any; 
    customColors: CustomColorEdited[] = [];
    productTypes: IProductType[] = [];
    items: IKeyItem[] = [];
}

class ProductTypeMenu {
    target: IPoint | HTMLElement = { x: undefined, y: undefined };
    item: IProductType = undefined;
    isContextMenuVisible: boolean = false;
}

export class KeyEditorState {
    productKey: ProductKey = new ProductKey();
    productTypeMenu: ProductTypeMenu = new ProductTypeMenu();
}
