import * as React from "react";
import { Overlay, Spinner, SpinnerSize } from "office-ui-fabric-react";
import "./GlobalSpinner.less";

export default class GlobalSpinner extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { isVisible: false, label: null };
    }

    render() {
        return this.state.isVisible ? (
            <Overlay className="center-container">
                <Spinner
                    size={SpinnerSize.large}
                    label={this.state.label}/>
            </Overlay>
        ) : null;
    }
}
