import {
    OfferDuplicated,
    OfferCreated,
    OfferStatusChanged,
    OfferSaved,
    OfferDeleted,
    OfferTagsUpdated
} from "../../models/events/OfferEventModels";
import {
    RalColorCreated,
    RalColorDeleted,
    RalColorMarkedAsDefault,
    RalColorUpdated
} from "../../models/events/RalEventModels";
import {
    ProductKeyChanged,
    ProductKeyCreated,
    ProductKeyDeleted,
    ProductKeyPricesUpdated,
    ProductKeyUpdated
} from "../../models/events/ProdcutKeyEventModels";
import {
    CustomerCreated,
    CustomerUpdated,
    CustomerWorkerCreated,
    CustomerWorkerUpdated,
    ProjectCreated,
    ProjectUpdated
} from "../../models/events/CustomerEventModels";
import { TagDeleted, TagUpdated } from "../../models/events/TagEventModels";
import { DefaultEvent } from "../../models/events/DefaultEventModels";
import { IEvent } from "../../models/NotificationModels";

export class EventHandlerFactory {
    constructor() {
        this.init();
    }

    init() {// fix way to run the event handler and refactor names
        (global as any).OfferDuplicated = OfferDuplicated;
        (global as any).OfferCreated = OfferCreated;
        (global as any).OfferStatusChanged = OfferStatusChanged;
        (global as any).OfferSaved = OfferSaved;
        (global as any).OfferDeleted = OfferDeleted;
        (global as any).OfferTagsUpdated = OfferTagsUpdated;

        (global as any).RalColorCreated = RalColorCreated;
        (global as any).RalColorDeleted = RalColorDeleted;
        (global as any).RalColorMarkedAsDefault = RalColorMarkedAsDefault;
        (global as any).RalColorUpdated = RalColorUpdated;

        (global as any).ProductKeyChanged = ProductKeyChanged;
        (global as any).ProductKeyCreated = ProductKeyCreated;
        (global as any).ProductKeyDeleted = ProductKeyDeleted;
        (global as any).ProductKeyPricesUpdated = ProductKeyPricesUpdated;
        (global as any).ProductKeyUpdated = ProductKeyUpdated;

        (global as any).CustomerCreated = CustomerCreated;
        (global as any).CustomerUpdated = CustomerUpdated;
        (global as any).CustomerWorkerCreated = CustomerWorkerCreated;
        (global as any).CustomerWorkerUpdated = CustomerWorkerUpdated;
        (global as any).ProjectCreated = ProjectCreated;
        (global as any).ProjectUpdated = ProjectUpdated;

        (global as any).TagDeleted = TagDeleted;
        (global as any).TagUpdated = TagUpdated;
    }

    public getEventHandler(event: IEvent) {
        let ev;
        try {
            ev = new (global as any)[event.name](event);
        } catch (error) {
            ev = new DefaultEvent(event);
        }
        return ev;
    }
}
