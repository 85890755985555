export interface IResource {
    url: string;
    id: string;
}

export interface IResources {
    [index: string]: IResource;
}

export interface IConf {
    AuthTenant: string;
    AuthClientId: string;
    Resources: IResources;
    DefaultResourceName: string;
    ThemeName: string;
}

let Conf: IConf;

if (process.env.NODE_ENV === "production") {
    Conf = {
        AuthTenant: "#{Auth_Tenant}#",
        AuthClientId: "#{Auth_ClientId}#",
        DefaultResourceName: "api",
        Resources: {
            api: {
                id: "#{Api_ResourceId}#",
                url: "#{Api_Url}#"
            }
        },
        ThemeName: "#{ThemeName}#"
    };
}
else {
    Conf = {
        AuthTenant: "klimaoprema.pl",
        AuthClientId: "11298d24-ba9c-4498-9640-bc746837be53",
        DefaultResourceName: "api",
        Resources: {
            api: {
                id: "https://dev.klimaoprema.pl/offers/api",
                url: "https://localhost:55443"
            }
        },
        ThemeName: "airidea"
    };
}

export class Resources {
    static getResource(resourceName: string) : IResource {
        if (resourceName === null || resourceName === undefined) {
            resourceName = Conf.DefaultResourceName;
        }
        return Conf.Resources[resourceName];
    }
}

export default Conf;
