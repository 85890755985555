import * as React from "react";
import { Label } from "office-ui-fabric-react/lib/Label";
import CustomerSelect  from "../details/CustomerSelect";
import WorkerSelect  from "../details/WorkerSelect";
import ProjectSelect  from "../details/ProjectSelect";
import OfferModel from "../../models/OfferModel";
import { connect } from "react-redux";
import { onTagAdd, onTagRemove, onChangeContactPerson, onChangeProject, onChangeCustomer } from "../../store/offerEditor/actions";
import { WithContext as ReactTags } from "react-tag-input";

interface Props {
    offer: OfferModel;
    tagsSuggestions: string[];
    onAddTag: (tag: string) => void;
    onRemoveTag: (tagIndex: number) => void;
    onChangeContactPerson: (contactPersonId: number) => void;
    onChangeProject: (contactProjectId: number) => void;
    onChangeCustomer: (customerId: number) => void;
}

class OffersDetails extends React.Component<Props, any> {    
    render() {
        let tags = this.props.offer.tags.map(t => {return {id: t, text: t}; });
        let suggestions = this.props.tagsSuggestions.map(t => {return {id: t, text: t}; });
        return (
            <div className="ms-Grid">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-u-sm6 ms-u-md4 ms-lg3">
                        <Label
                            title={"Numer oferty zostanie nadany podczas pierwszego zapisu".ifEmpty(this.props.offer.offerNumber)}>
                            Numer <u>{"Nie przydzielono".ifEmpty(this.props.offer.offerNumber)}</u>
                        </Label>
                    </div>
                    <div className="ms-Grid-col ms-u-sm6 ms-u-md4 ms-u-lg6">
                        <ReactTags tags={tags}
                            handleAddition={tag => this.props.onAddTag(tag.text)}
                            handleDelete={this.props.onRemoveTag}
                            placeholder="Dodaj tag"
                            autofocus={false}
                            suggestions={suggestions}
                            allowDragDrop={false}/>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-xxl4 ms-lg3">
                        <Label>Klient</Label>
                        <CustomerSelect
                            onChange={this.props.onChangeCustomer}
                            customerId={this.props.offer.customerId}/>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-lg3">
                        <Label>Osoba kontaktowa</Label>
                        <WorkerSelect
                            onChangeCustomerWorker={this.props.onChangeContactPerson} 
                            workerId={this.props.offer.contactPersonId}
                            customerId={this.props.offer.customerId}/>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-lg3">
                        <Label>Projekt</Label>
                        <ProjectSelect
                            onChangeProject={this.props.onChangeProject} 
                            projectId={this.props.offer.projectId}
                            customerId={this.props.offer.customerId}/>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        offer: state.offerEditor.offer,
        tagsSuggestions: state.offerEditor.tagsSuggestions
    };
}

function mapDispatchToProps(dispatch: any, ownProps: any) {
    return {
        onChangeContactPerson: (contactPersonId: number) => dispatch(onChangeContactPerson(contactPersonId)),
        onChangeProject: (projectId: number) => dispatch(onChangeProject(projectId)),
        onChangeCustomer: (customerId: number) => dispatch(onChangeCustomer(customerId)),
        onAddTag: (tag: string) => dispatch(onTagAdd(tag)),
        onRemoveTag: (tagIndex: number) => dispatch(onTagRemove(tagIndex))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OffersDetails);
