interface IRalColor {
    id: number;
    value: number;
    other: string;
    costFactor: number;
    isDefault: boolean;
    modifiedDate: Date;
}

export default class RalColor implements IRalColor {
    constructor(
        public id: number = undefined,
        public value: number = undefined,
        public other: string = undefined,
        public costFactor: number = undefined,
        public modifiedDate: Date = undefined,
        public isDefault: boolean = undefined,
        public rowVersion: number = undefined
    ) {
    }

    toLabel() {
        let label = `RAL ${this.value}`;
        if (!this.value || Number(this.value) > 9999) {
            label = this.other;
        }
        return label;
    }

    static Convert(ralColors: IRalColor[]) : RalColor[] {
        return ralColors.map(x => new RalColor(x.id, x.value, x.other, x.costFactor, x.modifiedDate, x.isDefault));
    }
}
