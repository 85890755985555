export const enum ProjectsListActionTypes {
    LOAD_ITEMS = "@@Project/LOAD_ITEMS",
    IS_LOADING = "@@Project/IS_LOADING",
}

export class ProjectsListState {
    allItems: [] = [];
    Count: number = 0;
    items: [] = [];
    isLoaded: boolean = false;
    isFull: boolean = false;
}
