import * as React from "react";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { Position } from "./Position";
import OfferReadModel from "../../models/OfferReadModel";
import DisplayHelper from "../../../../helpers/DisplayHelper";
import "../../../../styles/tables.less";

interface Props {
    model: OfferReadModel;
}

export class Offer extends React.Component<Props, any> {
    render() {
        let { discountInCurrency, discountPercentage, quantitySum, sum, positions } = this.props.model;

        if (positions.length === 0) {
            return <MessageBar className="positions-empty">Oferta nie zawiera pozycji. Dodaj nową.</MessageBar>;
        }
        let no = 0;
        let positionViews = positions.map(position => {
            no++;
            return (
                <Position
                    key={`position-${no}`}
                    model={position}
                    no={no}/>
            );
        });

        let currencyIso = this.props.model.currencyIso;

        return (
            <div className="positions">
                <table className="table border bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th className="min-width400">Pozycja</th>
                            <th>Uwagi</th>
                            <th>Liczba [szt]</th>
                            <th>Cena netto [{currencyIso}]</th>
                            <th>Rabat [%]</th>
                            <th>Cena netto po rabacie [{currencyIso}]</th>
                            <th>Wartość netto [{currencyIso}]</th>
                        </tr>
                    </thead>
                    <tbody>
                        {positionViews}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>Suma:</td>
                            <td>
                                {DisplayHelper.fixed(quantitySum)}
                            </td>
                            <td></td>
                            <td></td>
                            <td>Wartość rabatu [%]:</td>
                            <td>
                                {DisplayHelper.fixed(discountPercentage)}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Wartość rabatu [{currencyIso}]:</td>
                            <td>
                                {DisplayHelper.fixed(discountInCurrency)}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Suma [{currencyIso}]:</td>
                            <td>
                                {DisplayHelper.fixed(sum)}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
}
