import { Request } from "../../../tools/requests";
import { IProductKey, IKeyItemValue } from "../../../models/interfaces";
import { KeyCost } from "../store/costs/types";

export default class ProductKeyActions {
    static getProductKey(id: number) {
        return Request.freezeQuery<IProductKey>("ReadKey", { id });
    }

    static getKeyWithCosts(id: number) {
        return Request.freezeQuery<IProductKey>("ReadKeyCosts", { id });
    }

    static create(key: IProductKey) {
        key = {
            ...key,
            productTypes: key.productTypes.map(x => {
                if (x.id < 0) { return { ...x, id: 0 }; }
            }),
            items: key.items.map(x => {
                x.availableValues.map(i => {
                    if (i.id < 0) { return { ...i, id: 0 }; }
                });
                if (x.id < 0) { return { ...x, id: 0 }; }
            })
        };
        return Request.freezeCommand("CreateProductKey", key);
    }

    static update(key: IProductKey) {
        key = {
            ...key,
            productTypes: key.productTypes.map(x => {
                if (x.id < 0) { return { ...x, id: 0 }; }
                return x;
            }),
            items: key.items.map(x => {
                if (x.id < 0) { return { ...x, id: 0, keyId: key.id }; }
                return { ...x, keyId: key.id };
            })
        };
        return Request.freezeCommand("UpdateProductKey", key);
    }

    static updateCosts(key: KeyCost) {
        let { id, productTypes } = key;
        return Request.freezeCommand("UpdateCosts", { id, productTypes });
    }

    static loadKeyItemValues(keyItemId: number) {
        return Request.query<IKeyItemValue[]>("ReadKeyItemValues", { keyItemId });
    }
}
