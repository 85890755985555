import * as React from "react";
import { RouteComponentProps } from "react-router";
import { FormBottomPanel } from "../../../components";
import Offer from "../components/edit/Offer";
import CommandBar from "../components/edit/CommandBar";
import { OfferActions } from "../actions";
import OfferModel from "../models/OfferModel";
import NavigationHelper from "../helpers/NavigationHelper";
import Validate from "../helpers/Validate";
import ValidateDialog from "../components/ValidateDialog";
import { connect, Dispatch } from "react-redux";
import { onLoadOffer, onTagsSuggestionsLoad, onSetNumber, onSetVersion, onChangeCommentsVisibility, onChangeInitialComment, onChangeContactInfoVisibility } from "../store/offerEditor/actions";
import { IKeyMetadata, OfferContactInfo } from "../models/interfaces";
import PositionModel from "../models/PositionModel";
import { SessionScope } from "../../../tools/requests";
import { parse } from "query-string";
import { autobind } from "@uifabric/utilities";
import { Shortcut } from "@shopify/react-shortcuts";
import { Panel, PanelType } from "office-ui-fabric-react";
import { CommentsThread } from "../components/comments";
import ContactInfo from "../components/contact-info/ContactInfo";

interface Props {
    model: OfferModel;
    location: any;
    commentsAreVisible: boolean;
    contactInfoIsVisible: boolean;
    initialComment: string;
    onLoad: (offer: OfferModel) => void;
    onTagsSuggestionsLoad: (tags: string[]) => void;
    addPosition: () => void;
    deletePosition: (position: PositionModel) => void;
    copyPosition: (position: PositionModel) => void;
    addCustomPosition: (key: IKeyMetadata) => void;
    addDuplicate: (position: PositionModel, index: number) => void;
    onSetOfferNumber: (offerNumber: string) => void;
    onSetVersion: (version: any) => void;
    onChangeCommentsVisibility: (visibility: boolean) => void;
    onChangeContactInfoVisibility: (isVisible: boolean) => void;
    onChangeInitialComment: (comment: string) => void;
}

interface State {
    isOverlayVisible: boolean;
}

class Editor extends React.Component<Props & RouteComponentProps<any>, State> {
    private offer: Offer;
    private model: OfferModel;
    private validateDialog: ValidateDialog;

    componentWillMount() {
        SessionScope.newScope("offers.editor");
        OfferActions.getAllTags().then(tags => this.props.onTagsSuggestionsLoad(tags.map(t => t.name)));
        if (this.props.match.params.offerId === undefined) {
            let values = parse(this.props.location.search);
            let templateId = parseInt(values.template as string, 0);
            OfferActions.newOffer(templateId)
                .then(o => {
                    this.model = o;
                    if (this.model.positions.length === 0) {
                        this.model.addPosition(null, false);
                    }
                    this.props.onLoad(this.model);
                });
            return;
        }

        let offerId = Number(this.props.match.params.offerId);
        OfferActions.getOffer(offerId)
            .then(o => {
                this.model = o;
                this.model.recalculateSum();
                this.props.onLoad(o);
            });
    }

    render() {
        let threadId: string;
        if (this.model) {
            threadId = this.model.commentsThreadId;
        }
        return (
            <div>
                <div className="fixed-command-bar">
                    <CommandBar
                        addIsDisabled={this.model !== undefined && this.model.currencyIso !== "PLN"}
                        onAddPosition={this.addPosition}
                        onAddCustomPosition={this.addCustomPosition}
                        onShowComments={this.showComments}
                        onShowContactInfo={this.showContactInfo}
                        onSave={() => this.save(false)}
                        threadId={threadId}/>
                </div>
                {this.model !== undefined && (
                    <div className="body-container">
                        <Offer ref={ref => this.offer = ref}
                            model={this.model}
                            onChange={() => { }}/>
                        <FormBottomPanel
                            onCancel={() => NavigationHelper.goToOfferList()}
                            onSave={this.save}/>
                        <Panel isOpen={this.props.commentsAreVisible} 
                            onDismiss={this.hideComments}
                            type={PanelType.medium}
                            headerText="Komentarze">
                            {this.model.commentsThreadId !== null && (
                                <CommentsThread threadId={this.model.commentsThreadId}/>
                            )}
                            {this.model.commentsThreadId === null && (
                                <CommentsThread onInput={this._onChangeInitialComment} onSave={this.hideComments}/>
                            )}
                        </Panel>
                        <Panel isOpen={this.props.contactInfoIsVisible}
                            type={PanelType.smallFixedFar}
                            headerText="Informacje o kontakcie"
                            onDismiss={this.hideContactInfo}>
                            <ContactInfo
                                contactInfo={this.model}
                                onSave={this._onSaveContactInfo}/>
                        </Panel>
                    </div>
                )}
                <Shortcut
                    held={["Alt"]}
                    ordered={["s"]}
                    onMatch={() => this.save(false)}
                    ignoreInput={true}/>
                <Shortcut
                    ordered={["Escape"]}
                    onMatch={() => NavigationHelper.goToOfferList()}
                    ignoreInput={true}/>
                <Shortcut
                    ordered={["/", "n"]}
                    onMatch={() => this.addPosition()}
                    ignoreInput={true}/>
                <Shortcut
                    ordered={["/", "m"]}
                    onMatch={() => this.addCustomPosition()}
                    ignoreInput={true}/>
                <Shortcut
                    ordered={["/", "c"]}
                    onMatch={() => this.showComments()}
                    ignoreInput={true}/>
                <Shortcut
                    ordered={["/", "i"]}
                    onMatch={() => this.showContactInfo()}
                    ignoreInput={true}/>
                <ValidateDialog
                    ref={(ref: ValidateDialog) => this.validateDialog = ref}/>
            </div>
        );
    }

    @autobind
    private save(backToOfferList: boolean = true) {
        let { customerId, contactPersonId, projectId, tags, rowVersion } = this.props.model;
        let model = {...this.offer.model, customerId, contactPersonId, projectId, tags, rowVersion } as OfferModel; // TODO temporary solution, this props are set by redux, we need migrate rest of the editor
        
        let offerId = this.props.match.params.offerId;
        if (Validate.offer(model)) {
            if (offerId === undefined) {
                OfferActions.createOffer(model, this.props.initialComment)
                    .then(request => this._onSaveEnd(request, backToOfferList));
            }
            else {
                OfferActions.updateOffer(model, offerId)
                    .then(request => this._onSaveEnd(request, backToOfferList));
            }
        }
        else {
            this.validateDialog
                .setState({
                    ...Validate.offerModel(model),
                    showDialog: true
                });
        }
    }

    @autobind
    addPosition() {
        this.offer.addPosition();
    }

    @autobind
    addCustomPosition() {
        this.offer.addCustomPosition();
    }

    @autobind
    showComments() {
        this.props.onChangeCommentsVisibility(true);
    }

    @autobind
    hideComments() {
        this.props.onChangeCommentsVisibility(false);
    }

    @autobind
    showContactInfo() {
        this.props.onChangeContactInfoVisibility(true);
    }

    @autobind
    hideContactInfo() {
        this.props.onChangeContactInfoVisibility(false);
    }

    private _onSaveEnd(request: any, backToOfferList: boolean = true) {
        if (request.response.ok && backToOfferList) {
            NavigationHelper.goToOfferList();
            return;
        }
        let event = request.body[0].body;
        this.props.onSetOfferNumber(event.number);
        this.props.onSetVersion(event.rowVersion);
        NavigationHelper.goToOfferEditor(event.id);
    }

    @autobind
    private _onChangeInitialComment(comment: string) {
        this.props.onChangeInitialComment(comment);
    }

    @autobind
    private _onSaveContactInfo(contactInfo: OfferContactInfo) {
        this.model.lastContactDate = contactInfo.lastContactDate;
        this.model.nextContactDate = contactInfo.nextContactDate;
        this.hideContactInfo();
    }
}

function mapStateToProps(state: any) {
    return {
        model: state.offerEditor.offer,
        commentsAreVisible: state.offerEditor.commentsAreVisible,
        contactInfoIsVisible: state.offerEditor.contactInfoIsVisible,
        initialComment: state.offerEditor.initialComment
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        onLoad: (offer: OfferModel) => dispatch(onLoadOffer(offer)),
        onTagsSuggestionsLoad: (tags: string[]) => dispatch(onTagsSuggestionsLoad(tags)),
        onSetOfferNumber: (offerNumber: string) => dispatch(onSetNumber(offerNumber)),
        onSetVersion: (version: any) => dispatch(onSetVersion(version)),
        onChangeCommentsVisibility: (visibility: boolean) => dispatch(onChangeCommentsVisibility(visibility)),
        onChangeContactInfoVisibility: (isVisible: boolean) => dispatch(onChangeContactInfoVisibility(isVisible)),
        onChangeInitialComment: (comment: string) => dispatch(onChangeInitialComment(comment))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
