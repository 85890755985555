export default class NavigationHelper {
    static goToAddKey() {
        location.href = "/#/admin/key/add";
    }

    static goToKeysList() {
        location.href = "/#/admin/keys";
    }

    static goToRalsList() {
        location.href = "/#/admin/rals";
    }

    static goToEditKey(keyId: number) {
        location.href = `/#/admin/key/${keyId}/edit`;
    }

    static goToEditCosts(keyId: number) {
        location.href = `/#/admin/key/${keyId}/costs/edit`;
    }
}
