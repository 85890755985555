export default class ArrayHelper {
    static getArrayOrEmptyIfUndefined<T>(array: T[]) {
        if (array === undefined) {
            return [];
        }
        return array;
    }
    
    static getArrayOrEmptyIfUndefined2<T, TT>(array: T[] | TT[]) {
        if (array === undefined) {
            return [];
        }
        return array;
    }
}
