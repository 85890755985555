import * as React from "react";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";
import "./FormBottomPanel.less";

export enum ButtonsAlgin {
    Left = 0,
    Right
}

interface IFormBottomPanelProps {
    onCancel: () => void;
    onSave: () => void;
    align?: ButtonsAlgin;
}

export class FormBottomPanel extends React.Component<IFormBottomPanelProps, any> {
    render() {
        let className = "panel-margin";
        if (this.props.align === ButtonsAlgin.Right) {
            className += " panel-margin-right";
        }
        return (
            <div className={className}>
                <PrimaryButton text="Zapisz" onClick={this.props.onSave}/>
                <span></span>
                <DefaultButton text="Anuluj" onClick={this.props.onCancel}/>
            </div>
        );
    }
}
