import { Types } from "./types";
import { createAction } from "redux-actions";
import OfferModel from "../../models/OfferModel";
import { create } from "domain";

export const onLoadOffer = createAction(
    Types.ON_LOAD,
    (offer: OfferModel) => offer
);

export const onTagsSuggestionsLoad = createAction(
    Types.TAGS_SUGGESTIONS_ON_LOAD,
    (tags: string[]) => tags 
);

export const onTagAdd = createAction(
    Types.ADD_TAG,
    (tag: string) => tag
);

export const onTagRemove = createAction(
    Types.REMOVE_TAG,
    (tagIndex: number) => tagIndex
);

export const onSetNumber = createAction(
    Types.SET_NUMBER,
    (offerNumber: string) => offerNumber
);

export const onSetVersion = createAction(
    Types.SET_VERSION,
    (version: string) => version
);

export const onChangeCustomer = createAction(
    Types.CHANGE_CUSTOMER,
    (customerId: number) => customerId
);

export const onChangeProject = createAction(
    Types.CHANGE_PROJECT,
    (projectId: number) => projectId
);

export const onChangeContactPerson = createAction(
    Types.CHANGE_CONTACT_PERSON,
    (contactPersonId: number) => contactPersonId
);

export const onChangeCommentsVisibility = createAction(
    Types.CHANGE_COMMENTS_VISIBILITY,
    (visibility: boolean) => visibility
);

export const onChangeInitialComment = createAction(
    Types.CHANGE_INITIAL_COMMENT,
    (comment: string) => comment
);

export const setCommentsCount = createAction(
    Types.SET_COMMENTS_COUNT,
    (commentsCount: number) => commentsCount
);

export const onChangeContactInfoVisibility = createAction(
    Types.CHANGE_CONTACT_INFO_VISIBILITY,
    (isVisible: boolean) => isVisible
);
