import { KeyItemMenuActionTypes, KeyItemMenuState, KeyItem, KeyItemValue } from "./types";
import { Action } from "redux-actions";
import ArrayExtensions from "../../helpers/ArrayExtensions";

const initState = new KeyItemMenuState();

export const keyItemMenuReducer = (state: KeyItemMenuState = initState, action: Action<any>) => {
    switch (action.type) {
        case KeyItemMenuActionTypes.ON_CHANGE: {
            return { ...state, item: action.payload };
        }
        case KeyItemMenuActionTypes.SHOW_ADD_DIALOG: {
            return { ...state, showDialog: true, isEditing: false, item: new KeyItem() };
        }
        case KeyItemMenuActionTypes.SHOW_EDIT_DIALOG: {
            return { ...state, showDialog: true, isEditing: true };
        }
        case KeyItemMenuActionTypes.DISMISS_DIALOG: {
            return { ...state, showDialog: false };
        }
        case KeyItemMenuActionTypes.VALUE_ADD: {
            let availableValues = state.item.availableValues.concat(new KeyItemValue());
            return {
                ...state,
                item: {
                    ...state.item,
                    availableValues
                }
            };
        }
        case KeyItemMenuActionTypes.VALUE_DELETE: {
            let availableValues = state.item.availableValues;
            availableValues = ArrayExtensions.deleteItem(availableValues, action.payload);
            return {
                ...state,
                item: {
                    ...state.item,
                    availableValues
                }
            };
        }
        case KeyItemMenuActionTypes.SHOW_MENU: {
            return {
                ...state,
                isContextMenuVisible: true,
                target: action.payload.target,
                item: action.payload.item,
            };
        }
        case KeyItemMenuActionTypes.DISMISS_MENU: {
            return {
                ...state,
                isContextMenuVisible: false
            };
        }
        default: {
            return state;
        }
    }
};
