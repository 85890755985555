import * as React from "react";
import * as ReactDOM from "react-dom";
import { createHashHistory } from "history";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { routerMiddleware } from "react-router-redux";
import { initializeIcons } from "@uifabric/icons";
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import App from "./containers/App";
import rootReducers from "./reducers";
import ReduxThunk from "redux-thunk";
import "office-ui-fabric-react/dist/css/fabric.min.css";
import { Theme } from "./tools/themes";
import Conf from "./tools/Conf";
import {ShortcutProvider} from "@shopify/react-shortcuts";
import ribbonButtonRenderer from "./components/editor/ribbonButtonRenderer";

initializeIcons();
Theme.initialize(Conf.ThemeName);

const history = createHashHistory();
const middleware = routerMiddleware(history);
const store = createStore(rootReducers, applyMiddleware(ReduxThunk));
let routerId: number = 0;
const rend = ribbonButtonRenderer;

const renderApp = () => {
    let result =
        <Fabric>
            <Provider store={store}>
                <ShortcutProvider>
                    <App history={history} routerId={routerId++}/>
                </ShortcutProvider>
            </Provider>
        </Fabric>;
    return result;
};

ReactDOM.render(
    renderApp(),
    document.getElementById("container")
);
