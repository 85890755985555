export default class PositionReadModel {
    public id: number = undefined;
    public name: string = "";
    public discountPercentage: number = 0;
    public discountInCurrency: number = 0;
    public quantity: number = 0;
    public netUnitPrice: number = 0;
    public netUnitPriceAfterDiscount: number = 0;
    public netValue: number = 0;
    public remarks?: string;
}
