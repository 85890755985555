import { AffectsPriceType } from "../../../../models/Enums";
import CostTableBuilder from "../../helpers/CostTableBuilder";

export const enum CostsActionTypes {
    LOAD = "Admin/Costs/LOAD",
    CHANGE_PRODUCT_TYPE = "Admin/Costs/CHANGE_PRODUCT_TYPE",
    CHANGE_COST = "Admin/Costs/CHANGE_COST",
    CHANGE_KEY = "Admin/Costs/CHANGE_KEY"
}

export class CostGridCell {
    readOnly: boolean = false;
    value: string | number;
    costOptionId?: number;
    keyItemValueId?: number;
    colSpan?: number;
    baseValueId?: number;
    itemId?: number;
    className?: string;
    component?: any;
    forceComponent?: any;
    tooltip?: boolean = false;
    tooltipText?: string;
}

export class CostEditorState {
    costs: CostGridCell[][] = [];
    key: KeyCost = new KeyCost();
    builder: CostTableBuilder;
    currentProductTypeId: number = 0;
}

export class KeyCost {
    id: number;
    name: string;
    productTypes: ProductTypeCost[] = [];
    items: KeyItemCost[];
}

export class ProductTypeCost {
    id: number;
    name: string;
    symbol: string;
    productTypes: ProductTypeCost[];
    costBases: CostBase[];
}

export class KeyItemCost {
    id: number;
    name: string = "";
    order: number;
    affectsPrice: AffectsPriceType;
    availableValues?: KeyItemValueCost[] = [];
}

export class KeyItemValueCost {
    id?: number;
    name?: string;
    order: number;
    value: string | number;
    isAlwaysPresent: boolean;
}

export class CostOptionVariant {
    id: number;
    baseId: number;
    valueId: number;
    cost: number;
}

export class CostVariant {
    keyItemId: number;
    keyItem: KeyItemCost;
    optionVariants: CostOptionVariant[];
}

export class CostBase {
    costVariants: CostVariant[];
    baseKeyItemValue: KeyItemValueCost;
    productTypeId: number;
    baseKeyItemValueId: number;
}
