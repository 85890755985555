import * as React from "react";
import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import KeysList from "../../components/keys/KeysList";
import { Dispatch, connect } from "react-redux";
import { loadKey } from "../../store/keyEditor/actions";
import { ProductKey } from "../../store/keyEditor/types";
import NavigationHelper from "../../helpers/NavigationHelper";
import NavNavigator from "../../components/NavNavigator";
import "../../styles/editor.less";
import { Roles, RolesHelper } from "../../../../tools/auth";

class List extends React.Component<any, any> {
    render() {
        return (
            <div>
                <div className="fixed-command-bar">
                    <CommandBar items={RolesHelper.filter(this._commandBarItems)}/>
                </div>
                <div className="body-container"  >
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                                <NavNavigator/>
                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg10">
                                <KeysList/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private _commandBarItems = [{
        name: "Dodaj",
        key: "add",
        iconProps: { iconName: "Add" },
        onClick: () => {
            this.props.onClick();
            NavigationHelper.goToAddKey();
        },
        roles: [Roles.Administrator]
    }];
}

function mapStateToProps(state: any) {
    return {};
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        onClick: () => dispatch(loadKey(new ProductKey()))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
