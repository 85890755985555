import PositionModel from "./PositionModel";
import Publisher from "./Publisher";
import MathHelper from "./../../../helpers/MathHelper";
import { CalculationActions } from "../actions";
import { PrintTemplate } from "./Enums";

export default class OfferModel extends Publisher {
    private newPositionsIndex: number = -1;
    public customerId: number;
    public contactPersonId: number;
    public projectId: number;
    public rowVersion: any;
    public currencyId: number;
    public currencyIso: string;
    public printTemplate: PrintTemplate;
    public tags: string[] = [];
    public offerNumber: string;
    public notes: string;
    public commentsThreadId: string;
    public nextContactDate: Date;
    public lastContactDate: Date;
    
    constructor(
        public positions: PositionModel[] = [],
        public discountInCurrency: number = 0,
        public discountPercentage: number = 0,
        public sum: number = 0,
        public quantitySum: number = 0,
    ) {
        super();
    }

    addPosition(position: PositionModel = null, recount: boolean = true, index: number = undefined) {
        if (position === null) {
            position = PositionModel.create();
            position.id = this.newPositionsIndex--;
        }
        else if (position.isCustom && !position.id) {
            position.id = this.newPositionsIndex--;
        }
        this.add(position, recount, index);
    }

    copyPosition(position: PositionModel) {
        let duplicate = position.clone();
        this.addDuplicate(duplicate, this.positions.indexOf(position));
    }

    addDuplicate(position: PositionModel, index: number) {
        position.id = this.newPositionsIndex--;
        position.subscribe(this.recalculateSum.bind(this));
        this.positions.splice(index + 1, 0, position);
        this.recalculateSum();
    }

    private add(position: PositionModel, recount: boolean = true, index: number = undefined) {
        position.subscribe(this.recalculateSum.bind(this));
        if (index === undefined) {
            this.positions.push(position);
        }
        else {
            this.positions.splice(index, 0, position);
        }
        if (recount) {
            this.recalculateSum();
        }
        else {
            this.inform();
        }
    }

    delete(position: PositionModel) {
        this.positions = this.positions.filter(x => x !== position);
        this.recalculateSum();
    }

    changeDiscountInCurrency(value: number) {
        this.discountInCurrency = MathHelper.correctOr0(value);
        this.discountPercentage = null;
        this.recalculateSum();
    }

    changeDiscountInPercentage(value: number) {
        this.discountPercentage = MathHelper.correctOr0(value);
        this.discountInCurrency = null;
        this.recalculateSum();
    }

    recalculateSum() {
        CalculationActions.recalculateOfferNetValue(this)
            .then(x => {
                this.sum = x.sum;
                this.quantitySum = x.quantitySum;
                this.discountInCurrency = x.discountInCurrency;
                this.discountPercentage = x.discountPercentage;
                this.inform();
            });
    }
}
