import * as React from "react";
import { IContact, IRole } from "../../../models/interfaces";
import { EntityTypesActions } from "../../../actions/EntityTypesActions";
import { ContactRow } from "./ContactRow";
import { ContactContextualMenu } from "./ContactContextualMenu";
import { IPoint } from "@uifabric/utilities";
import "../../../../../styles/tables.less";

interface Props {
    contacts: IContact[];
    disabled?: boolean;
    onChange: (contacts: IContact[]) => void;
    onDeleteContact: (item: IContact) => void;
}

interface State {
    entityTypes: IRole[];
    validating: boolean;
    errorMessages: { [name: string]: string };
}

export class ContactList extends React.Component<Props, State> {
    private _contextualMenu: ContactContextualMenu;

    constructor(props: Props) {
        super(props);
        this.state = { entityTypes: [], validating: false, errorMessages: {} };
        this._readRoles();
    }

    render() {
        let contacts = this.props.contacts;
        if (contacts ===undefined || contacts.length === 0) {
            return <div>Brak powiązanych podmiotów</div>
        }
        let contactsRendered = contacts.map(contact => {
            return (
                <tr key={contact.id}>
                    <td>
                        <ContactRow
                            contact={contact}
                            roles={this.state.entityTypes}
                            onContextMenu={this._showContextualMenu}
                            errorMessage={this.state.errorMessages[contact.id]}
                            onChange={this._handleChange}
                            disabled={this.props.disabled}/>
                    </td>
                </tr>
            );
        });
        return (
            <div>
                <table className="table border bordered">
                    <tbody>
                        {contactsRendered}
                    </tbody>
                </table>
                <ContactContextualMenu
                    ref={r => this._contextualMenu = r}
                    onDelete={this.props.onDeleteContact}/>
            </div>
        );
    }

    validate() {
        this.setState({...this.state, validating: true});
        let errorMessages : { [name: string]: string } = {};

        this.props.contacts.forEach(contact => {
            if (contact.entity == undefined && contact.role === undefined) {
                errorMessages[contact.id] = "Wybierz kontakt i rolę";
                return;
            }
            if (contact.role === undefined) {
                errorMessages[contact.id] = "Wybierz rolę";
                return;
            }
        });

        this.setState({...this.state, errorMessages })
        return Object.keys(errorMessages).length === 0;
    }

    private _readRoles() {
        EntityTypesActions.getEntityTypes()
            .then(types => {
                this.setState({...this.state, entityTypes: types});
            });
    }

    private _showContextualMenu = (item: IContact, target: IPoint | HTMLElement) => {
        let menu = this._contextualMenu;
        if (menu) {
            menu.show(item, target);
        }
    }

    private _handleChange = (contact: IContact) => {
        let contacts = this.props.contacts;
        contacts.forEach(c => {
            if (c.id === contact.id) {
                c.entity = contact.entity;
                c.role = contact.role;
            }
        })
        this.props.onChange(contacts);
    }
} 
