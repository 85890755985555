import * as React from "react";
import { DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { Label } from "office-ui-fabric-react/lib/Label";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Dialog } from "../../../../components/dialogs";
import "../../styles/editor.less";
import { Dispatch, connect } from "react-redux";
import { ralDismissDialog, ralEdit, ralOnChange, ralOnAdd } from "../../store/ralColors/actions";
import RalColorActions from "../../actions/RalColorsActions";
import RalColor from "../../../../models/RalColor";
import { NumberInput } from "../../../../components";

interface IRalColorDialogProps {
    item: any;
    showDialog: boolean;
    edited: boolean;
    onDismiss: () => void;
    onEdit: () => void;
    onAdd: (item: RalColor) => void;
    onChange: (item: RalColor) => void;
}

class RalColorDialog extends React.Component<IRalColorDialogProps, any> {
    render() {
        return (
            <Dialog
                modalProps={{ isBlocking: false }}
                hidden={!this.props.showDialog}
                dialogContentProps={{ type: DialogType.normal, className: "ms-dialogMain-text" }}
                onDismiss={this.props.onDismiss}
                title={this.props.edited ? "Edycja klucza produktu" : "Dodanie klucza produktu"}>
                <Label>Wartość</Label>
                <NumberInput
                    className="text-right"
                    dialogClass
                    onChange={(value) => this.props.onChange({ ...this.props.item, value })}
                    value={this.props.item.value}/>
                <TextField
                    label="Nazwa"
                    defaultValue={this.props.item.other}
                    onChanged={(other) => this.props.onChange({ ...this.props.item, other })}/>
                <Label>Współczynnik ceny</Label>
                <NumberInput
                    className="text-right"
                    dialogClass
                    onChange={(costFactor) => this.props.onChange({ ...this.props.item, costFactor })}
                    value={this.props.item.costFactor}/>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => this.props.edited ? this.handleOnEdit() : this.handleOnAdd()}
                        text="Zapisz"
                        disabled={!this.props.showDialog}/>
                    <DefaultButton
                        onClick={this.props.onDismiss}
                        text="Anuluj"
                        disabled={!this.props.showDialog}/>
                </DialogFooter>
            </Dialog>
        );
    }

    private handleOnEdit() {
        this.props.onDismiss();
        RalColorActions.update(this.props.item)
            .then(request => {
                if (request.response.ok) {
                    this.props.onEdit();
                }
            });
    }

    private handleOnAdd() {
        RalColorActions.create(this.props.item)
            .then(request => {
                this.props.onAdd(request.body[0].body);
            });
    }
}

function mapStateToProps(state: any) {
    return {
        item: state.ralColorReducer.menuItem,
        showDialog: state.ralColorReducer.showDialog,
        edited: state.ralColorReducer.edited
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        onEdit: () => dispatch(ralEdit()),
        onDismiss: () => dispatch(ralDismissDialog()),
        onAdd: (item: RalColor) => dispatch(ralOnAdd(item)),
        onChange: (item: RalColor) => dispatch(ralOnChange(item))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RalColorDialog);
