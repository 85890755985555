import * as React from "react";
import { IPoint } from "office-ui-fabric-react/lib/utilities/positioning";
import { autobind } from "office-ui-fabric-react/lib";
import TagsActions from "../../actions/TagsActions";
import { Tag } from "../../models/interfaces";
import { ConfirmDialog } from "../../../../components/dialogs";
import ItemContextualMenu from "../ItemContextualMenu";
import TagDialog from "../../components/tags/TagDialog";

interface MenuProps {
    onDeleted: (tag: Tag) => void;
    onUpdated: (tag: Tag) => void;
    roles?: string[];
}

interface MenuState {
    isContextMenuVisible: boolean;
    target?: IPoint | HTMLElement;
    selectedTag?: Tag;
}

export default class TagContextualMenu extends React.Component<MenuProps, MenuState> {
    private _tagDialog: TagDialog;
    private _confirmDialog: ConfirmDialog;

    constructor(props: MenuProps) {
        super(props);
        this.state = { isContextMenuVisible: false };
    }

    render() {
        return (
            <div>
                <ItemContextualMenu
                    isContextMenuVisible={this.state.isContextMenuVisible}
                    onDelete={() => this._showConfirmDialog()}
                    onEdit={() => this._tagDialog.show(this.state.selectedTag)}
                    onDismiss={() => this.dismiss()}
                    target={this.state.target}/>
                <ConfirmDialog
                    ref={ref => this._confirmDialog = ref}
                    text={"Czy na pewno chcesz usunąć tag '{0}'? Zostanie on usunięty ze wszystkich ofert."}
                    onConfirmed={this._onDelete}/>
                <TagDialog
                    ref={ref => this._tagDialog = ref}
                    onConfirmed={this._onUpdate}/>
            </div>
        );
    }

    show(tag: Tag, event: MouseEvent) {
        this.setState({...this.state, 
            isContextMenuVisible: true,
            selectedTag: tag,
            target: { x: event.clientX, y: event.clientY }
        });
    }

    @autobind
    dismiss() {
        this.setState({...this.state, isContextMenuVisible: false});
    }

    @autobind
    private _onDelete() {
        TagsActions.deleteTag(this.state.selectedTag)
            .then(request => {
                if (request.response.ok) {
                    this.props.onDeleted(this.state.selectedTag);
                }
            });
    }

    @autobind
    private _onUpdate(tag: Tag) {
        TagsActions.updateTag(tag)
            .then(request => {
                if (request.response.ok) {
                    this.props.onUpdated(tag);
                }
            });
    }

    @autobind
    private _showConfirmDialog() {
        this._confirmDialog.show(this.state.selectedTag.name);
    }
}
