import * as React from "react";
import {Filter, FilterProps, FilterState } from "./Filter";
import { autobind } from "office-ui-fabric-react";
import { CalendarInline } from "../date-input";
import "./filter.less";
import DisplayHelper from "../../helpers/DisplayHelper";
import { DateRange } from "../../modules/offers/models/interfaces";

interface Props extends FilterProps {
    dateRange: DateRange;
    onChanged?: (from: Date, to: Date) => void;
}

interface State extends FilterState {
    menuIsVisible: boolean;
    from?: Date;
    to?: Date;
}

export class DateFilter extends Filter<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            menuIsVisible: false,
            label: ""
        };
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.dateRange.from !== this.props.dateRange.from || prevProps.dateRange.to !== this.props.dateRange.to) {
            const { from, to } = this.props.dateRange;
            let selectedLabel = this.getDateString(from, to);
            this.setState({...this.state, from, to, label: selectedLabel});
        }
    }

    protected renderBody() {        
        return (
            <div className="date-range-filter"> 
                <CalendarInline
                    from={this.state.from}
                    to={this.state.to}
                    onChange={this.handleOnChange}/>
            </div>
        );
    }

    @autobind
    protected _clearSelected() {
        this.setState({...this.state, from: undefined, to: undefined, label: "" });
        if (this.props.onClear !== undefined) {
            this.props.onClear();
        }
    }

    @autobind
    protected handleOnChange(from: Date, to: Date) {
        let selectedLabel = this.getDateString(from, to);
        this.setState({...this.state, from, to, menuIsVisible: true, label: selectedLabel});
        if (this.props.onChanged) {
            this.props.onChanged(from, to);
        }
    }

    protected getWidth() {
        return 280;
    }

    protected getDateString(from: Date, to: Date) {
        if (from === undefined) {
            return undefined;
        }
        if (to === undefined) {
            return `od ${DisplayHelper.formatDate(from)}`;
        }
        return `od ${DisplayHelper.formatDate(from)} do ${DisplayHelper.formatDate(to)}`;
    }
}
