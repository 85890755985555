import * as React from "react";
import { NumberInput } from "../../../../../components";
import { IKeyItemValue, IKeyItem } from "../../../../../models/interfaces";
import "../../../styles/editor.less";
import SizeItem from "./SizeItem";
import { Label } from "office-ui-fabric-react";

interface INumberItemProps {
    item: IKeyItem;
    setupAffectPrice: any[];
    onChange: (item: IKeyItem) => any;
}

export default class NumberItem extends React.Component<INumberItemProps, any> {
    render() {
        let { item, onChange, setupAffectPrice } = this.props;
        return (
            <div>
                <SizeItem
                   onChange={onChange}
                   item={item}
                   setupAffectPrice={setupAffectPrice}/>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg4">
                        <Label>Wartość domyślna</Label>
                        <NumberInput
                            className="text-right"
                            dialogClass
                            onChange={(value) => this.changeDefaultValue(value.toString())}
                            value={item.availableValues.length > 0 ? Number(item.availableValues[0].value) : 0}/>
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md2 ms-lg4">
                        <Label>Wartość minimalna</Label>
                        <NumberInput
                            className="text-right"
                            dialogClass
                            onChange={(min) => onChange({ ...item, min })}
                            value={item.min}/>
                    </div>
                </div>
            </div>
        );
    }

    private changeDefaultValue(value: string) {
        let currentValue = this.props.item.availableValues.length === 1
            ? { ...this.props.item.availableValues[0], value }
            : { value } as IKeyItemValue;
        this.props.onChange({
            ...this.props.item,
            availableValues: [currentValue]
        });
    }
}
