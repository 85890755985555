import * as React from "react";
import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import ProductTypeDialog from "./productType/ProductTypeDialog";
import { IProductType } from "../../../../models/interfaces";
import "../../styles/editor.less";

interface IEditorCommandBarProps {
    onAddProductType: (productType: IProductType) => any;
    onAddKeyItem: () => void;
    onAssignColors: () => void;
}

export default class EditorCommandBar extends React.Component<IEditorCommandBarProps, any> {
    private _productTypeDialog: ProductTypeDialog;

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div>
                <CommandBar
                    items={[
                        {
                            name: "Dodaj typ produktu",
                            key: "add-product-type",
                            iconProps: { iconName: "Add" },
                            onClick: () => this._productTypeDialog.show()
                        },
                        {
                            name: "Dodaj element",
                            iconProps: { iconName: "Add" },
                            key: "add-element",
                            onClick: this.props.onAddKeyItem
                        },
                        {
                            name: "Przypisz kolory",
                            iconProps: { iconName: "Assign" },
                            key: "assign-colors",
                            onClick: this.props.onAssignColors
                        }
                    ]}/>
                <ProductTypeDialog
                    ref={ref => this._productTypeDialog = ref}
                    text="Typ Produktu"
                    onConfirmed={this.props.onAddProductType}/>
            </div>
        );
    }
}
