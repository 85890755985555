import * as React from "react";
import { DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { autobind } from "@uifabric/utilities";
import { Dialog } from "../../../../components/dialogs";
import { Tag } from "../../models/interfaces";

interface TagDialogProps {
    text?: string;
    onConfirmed: (tag: Tag) => any;
}

interface TagDialogState {
    hidden: boolean;
    tag: Tag;
}

export default class TagDialog extends React.Component<TagDialogProps, TagDialogState> {
    private _name: TextField;

    constructor(props: any) {
        super(props);
        this.state = {
            hidden: true,
            tag: { id: null, name: undefined }
        };
    }

    public render() {
        return (
            <Dialog
                modalProps={{ isBlocking: false, containerClassName: "dialog-min-width" }}
                hidden={this.state.hidden}
                dialogContentProps={{ type: DialogType.normal  }}
                onDismiss={this._closeDialog}
                title={this.props.text === undefined
                    ? "Edycja"
                    : this.props.text}>
                <TextField
                    label="Nazwa"
                    defaultValue={this.state.tag === undefined ? "" : this.state.tag.name}
                    required
                    ref={(ref: any) => this._name = ref}/>
                <DialogFooter>
                    <PrimaryButton
                        onClick={this._onConfirmDialog}
                        text="Zapisz"
                        disabled={this.state.hidden}/>
                    <DefaultButton
                        onClick={this._closeDialog}
                        text="Anuluj"
                        disabled={this.state.hidden}/>
                </DialogFooter>
            </Dialog>
        );
    }

    @autobind
    show(tag?: Tag) {
        this.setState({ hidden: false, tag });
    }

    @autobind
    private _onConfirmDialog() {
        let item = { id: this.state.tag.id, name: this._name.value };
        this.props.onConfirmed(item);
        this.setState({ hidden: true });
    }

    @autobind
    private _closeDialog() {
        this.setState({ hidden: true });
    }
}
