import * as React from "react";
import { Ribbon, RibbonPlugin } from "roosterjs-react-ribbon";
import { EmojiPlugin, createEditorViewState, EditorViewState } from "roosterjs-react";
import ReactEditor from "./ReactEditor";
import ribbonButtonRenderer from "./ribbonButtonRenderer";
import { autobind } from "office-ui-fabric-react";
import "./RichTextEditor.less";

interface RichTextEditorProps {
    content?: string;
    onChange: (text: string) => void;
}

export class RichTextEditor extends React.Component<RichTextEditorProps, any> {
    private _viewState: EditorViewState;
    private _ribbonPlugin: RibbonPlugin;
    //private _emojiPlugin: EmojiPlugin;

    constructor(props: RichTextEditorProps) {
        super(props);

        this._viewState = createEditorViewState(props.content);
        this._ribbonPlugin = new RibbonPlugin();
        //this._emojiPlugin = new EmojiPlugin();
        //this._emojiPlugin.initializeContentEditable()
    }

    ribbonButtons = [
        // "emoji",
        "bold",
        "italic",
        "underline",
        "font",
        "size",
        "bkcolor",
        "color",
        "bullet",
        "number",
        "indent",
        "outdent",
        "quote",
        "left",
        "center",
        "right",
        "link",
        "unlink",
        "sub",
        "super",
        "strike",
        // 'alttext',
        // 'ltr',
        // 'rtl',
        // 'undo',
        // 'redo',
        "unformat"
    ];

    render() {
        return (
            <div className={"rich-text-editor"}>
                <Ribbon
                    ribbonPlugin={this._ribbonPlugin}
                    className={"comments-ribbon"}
                    buttonRenderer={ribbonButtonRenderer}
                    buttonNames={this.ribbonButtons}
                    //additionalButtons={{ emoji: { name: "btnEmoji", onClick: () => this._emojiPlugin.startEmoji() } }}
                    />
                <ReactEditor
                    viewState={this._viewState}
                    onChange={this._handleChange}
                    plugins={[this._ribbonPlugin /* , this._emojiPlugin */]} />
            </div>
        );
    }

    @autobind
    private _handleChange(message: string) {
        this.props.onChange(message);
    }
}
