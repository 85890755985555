import * as React from "react";
import { ContextualMenu as FabricContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { IPoint } from "office-ui-fabric-react/lib/utilities/positioning";
import { OfferActions, PrintActions } from "../../actions";
import { IContextualMenuItem, ContextualMenuItemType } from "office-ui-fabric-react/lib";
import NavigationHelper from "../../helpers/NavigationHelper";
import { OfferListItem } from "../../models/OfferListItem";
import { ConfirmDialog } from "../../../../components/dialogs/ConfirmDialog";
import { OfferStatus, OfferStatuses } from "../../models/Enums";
import "../../../../extensions/String.Extensions";
import OfferStatusService from "../../services/OffersStatusService";

interface State {
    target: IPoint | HTMLElement;
    item: OfferListItem;
    isVisible: boolean;
    statusToSet: OfferStatus;
    statusesAreImported: boolean;
}

interface Props {
    onCopy: (item: OfferListItem) => void;
    onStatusChange: (id: number, newStatus: OfferStatus) => void;
    onDelete: (id: number) => void;
}

export class ContextualMenu extends React.Component<Props, State> {
    private _deleteConfirmDialog: ConfirmDialog;
    private _changeStatusConfirmDialog: ConfirmDialog;
    private _offerStatusService: OfferStatusService = new OfferStatusService();

    constructor(props: Props) {
        super(props);
        this.state = {
            isVisible: false,
            target: undefined,
            item: undefined,
            statusToSet: undefined,
            statusesAreImported: false
        };
        this._onDismiss = this._onDismiss.bind(this);
    }

    componentWillMount() {
        this._offerStatusService.init(() => this.setState({...this.state, statusesAreImported: true}));
    }

    render() {
        let isSent = this.state.item !== undefined && this.state.item.status !== OfferStatus.InEditing;
        return (
            <div>
                {this.state.isVisible ? (
                    <FabricContextualMenu
                        shouldFocusOnMount
                        target={this.state.target}
                        onDismiss={this._onDismiss}
                        onItemClick={(ev, item) => this._handleOnClick(item)}
                        items={
                            [
                                {
                                    key: "view",
                                    iconProps: {
                                        iconName: "CustomList"
                                    },
                                    name: "Podgląd"
                                },
                                {
                                    key: "div-1",
                                    itemType: ContextualMenuItemType.Divider
                                },
                                {
                                    key: "edit",
                                    iconProps: {
                                        iconName: "Edit"
                                    },
                                    disabled: isSent,
                                    name: "Edytuj"
                                },
                                {
                                    key: "copy",
                                    iconProps: {
                                        iconName: "Copy"
                                    },
                                    name: "Duplikuj"
                                },
                                {
                                    key: "delete",
                                    iconProps: {
                                        iconName: "Delete"
                                    },
                                    name: "Usuń",
                                    disabled: isSent
                                },
                                {
                                    key: "div-1",
                                    itemType: ContextualMenuItemType.Divider
                                },
                                {
                                    key: "print",
                                    iconProps: {
                                        iconName: "Print"
                                    },
                                    name: "Drukuj"
                                },
                                {
                                    key: "div-3",
                                    itemType: ContextualMenuItemType.Divider
                                },
                                {
                                    key: "_setStatus",
                                    subMenuProps: this._offerStatusService.getSubMenuToSetStatus(this.state.item.status, item => this._handleOnClick(item)),
                                    disabled: !this._offerStatusService.checkIfAnyStatuesAreAvailableFor(this.state.item.status),
                                    name: "Zmień status na..."
                                }
                            ]
                        }
                    />) : (null)}
                <ConfirmDialog
                    ref={ref => this._changeStatusConfirmDialog = ref}
                    text="Czy na pewno zmienić status oferty {0} na '{1}'?"
                    onConfirmed={() => this._onChangeStatus()}/>
                <ConfirmDialog
                    ref={ref => this._deleteConfirmDialog = ref}
                    text="Czy na pewno usunąć ofertę?"
                    onConfirmed={() => this._onDelete()}/>
            </div>
        );
    }

    private _handleOnClick(item: IContextualMenuItem) {
        switch (item.key.split("-")[0]) {
            case "copy":
                OfferActions.duplicateOffer(this.state.item.id)
                    .then(request => {
                        if (request.response.ok) {
                            let body = request.body[0].body;
                            this.props.onCopy({ 
                                ...this.state.item, 
                                id: body.id, 
                                number: body.number, 
                                status: OfferStatus.InEditing, 
                                author: body.author, 
                                createdDate: body.createdDate, 
                                modifiedDate: body.createdDate
                            });
                        }
                    });
                break;

            case "delete":
                this._deleteConfirmDialog.show();
                break;

            case "print":
                PrintActions.print(this.state.item.id, this.state.item.number);
                break;

            case "edit":
                NavigationHelper.goToOfferEditor(this.state.item.id);
                break;

            case "view":
                NavigationHelper.goToOfferView(this.state.item.id);
                break;
            
            case "setStatus":
                let statusToSetStr = item.key.split("-")[1];
                let statusToSet = +statusToSetStr;
                this.setState({...this.state, statusToSet });
                this._changeStatusConfirmDialog.show(this.state.item.number, OfferStatuses[statusToSet]);
                break;
            case "_setStatus":
                break;

            default:
                throw new Error("Unsupported Command");
        }
    }

    private _onChangeStatus() {
        OfferActions.changeOfferStatus(this.state.item.id, this.state.statusToSet)
            .then(request => {
                if (request.response.ok) {
                    this.props.onStatusChange(this.state.item.id, this.state.statusToSet);
                }
            });
    }

    private _onDelete() {
        OfferActions.deleteOffer(this.state.item.id)
            .then(request => {
                if (request.ok) {
                    this.props.onDelete(this.state.item.id);
                }
            });
    }

    private _onDismiss(event: any) {
        this.setState({ isVisible: false });
    }
}
