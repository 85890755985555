import * as React from "react";
import { CommentsEditor } from "../../../../components/editor";
import { autobind } from "office-ui-fabric-react";
import Hub from "./Comments";

interface Props {
    threadId?: string;
    onInput?: (message: string) => void;
    onSave?: () => void;
}

interface State {
    text: string;
}

export class CommentsThread extends React.Component<Props, State> {
    private _hub: Hub;

    render() {
        return (
            <div>
                <CommentsEditor
                    onSave={this._onSave}
                    onChange={this._onChange}/>
                {
                    this.props.threadId !== undefined && (
                        <Hub threadId={this.props.threadId}
                            ref={m => this._hub = m}/>
                    )
                }
            </div>
        );
    }

    @autobind
    private _onChange(text: string) {
        if (this.props.onInput) {
            this.props.onInput(text);
        }
        if (this.props.threadId) {
            this.setState({text});
        }
    }

    @autobind
    private _onSave() {
        if (this.props.threadId) {
            this._hub.createComment(this.state.text);
        }
        if (this.props.onSave) {
            this.props.onSave();
        }
    }
}
