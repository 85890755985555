import * as React from "react";
import { Dialog, DialogFooter, PrimaryButton, DefaultButton } from "office-ui-fabric-react";

export class NotificationDialog extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { hidden: true };
    }

    render() {
        let { title, hidden, action, buttonTitle } = this.state;
        return (
            <div>
                <Dialog
                    modalProps={{className: "text size-dialog", isBlocking: false}}
                    hidden={hidden}
                    onDismiss={this._closeDialog.bind(this)}
                    title={title}> 
                    <DialogFooter>
                        <PrimaryButton 
                            style={{float: "left"}}
                            onClick={() => this._onConfirmDialog(action)}
                            text={buttonTitle}
                            disabled={hidden}/>
                        <DefaultButton
                             onClick={() => this._closeDialog()}
                             text="Anuluj"
                             disabled={hidden}/>
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }

    private _onConfirmDialog(onAction: () => void) {
        onAction();
        this.setState({ hidden: true });
    }

    private _closeDialog() {
        this.setState({ hidden: true });
    }
}
