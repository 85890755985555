import * as React from "react";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import "./filter.less";
import { Callout, Label } from "office-ui-fabric-react/lib";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import OutsideClickHandler from "react-outside-click-handler";
import { FilterLabel } from "./FilterLabel";
import ArrayHelper from "../../helpers/ArrayHelper";
import { Icon, autobind, Spinner, SpinnerSize } from "office-ui-fabric-react";

interface Props {
    name?: string;
    searchVisible?: boolean;
    isLoading?: boolean;
    selectedKeys?: string[] | number[];
    options: any[];
    getKey?: (item: any) => string | number;
    getLabel?: (item: any) => string;
    onChanged?: (item: any, selected: boolean) => void;
    onClear?: () => void;
}

interface State {
    menuIsVisible: boolean;
    selectedKeys: string[] | number[];
    filterText: string;
    filtredOptions: any[];
    label: string;
}

export class ListFilter extends React.Component<Props, State> {
    private _filterButton: HTMLElement | null;

    constructor(props: any) {
        super(props);
        let selectedKeys = ArrayHelper.getArrayOrEmptyIfUndefined2<string, number>(this.props.selectedKeys);
        this.state = {
            menuIsVisible: false,
            selectedKeys,
            filterText: "",
            filtredOptions: this.props.options,
            label: this._getLabel(this.getSelectedOptions(selectedKeys))
        };
    }

    componentWillReceiveProps(newProps: Props) {
        if (this.props.options === newProps.options
            && this.props.selectedKeys === newProps.selectedKeys) {
            return;
        }
        let selectedKeys = ArrayHelper.getArrayOrEmptyIfUndefined2<string, number>(newProps.selectedKeys);
        this.setState({
            ...this.state,
            filtredOptions: this._filterOptions(newProps.options, this.state.filterText),
            selectedKeys,
            label: this._getLabel(this.getSelectedOptions(selectedKeys))
        });
    }

    render() {
        let element: JSX.Element = <IconButton iconProps={{ iconName: "ChevronDownMed" }}/>;
        if (this.props.isLoading) {
            element = <Spinner className="filter-spinner" size={SpinnerSize.small}/>;
        }
        return (
            <div className="filter"
                onClick={() => this._changeMenuVisibility()}
                ref={(ref) => this._filterButton = ref}>
                <FilterLabel label={this.props.name} selectedLabel={this.state.label}/>
                {element}
                { this.state.menuIsVisible &&
                    <Callout target={this._filterButton}
                        calloutWidth={200}
                        isBeakVisible={false}
                        coverTarget={false}>
                        <OutsideClickHandler onOutsideClick={() => this._hide()}>
                            { this.props.searchVisible && 
                                <div className="filter-search">
                                    <input type="text" onChange={event => this._onSearchTextChange(event)} placeholder="Wyszukaj"/>
                                </div>
                            }
                            <div className="filter-options">
                                {this._renderOptions()}
                            </div>
                            <div className="filter-commands">
                                <Label onClick={() => this._clearSelected()}>
                                    <Icon iconName="Clear" title="Wyczyść" />
                                    Wyczyść
                                </Label>
                            </div>
                        </OutsideClickHandler>
                    </Callout>
                }
            </div>
        );
    }

    public getSelectedOptions(selectedKeys: string[] | number[]) {
        return (selectedKeys as string[]).map(k => this.props.options.find(o => this._getItemKey(o) === k));
    }

    @autobind
    private _onSearchTextChange(event: any) {
        let text = event.target.value as string;
        let filtredOptions = this._filterOptions(this.props.options, text);
        this.setState({...this.state, filterText: text, filtredOptions});
    }

    @autobind
    private _clearSelected() {
        this.setState({...this.state, selectedKeys: [], label: ""});
        if (this.props.onClear !== undefined) {
            this.props.onClear();
        }
    }

    @autobind
    private _renderItem(item: any) {
        let key = this._getItemKey(item);
        let label = this._getItemLabel(item);
        let selected = this.state.selectedKeys !== undefined
            ? (this.state.selectedKeys as any[]).some(k => k === key)
            : false;
        return (
            <li key={`${key}`} onClick={() => this._onClickedItem(key)}>
                <Checkbox label={label} checked={selected} onChange={() => this._onClickedItem(key)} />               
            </li>
        );
    }

    @autobind
    private _renderOptions() {
        if (this.props.options.length === 0) {
            return <p>Brak elementów</p>;
        }

        if (this.state.filtredOptions.length === 0) {
            return <p>Nie znaleziono elementów</p>;
        }

        return <ul>{this.state.filtredOptions.map(this._renderItem)}</ul>;
    }

    @autobind
    private _onClickedItem(key: any) {
        let selectedKeys = this.state.selectedKeys as any[];
        let keyIndex = selectedKeys.indexOf(key);
        let selected = keyIndex >= 0;
        if (this.props.onChanged === undefined) {
            if (selected) {
                selectedKeys.splice(keyIndex, 1);
            }
            else {
                selectedKeys.push(key);
            }
            let label = this._getLabel(this.getSelectedOptions(selectedKeys));
            this.setState({...this.state, selectedKeys, label });
        }
        if (this.props.onChanged !== undefined) {
            let item = this.props.options.find(o => this._getItemKey(o) === key);
            this.props.onChanged(item, !selected);
        }
    }

    private _getItemKey(item: any) : string {
        if (this.props.getKey === undefined) {
            return item.key;
        }
        return this.props.getKey(item) as string;
    }

    private _getItemLabel(item: any) {
        if (this.props.getLabel === undefined) {
            return item.text;
        }
        return this.props.getLabel(item);
    }

    @autobind
    private _changeMenuVisibility() {
        if (this.props.isLoading) {
            return;
        }
        this.setState({...this.state, menuIsVisible: !this.state.menuIsVisible});
    }

    @autobind
    private _hide() {
        this.setState({...this.state, menuIsVisible: false});
    }

    private _getLabel(options: any[]): string {
        let label = "";        
        if (options.length > 0) {
            let maxVisibleOptions = 1;
            label = this._getItemLabel(options[0]);
            for (let i = 1; i < maxVisibleOptions && i < options.length; i++) {
                label += `, ${this._getItemLabel(options[i])}`;
            }

            if (options.length > maxVisibleOptions) {
                label += ` (+${options.length - maxVisibleOptions})`;
            }
        }

        return label;
    }

    private _filterOptions(options: any[], text: string) {
        return options.filter(o => this._getItemLabel(o).toString().toLowerCase().includes(text.toLowerCase()));
    }
}
