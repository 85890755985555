import * as React from "react";
import IComment from "./IComment";
import DisplayHelper from "../../../../helpers/DisplayHelper";
import "./Comment.less";

interface Props {
    data: IComment;
}

export default class Comment extends React.Component<Props, {}> {
    render() {      
        let comment = this.props.data;
        return (
            <div className="comment">
                <div className="comment-meta">{comment.author.firstName} {comment.author.surname} [{DisplayHelper.formatDateTime(comment.createdDate, "YYYY-MM-DD HH:mm:ss")}]</div>
                <div className="comment-body" dangerouslySetInnerHTML={{ __html: comment.body }}></div>
            </div>
        );
    }
}
