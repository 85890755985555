import PositionModel from "../models/PositionModel";
import { Request } from "../../../tools/requests";
import KeyItemModel from "../models/KeyItemModel";
import { AffectsPriceType, ItemType } from "../../../models/Enums";
import { ISelectedKeyItemValue, IRecalculatePositionValues, IRecalculateOfferValues, CalculatePositionNetValue } from "../models/interfaces";
import OfferModel from "../models/OfferModel";

export class CalculationActions {
    static calculatePositionNetValue(position: PositionModel) {
        let values = position.key.items.map(this.mapToSelected);
        return Request.query<CalculatePositionNetValue>("CalculatePositionNetValue", {
            productTypeId: position.key.productTypeId,
            keyItemValues: values
        });
    }

    static recalculatePositionNetValue(position: PositionModel) {
        return Request.query<IRecalculatePositionValues>("RecalculatePositionNetValue", {
            discountPercentage: position.discountPercentage,
            quantity: position.quantity,
            netUnitPrice: position.netUnitPrice
            }
        );
    }

    static recalculateOfferNetValue(offer: OfferModel) {
        return Request.query<IRecalculateOfferValues>("RecalculateOfferNetValue", {
            positions: offer.positions.map(x => { 
                return { netValue: x.netValue, quantity: x.quantity }; 
            }),
            discountPercentage: offer.discountPercentage,
            discountInCurrency: offer.discountInCurrency
        });
    }

    private static mapToSelected(keyItem: KeyItemModel) : ISelectedKeyItemValue {
        if (keyItem.metadata.affectsPrice === AffectsPriceType.MultipliesBySelf) {
            return {
                keyItemId: keyItem.metadata.id,
                numberValue: +keyItem.getValue()
            };
        }
        if (keyItem.metadata.type === ItemType.RAL) {
            return {
                keyItemId: keyItem.metadata.id,
                numberValue: keyItem.getValue() as number
            };
        }
        return {
            keyItemValueId: keyItem.getCurrentId(),
            keyItemId: keyItem.metadata.id
        };
    }
}
