import { IOffer, IPosition, ISelectedKeyItemValue, IOfferRead, Tag, ICreateOffer, OfferContactInfo } from "../models/interfaces";
import { IKeyItemValue } from "../../../models/interfaces";
import { Request } from "../../../tools/requests";
import { OfferListItem } from "../models/OfferListItem";
import PositionModel from "../models/PositionModel";
import OfferModel from "../models/OfferModel";
import OfferReadModel from "../models/OfferReadModel";
import KeyModel from "../models/KeyModel";
import KeyItemModel from "../models/KeyItemModel";
import MathHelper from "../../../helpers/MathHelper";
import { ItemType } from "../../../models/Enums";
import PositionReadModel from "../models/PositionReadModel";
import SpinnerStore from "../../../tools/SpinnerStore";
import { OfferStatus } from "../models/Enums";
import { IOffersListFilters } from "../store/offersListFilters/types";

export class OfferActions {
    static createOffer(offer: OfferModel, initialComment: string) {
        let mappedOffer = this.mapOfferModel(offer) as ICreateOffer;
        mappedOffer.comment = { body: initialComment };
        return Request.freezeCommand("CreateOffer", mappedOffer);
    }

    static getOffersList(skip: number, take: number, filters: IOffersListFilters) {
        let { searchText, statuses, onlyMy, tags, nextContactDates } = filters;
        return Request.query<OfferListItem[]>("ReadOffers", { searchText, statuses, skip, take, onlyMy, tags, nextContactDates });
    }

    static duplicateOffer(id: number) {
        return Request.command("DuplicateOffer", { id });
    }

    static deleteOffer(id: number) {
        return Request.command("DeleteOffer", { id });
    }

    static duplicatePositionToCustom(position: PositionModel) {
        return Request.query<string>("GeneratePositionName", this.mapPositionModel(position));
    }

    static changeOfferStatus(id: number, newStatus: OfferStatus) {
        return Request.command("ChangeOfferStatus", { id, newStatus });
    }

    static getOffer(id: number) {
        return Request.freezeQuery<IOffer>("ReadOfferToEdit", { id })
            .then(x => this.mapOffer(x))
            .then(x => { SpinnerStore.hideSpinner(); return x; });
    }

    static newOffer(templateId: number) {
        return Request.freezeQuery<IOffer>("ReadNewOffer", { templateId })
            .then(x => this.mapOffer(x))
            .then(x => { SpinnerStore.hideSpinner(); return x; });
    }

    static getOfferToRead(id: number) {
        return Request.freezeQuery<IOfferRead>("ReadOffer", { id }).then(this.mapOfferToRead);
    }

    static updateOffer(offer: OfferModel, offerId: number) {
        let mappedOffer = this.mapOfferModel(offer);
        mappedOffer.id = offerId;
        return Request.freezeCommand("UpdateOffer", mappedOffer);
    }

    static updateContactInfo(offerId: number, contactInfo: OfferContactInfo) {
        let model = {
            offerId,
            lastContactDate: contactInfo.lastContactDate.toDateString(),
            nextContactDate: contactInfo.nextContactDate.toDateString()
        };
        return Request.command("UpdateContactInfo", model);
    }

    static updateOfferTags(id: number, tags: string[]) {
        return Request.command("UpdateOfferTags", { id, tags });
    }

    static getAllTags() {
        return Request.query<Tag[]>("ReadAllTags");
    }

    private static mapKeyItemModel(keyItem: KeyItemModel): ISelectedKeyItemValue {
        return {
            id: keyItem.getSelectedId(),
            keyItemValueId: keyItem.getCurrentId(),
            keyItemId: keyItem.metadata.id,
            numberValue: MathHelper.numberOrNull(keyItem.getValue()),
            stringValue: keyItem.getValue() as string
        };
    }

    private static mapPositionModel(position: PositionModel, index?: number): IPosition {
        return {
            id: position.id,
            productTypeId: position.productTypeId,
            selectedKeyItemValues: position.key.items.map(this.mapKeyItemModel),
            discountPercentage: position.discountPercentage,
            discountInCurrency: position.discountInCurrency,
            isCustom: position.isCustom,
            quantity: position.quantity,
            netUnitPrice: position.netUnitPrice,
            netUnitPriceAfterDiscount: position.netUnitPriceAfterDiscount,
            netValue: position.netValue,
            remarks: position.remarks,
            order: index + 1
        };
    }

    private static mapOffer(offer: IOffer): OfferModel {
        let model = new OfferModel();
        model.rowVersion = offer.rowVersion;
        model.currencyId = offer.currencyId;
        model.currencyIso = offer.currencyIso;
        model.contactPersonId = offer.contactPersonId;
        model.offerNumber = offer.number;
        model.customerId = offer.customerId;
        model.sum = offer.sum;
        model.discountPercentage = offer.discountPercentage;
        model.discountInCurrency = offer.discountInCurrency;
        model.projectId = offer.projectId;
        model.quantitySum = offer.quantitySum;
        model.printTemplate = offer.printTemplate;
        model.tags = offer.tags;
        model.notes = offer.notes;
        model.commentsThreadId = offer.commentsThreadId;
        model.nextContactDate = new Date(offer.nextContactDate);
        model.lastContactDate = new Date(offer.lastContactDate);
        offer.positions.map(position => {
            let m = new PositionModel();
            m.id = position.id;
            m.isCustom = position.isCustom;
            m.productTypeId = position.productTypeId;
            m.discountInCurrency = position.discountInCurrency;
            m.discountPercentage = position.discountPercentage;
            m.quantity = position.quantity;
            m.netUnitPrice = position.netUnitPrice;
            m.netUnitPriceAfterDiscount = position.netUnitPriceAfterDiscount;
            m.netValue = position.netValue;
            m.key = OfferActions.mapItems(position);
            m.remarks = position.remarks;
            return m;
            })
            .forEach(x => model.addPosition(x, false));

        return model;
    }

    private static mapOfferToRead(offer: IOfferRead): OfferReadModel {
        let model = new OfferReadModel();
        model.id = offer.id;
        model.currencyIso = offer.currencyIso;
        model.offerNumber = offer.number;
        model.sum = offer.sum;
        model.discountPercentage = offer.discountPercentage;
        model.discountInCurrency = offer.discountInCurrency;
        model.customer = offer.customer;
        model.contactPerson = offer.contactPerson;
        model.contactPerson.name = `${offer.contactPerson.firstName} ${offer.contactPerson.surname}`;
        model.project = offer.project;
        model.quantitySum = offer.quantitySum;
        model.notes = offer.notes;
        model.positions = offer.positions.map(position => {
            let p = new PositionReadModel();
            p.id = position.id;
            p.name = position.name;
            p.discountPercentage = position.discountPercentage;
            p.discountInCurrency = position.discountInCurrency;
            p.quantity = position.quantity;
            p.netUnitPrice = position.netUnitPrice;
            p.netUnitPriceAfterDiscount = position.netUnitPriceAfterDiscount;
            p.netValue = position.netValue;
            p.remarks = position.remarks;
            return p;
        });
        model.status = offer.status;
        model.tags = offer.tags;
        model.threadId = offer.commentsThreadId;
        if (offer.lastContactDate !== null) {
            model.lastContactDate = new Date(offer.lastContactDate);
        }
        if (offer.nextContactDate !== null) {
            model.nextContactDate = new Date(offer.nextContactDate);
        }
        return model;
    }

    private static mapOfferModel(model: OfferModel): IOffer {
        return {
            contactPersonId: model.contactPersonId,
            customerId: model.customerId,
            currencyId: model.currencyId,
            currencyIso: model.currencyIso,
            projectId: model.projectId,
            rowVersion: model.rowVersion,
            discountInCurrency: model.discountInCurrency,
            discountPercentage: model.discountPercentage,
            positions: model.positions.map((p, index) => this.mapPositionModel(p, index)),
            quantitySum: model.quantitySum,
            sum: model.sum,
            status: undefined,
            printTemplate: model.printTemplate,
            tags: model.tags,
            notes: model.notes,
            commentsThreadId: model.commentsThreadId,
            lastContactDate: model.lastContactDate.toDateString(),
            nextContactDate: model.nextContactDate.toDateString()
        };
    }

    private static mapItems(position: IPosition): KeyModel {
        let model = new KeyModel(
            position.productTypeId,
            position.key.id,
            position.key.items.map(x => {
                let current = {
                    id: x.current.keyItemValueId,
                    selectedKeyItemValuesId: x.current.id,
                    value: x.current.numberValue === null
                        ? x.current.stringValue === null && x.metadata.type !== ItemType.RAL
                            ? x.metadata.availableValues.find(k => k.id === x.current.keyItemValueId).value
                            : x.current.stringValue
                        : x.current.numberValue
                } as IKeyItemValue;
                return new KeyItemModel(x.metadata, current);
            }),
            position.key.needCompleteCosts
        );
        return model;
    }
}
