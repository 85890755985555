import NavigationHelper from "../../modules/offers/helpers/NavigationHelper";
import { IEvent, errorModel, succesModel } from "../NotificationModels";
import { Event } from "./Event";

export class OfferDuplicated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Utworzono ofertę " + event.body.number + " (duplikat)",
                action: {
                    label: "Przejdź",
                    callback: () => NavigationHelper.goToOfferView(event.body.id)
                }
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się zduplikować oferty"
            }
        };
    }
}

export class OfferCreated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Utworzono ofertę " + event.body.number,
                action: {
                    label: "Przejdź",
                    callback: () => NavigationHelper.goToOfferView(event.body.id)
                }
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się utworzyć oferty"
            }
        };
    }
}

export class OfferDeleted extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Usunięto ofertę " + event.body.number,
                action: {
                    label: "Przejdź",
                    callback: () => NavigationHelper.goToOfferView(event.body.id)
                }
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się usunąć oferty"
            }
        };
    }
}

export class OfferSaved extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: "Zapisano ofertę " + event.body.number,
                action: {
                    label: "Przejdź",
                    callback: () => NavigationHelper.goToOfferView(event.body.id)
                }
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się zapisać oferty"
            }
        };
    }
}

export class OfferStatusChanged extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: `Zmieniono status oferty ${event.body.number}`,
                action: {
                    label: "Przejdź",
                    callback: () => NavigationHelper.goToOfferView(event.body.id)
                }
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się oznaczyć oferty"
            }
        };
    }
}

export class OfferTagsUpdated extends Event {
    protected onSuccess(event?: IEvent) {
        return {
            notification: {
                ...succesModel,
                message: `Zapisano tagi`
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się zapisać tagów"
            }
        };
    }
}
