export class SessionScope {    
    private constructor(private _name: string) {}

    clear() {
        if (this.name === undefined) {
            return;
        }

        let scopeAlias = this.nameAlias;
        for (let index = 0; index < sessionStorage.length; index++) {
            let name = sessionStorage.key(index);
            if (name.startsWith(scopeAlias)) {
                sessionStorage.removeItem(name);
            }
        }
    }

    get name(): string {
        return this._name;
    }

    get nameAlias(): string {
        return `${name}_`;
    }

    private static _scope: SessionScope;

    static get scope() : SessionScope {
        return SessionScope._scope;
    }

    static init() {
        SessionScope._scope = new SessionScope("");
    }

    static newScope(name: string) {
        if (SessionScope._scope !== undefined) {
            SessionScope._scope.clear();
        }

        SessionScope._scope = new SessionScope(name);
    }
}
