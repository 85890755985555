import { ProjectsListActionTypes, ProjectsListState } from "./types";
import { Action } from "redux-actions";

const initState = new ProjectsListState();

export const projectsList = (state: ProjectsListState = initState, action: Action<any>) => {
    switch (action.type) {
        default: {
            return state;
        }
    }
};
