import * as React from "react";
import NumberKeyItem from "./NumberKeyItem";
import SelectKeyItem from "./SelectKeyItem";
import ConstKeyItem from "./ConstKeyItem";
import CustomTextKeyItem from "./CustomTextKeyItem";
import RalKeyItem from "./RalKeyItem";
import KeyModel from "../../../models/KeyModel";
import KeyItemModel from "../../../models/KeyItemModel";
import { ItemType } from "../../../../../models/Enums";
import { TooltipHost, Icon, Spinner } from "office-ui-fabric-react";

interface IProductKeyProps {
    model: KeyModel;
    onChange: () => void;
}

interface IProductKeyState {
    isLoading: boolean;
    model: KeyModel;
}
export default class ProductKey extends React.Component<IProductKeyProps, IProductKeyState> {
    constructor(props: IProductKeyProps) {
        super(props);
        this.state = {model: props.model, isLoading: false};
    }

    render() {
        if (this.state.isLoading) {
            return <Spinner></Spinner>;
        }

        let needCompleteCosts = this.state.model !== undefined && this.state.model.needCompleteCosts;
        return (
            <div className="keyItems">
                {this.prepareItems()}
                {
                    needCompleteCosts
                        && <TooltipHost content="Klucz produktu wymaga uzupełnienia cen" calloutProps={{ gapSpace: 0 }}>
                            <Icon
                                style={{ color: "red" }}
                                iconName="Error"/>
                        </TooltipHost>
                }
            </div>
        );
    }

    private prepareItems(): any {
        if (this.state.model === null
            || this.state.model === undefined
            || this.state.model.items === null
            || this.state.model.items.length === 0) {
            return null;
        }
        return this.state.model.items.map(this.itemFactory, this);
    }

    private itemFactory(item: KeyItemModel) {
        let productTypeId = this.state.model.productTypeId;
        let metadata = item.metadata;
        let keyName = `${productTypeId}-${metadata.name}`;
        switch (metadata.type) {
            case ItemType.Number:
                return (
                    <NumberKeyItem
                        key={keyName}
                        value={item.getValue() as number}
                        min={metadata.min}
                        hint={metadata.name}
                        onChange={item.onValueChange.bind(item)}/>
                );
            case ItemType.SimpleChoice:
                return (
                    <SelectKeyItem
                        key={keyName}
                        value={item.getValue() as string}
                        metadata={metadata} 
                        onChange={item.onValueChange.bind(item)}/>
                );
            case ItemType.Const:
                return (
                    <ConstKeyItem 
                        key={keyName} 
                        value={item.getValue() as string}
                        hint={metadata.name}/>
                );
            case ItemType.RAL:
                return (
                    <RalKeyItem
                        key={keyName}
                        value={item.getValue() as string}
                        metadata={metadata} 
                        onChange={item.onValueChange.bind(item)}/>
                );
            case ItemType.CustomText:
                return (
                    <CustomTextKeyItem
                        key={keyName}
                        value={item.getValue() as string}
                        hint={metadata.name}
                        onChange={item.onValueChange.bind(item)}/>
                );
            default:
                throw new Error("Niewspierany typ elementu klucza.");
        }
    }
}
