import * as React from "react";
import { connect, Dispatch } from "react-redux";
import NavNavigator from "../../components/NavNavigator";
import { DetailsList, DetailsListLayoutMode, CheckboxVisibility, Label, CommandBar } from "office-ui-fabric-react";
import { Tag } from "../../../offers/models/interfaces";
import { tagsLoad, deleteTag, updateTag } from "../../store/tags/actions";
import TagsActions from "../../actions/TagsActions";
import { AuthContext, Roles } from "../../../../tools/auth";
import TagContextualMenu from "../../components/tags/TagContextualMenu";
import { ConfirmDialog } from "../../../../components/dialogs";

interface ListProps {
    tags: Tag[];
    onLoad: (tags: Tag[]) => void;
    onDelete: (tag: Tag) => void;
    onUpdate: (tag: Tag) => void;
}

class List extends React.Component<ListProps, any> {
    private _menu: TagContextualMenu;

    constructor(props: ListProps) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="fixed-command-bar">
                </div>
                <div className="body-container">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                                <NavNavigator/>
                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg10">
                                <DetailsList
                                    className="off-padding"
                                    items={this.props.tags}
                                    columns={List.Columns}
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                    onItemContextMenu={(item?: any, index?: number, ev?: MouseEvent) => this._menu.show(item, ev)}/>
                            </div>
                        </div>
                    </div>
                </div>
                { AuthContext.instance.user.isInRole(Roles.Administrator)
                &&
                <TagContextualMenu
                    ref={ m => this._menu = m }
                    onDeleted={this.props.onDelete}
                    onUpdated={this.props.onUpdate}/>
                }
            </div>
        );
    }

    componentDidMount() {
        TagsActions.getAll().then(this.props.onLoad);
    }

    private static Columns = [
        {
            key: "tag_name",
            name: "Nazwa",
            fieldName: "name",
            minWidth: 200,
            maxWidth: 400,
            isResizable: true
        }
    ];
}
function mapStateToProps(state: any) {
    return {
        tags: state.tagsListReducer.tags,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        onLoad: (tags: Tag[]) => dispatch(tagsLoad(tags)),
        onDelete: (tag: Tag) => dispatch(deleteTag(tag)),
        onUpdate: (tag: Tag) => dispatch(updateTag(tag)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
