import * as React from "react";
import { TextField } from "office-ui-fabric-react";
import "./NumberInput.less";

interface INumberProps {
    value?: number;
    min?: number;
    type?: string;
    onChange: (value: number) => void;
    className?: string;
    inputClassName?: string;
    style?: any;
    dialogClass?: boolean;
}

interface INumberState {
    value: string;
}

export class NumberInput extends React.Component<INumberProps, INumberState> {
    constructor(props: INumberProps) {
        super(props);
        let val = this.props.value === undefined || this.props.value == null ? undefined : this.props.value.toString();
        this.state = { value: val };
    }

    onChange(value: any) {
        let newValueStr = value.toString();
        let newValue = parseFloat(newValueStr.replace(",", "."));
        let oldValueStr = this.state.value;
        let oldValue = parseFloat(oldValueStr);
        newValue = isNaN(newValue) ? 0 : newValue;

        if (newValue === undefined || newValueStr === oldValueStr) {
            return;
        }
        this.setState({ value: newValueStr });
        this.props.onChange(newValue);
    }

    render() {
        let val = this.state.value === undefined || this.state.value === "NaN"
            ? ""
            : this.state.value;
        return (
            <TextField
                className={this.props.className}
                inputClassName={`text-right ${this.props.inputClassName}`}
                style={this.props.style}
                step={0.01}
                min={this.props.min}
                value={val.replace(".", ",")}
                onChanged={this.onChange.bind(this)}/>
        );
    }

    setValue(newValue: number) {
        this.setState({ value: newValue.toString() });
    }
}
