import * as React from "react";
import Conf from "../tools/Conf";
import { AuthContext } from "../tools/auth";

export default class Authorize extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        let options = {
            tenant: Conf.AuthTenant,
            clientId: Conf.AuthClientId,
            endpoints: {}
        };
        for (const resourceName in Conf.Resources) {
            if (Conf.Resources.hasOwnProperty(resourceName)) {
                let resource = Conf.Resources[resourceName];
                (options.endpoints as any)[resource.url] = resource.id;
            }
        }
        AuthContext.init(options, true);
    }

    render() {
        if (!AuthContext.instance.isAuthenticated) {
            return <noscript/>;
        }
        return this.props.children;
    }
}
