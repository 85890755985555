import * as React from "react";
import { connect, Dispatch } from "react-redux";
import { DetailsList, DetailsListLayoutMode, CheckboxVisibility, Label, CommandBar } from "office-ui-fabric-react";
import DisplayHelper from "../../../../helpers/DisplayHelper";
import { LabelMenu } from "../../../../components";
import { IPoint } from "@uifabric/utilities";
import RalColorMenu from "../../components/ralColors/RalColorMenu";
import { ralShowMenu, ralLoad, ralDelete, ralDismissMenu, ralShowAddDialog, ralShowEditDialog, ralShowInvokedDialog, ralOnMark } from "../../store/ralColors/actions";
import RalColorsActions from "../../actions/RalColorsActions";
import "../../styles/editor.less";
import RalColor from "../../../../models/RalColor";
import NavNavigator from "../../components/NavNavigator";
import { autobind } from "office-ui-fabric-react/lib/Utilities";
import { ConfirmDialog } from "../../../../components/dialogs";
import { AuthContext, Roles, RolesHelper } from "../../../../tools/auth";

interface RalColorListProps {
    items: RalColor[];
    menuItem: RalColor;
    isContextMenuVisible: boolean;
    target: IPoint | HTMLElement;
    onLoad: (items: RalColor[]) => void;
    onInvoked: (item: RalColor) => void;
    onEdit: () => void;
    onAdd: () => void;
    onDismiss: () => void;
    onMark: (item: RalColor) => void;
    onDelete: () => void;
    showMenu: (target: IPoint | HTMLElement, item: RalColor) => void;
}

class List extends React.Component<RalColorListProps, any> {
    private _confrimDialog: any;

    constructor(props: RalColorListProps) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="fixed-command-bar" >
                    <CommandBar
                        className="bar-height"
                        items={RolesHelper.filter([{
                            name: "Dodaj",
                            key: "add",
                            iconProps: { iconName: "Add" },
                            onClick: this.props.onAdd,
                            roles: [Roles.Administrator]
                        }])}/>
                </div>
                <div className="body-container">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                                <NavNavigator/>
                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg10">
                                <DetailsList
                                    className="off-padding"
                                    items={this.props.items}
                                    columns={List.Columns}
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                    onItemInvoked={(item) => this.props.onInvoked(item)}
                                    onItemContextMenu={(item?: any, index?: number, ev?: MouseEvent) => this._handleContextualMenu(item, ev)}
                                    onRenderItemColumn={this._onRenderItemColumn}/>
                            </div>
                        </div>
                    </div>
                </div>
                { AuthContext.instance.user.isInRole(Roles.Administrator)
                &&
                <RalColorMenu
                    isContextMenuVisible={this.props.isContextMenuVisible}
                    onDelete={() => this.handleOnDelete()}
                    onDismiss={this.props.onDismiss}
                    onEdit={this.props.onEdit}
                    onMark={this.cheackDefault}
                    target={this.props.target}/>
                }
                <ConfirmDialog
                    ref={ref => this._confrimDialog = ref}
                    text={"Aktualnie domyślnym kolorem jest {0}. Czy na pewno chcesz dokonać zmiany?"}
                    onConfirmed={this.setDefault}/>
            </div>
        );
    }

    @autobind
    private setDefault() {
        this._confrimDialog.setState({ showDialog: false });
        RalColorsActions.setDefault(this.props.menuItem)
            .then(request => {
                if (request.response.ok) {
                    this.props.onMark(this.props.menuItem);
                }
            });
    }

    @autobind
    private cheackDefault() {
        RalColorsActions.getDefaultColor()
            .then(colorName => {
                if (colorName === undefined) {
                    this.setDefault();
                    return;
                }
                this._confrimDialog.show(colorName);
            });
    }

    private handleOnDelete() {
        RalColorsActions.delete(this.props.menuItem)
            .then(request => {
                if (request.response.ok) {
                    this.props.onDelete();
                }
            });
    }

    componentDidMount() {
        RalColorsActions.getAll().then(this.props.onLoad);
    }

    @autobind
    private _onRenderItemColumn(item: RalColor, index: any, column: any) {
        if (column.fieldName === "modifiedDate") {
            return <Label> {DisplayHelper.formatDateTime(item.modifiedDate)} </Label>;
        }
        if (column.fieldName === "costFactor") {
            return <Label> {DisplayHelper.fixed(item.costFactor)} </Label>;
        }
        if (column.fieldName === "name") {
            return (
                <LabelMenu
                    buttonId={`menu-${item.id}`}
                    text={new RalColor(item.id, item.value, item.other).toLabel()}
                    onClick={() => this.props.showMenu(
                        document.querySelector(`menu-${item.id}`) as HTMLElement,
                        item
                    )}/>
            );
        }
        if (column.fieldName === "isDefault") {
            return <Label> {item.isDefault ? "Tak" : "Nie"} </Label>;
        }
        return (item as any)[column.fieldName];
    }

    private _handleContextualMenu(item: any, event: MouseEvent) {
        this.props.showMenu(
            { x: event.clientX, y: event.clientY },
            item
        );
    }

    private static Columns = [
        {
            key: "name",
            name: "Nazwa",
            fieldName: "name",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true
        },
        {
            key: "cistFactor",
            name: "Współczynnik ceny",
            fieldName: "costFactor",
            minWidth: 100,
            maxWidth: 110,
            isResizable: true
        },
        {
            key: "modifiedDate",
            name: "Data modyfikacji",
            fieldName: "modifiedDate",
            minWidth: 100,
            maxWidth: 110,
            isResizable: true
        },
        {
            key: "isDefault",
            name: "Czy domyślny?",
            fieldName: "isDefault",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true
        }
    ];
}

function mapStateToProps(state: any) {
    return {
        items: state.ralColorReducer.items,
        menuItem: state.ralColorReducer.menuItem,
        isContextMenuVisible: state.ralColorReducer.isContextMenuVisible,
        target: state.ralColorReducer.target
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        onEdit: () => dispatch(ralShowEditDialog()),
        onInvoked: (item: any) => dispatch(ralShowInvokedDialog(item)),
        onAdd: () => dispatch(ralShowAddDialog()),
        onDismiss: () => dispatch(ralDismissMenu()),
        onMark: (item: RalColor) => dispatch(ralOnMark(item)),
        onDelete: () => dispatch(ralDelete()),
        onLoad: (items: any[]) => dispatch(ralLoad(items)),
        showMenu: (target: IPoint | HTMLElement, item: RalColor) => dispatch(ralShowMenu(target, item))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
