import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { TextField, Icon, IComboBoxOption, autobind } from "office-ui-fabric-react";
import { clear, changeSearchText, selectStatus, deselectStatus, loadAvaliableTags, clearSelectedStatuses, clearSelectedTags, selectTag, deselectTag, setNextContactDates } from "../../store/offersListFilters/actions";
import { OfferStatus, OfferStatuses } from "../../models/Enums";
import { ListFilter, DateFilter, FilterBar } from "../../../../components/filters";
import { DateRange, Tag } from "../../models/interfaces";
import { OfferActions } from "../../actions";

interface Props {
    areVisible: boolean;
    searchText: string;
    statuses: OfferStatus[];
    avaliableTags: Tag[];
    tags: number[];
    availableNextContactWeeks: number[];
    nextContactDates: DateRange;
    clear: () => void;
    changeSearchText: (searchText: string) => void;
    selectStatus: (status: OfferStatus) => void;
    clearSelectedStatuses: () => void;
    deselectStatus: (status: OfferStatus) => void;
    loadAvaliableTags: (tags: Tag[]) => void;
    clearSelectedTags: () => void;
    selectTag: (tagId: number) => void;
    deselectTag: (tagId: number) => void;
    setNextContactDates: (dateRange: DateRange) => void;
}

class Filters extends React.Component<Props, any> {
    componentWillMount() {
        OfferActions.getAllTags().then(this.props.loadAvaliableTags);
    }

    render() {
        return (
            <FilterBar isVisible={this.props.areVisible}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
                            <TextField
                                placeholder="Wyszukaj po słowie kluczowym"
                                value={this.props.searchText}
                                onChanged={this.onChangedSearchText}/>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg9">
                            <div className="filter-right-container">
                                <ListFilter
                                    options={this.availableOptions()}
                                    name="Statusy"
                                    selectedKeys={this.props.statuses}
                                    onChanged={this.onChangedStatus}
                                    onClear={this.props.clearSelectedStatuses}/>
                                <ListFilter
                                    options={this.props.avaliableTags}
                                    name="Tagi"
                                    getKey={(t: any) => t.id}
                                    getLabel={(t: any) => t.name}
                                    searchVisible={true}
                                    selectedKeys={this.props.tags}
                                    onChanged={this.onChangedTag}
                                    onClear={this.props.clearSelectedTags}
                                    isLoading={this.props.avaliableTags === undefined}/>
                                <DateFilter
                                    name="Następny kontakt"
                                    dateRange={this.props.nextContactDates}
                                    onChanged={this.onChangedNextContactDate}
                                    onClear={this.onClearNextContactDate}
                                    isLoading={this.props.avaliableTags === undefined}/>
                                <Icon className="filter-clear" iconName="Clear" title="Wyczyść filtry" onClick={() => this.props.clear()}/>
                            </div>
                        </div>
                    </div>
                </div>
            </FilterBar>
        );
    }

    private availableOptions(): IComboBoxOption[] {
        return Object.keys(OfferStatus)
            .filter(k => k.isNumber())
            .map(k => Number(k))
            .map(k => { return {key: k, text: OfferStatuses[k]}; });
    }

    @autobind
    private onChangedStatus(option: any, selected: boolean) {
        let status = option.key as OfferStatus;
        if (selected) {
            this.props.selectStatus(status);
        }
        else {
            this.props.deselectStatus(status);
        }
    }

    @autobind
    private onChangedTag(option: Tag, selected: boolean) {
        let tagId = option.id;
        if (selected) {
            this.props.selectTag(tagId);
        }
        else {
            this.props.deselectTag(tagId);
        }
    }

    @autobind
    private onChangedNextContactDate(from: Date, to: Date) {
        this.props.setNextContactDates({from, to});
    }

    @autobind
    private onClearNextContactDate() {
        this.props.setNextContactDates({from: undefined, to: undefined});
    }

    @autobind
    private onChangedSearchText(newValue: string) {
        this.props.changeSearchText(newValue);
    }
}

function mapStateToProps(state: any) {
    return {
        areVisible: state.offersListFilters.areVisible,
        searchText: state.offersListFilters.data.searchText,
        statuses: state.offersListFilters.data.statuses,
        avaliableTags: state.offersListFilters.data.avaliableTags,
        tags: state.offersListFilters.data.tags,
        nextContactDates: state.offersListFilters.data.nextContactDates
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>, ownProps: any) {
    return {
        clear: () => dispatch(clear()),
        changeSearchText: (searchText: string) => dispatch(changeSearchText(searchText)),
        selectStatus: (status: OfferStatus) => dispatch(selectStatus(status)),
        clearSelectedStatuses: () => dispatch(clearSelectedStatuses()),
        deselectStatus: (status: OfferStatus) => dispatch(deselectStatus(status)),
        loadAvaliableTags: (tags: Tag[]) => dispatch(loadAvaliableTags(tags)),
        clearSelectedTags: () => dispatch(clearSelectedTags()),
        selectTag: (tagId: number) => dispatch(selectTag(tagId)),
        deselectTag: (tagId: number) => dispatch(deselectTag(tagId)),
        setNextContactDates: (dateRange: DateRange) => dispatch(setNextContactDates(dateRange))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
