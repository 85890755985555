import * as React from "react";
import ItemContextualMenu from "../ItemContextualMenu";
import RalColorDialog from "./RalColorDialog";
import { ConfirmDialog } from "../../../../components/dialogs/ConfirmDialog";
import { ContextualMenuItemType } from "office-ui-fabric-react";

export default class RalColorMenu extends React.Component<any, any> {
    private _confirmDialog: ConfirmDialog;

    render() {
        return (
            <div>
                <ItemContextualMenu
                    isContextMenuVisible={this.props.isContextMenuVisible}
                    onDelete={() => this._confirmDialog.show()}
                    onDismiss={this.props.onDismiss}
                    onEdit={this.props.onEdit}
                    target={this.props.target}
                    items={[
                        {
                            key: "div-1",
                            itemType: ContextualMenuItemType.Divider
                        },
                        {
                            key: "default",
                            name: "Ustaw jako domyślny",
                            iconProps: { iconName: "Accept" },
                            onClick: () => this.props.onMark()
                        }
                    ]}/>
                <ConfirmDialog
                    ref={ref => this._confirmDialog = ref}
                    onConfirmed={this.props.onDelete}
                    text="Czy na pewno chcesz usunąć ten kolor?"/>
                <RalColorDialog/>
            </div>
        );
    }
}
