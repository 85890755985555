import { errorModel, succesModel } from "../NotificationModels";
import { Event } from "./Event";

export class DefaultEvent extends Event {
    protected onSuccess() {
        return {
            notification: {
                ...succesModel,
                message: "Operacja zakończona powodzeniem"
            }
        };
    }

    protected onError() {
        return {
            notification: {
                ...errorModel,
                message: "Nie udało się wykonać operacji"
            }
        };
    }
}
