import * as React from "react";
import {IKeyItemValue} from "../../../../../models/interfaces";
import "../../../styles/key/styles.less";
import { TextField } from "office-ui-fabric-react";

interface ICustomTextKeyItemProps {
    value: string;
    hint?: string;
    onChange: (value: IKeyItemValue) => void;
}

interface ICustomTextKeyItemState {
    value: string;
}

export default class CustomTextKeyItem extends React.Component<ICustomTextKeyItemProps, ICustomTextKeyItemState> {
    constructor(props: ICustomTextKeyItemProps) {
        super(props);
        let value = this.props.value;
        if (value === null || value === undefined) {
            value = "";
        }
        this.state = { value };
    }

    render() {
        let classNames = "custom custom-resizable full-width";
        if (!this.state.value) {
            classNames += " required";
        }
        return (
            <TextField 
                title={this.props.hint}
                className={classNames}
                multiline
                autoAdjustHeight
                value={this.state.value}
                onChanged={(event) => this.change(event)}/>
        );
    }

    private change(value: any) {
        this.setState({ value });
        this.props.onChange({ value });
    }
}
