import * as React from "react";
import { DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { String } from "typescript-string-operations";
import { Dialog } from "./Dialog";

interface IConfirmDialogProps {
    text?: string;
    onConfirmed: () => void;
}

interface IConfirmDialogState {
    isVisible: boolean;
    text: string;
}

export class ConfirmDialog extends React.Component<IConfirmDialogProps, IConfirmDialogState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isVisible: false,
            text: ""
        };
    }
    
    render() {
        return (
            <Dialog
                modalProps={{className: "text", isBlocking: false}}
                hidden={!this.state.isVisible}
                onDismiss={this._closeDialog.bind(this)}
                title={this.state.text}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={this._onConfirmDialog.bind(this)}
                        text="Tak"
                        disabled={!this.state.isVisible}/>
                    <DefaultButton
                        onClick={this._closeDialog.bind(this)}
                        text="Nie"
                        disabled={!this.state.isVisible}/>
                </DialogFooter>
            </Dialog>
        );
    }

    show(...args: any[]) {
        let text = "Czy jesteś pewny, że chcesz wykonać tę operacje?";
        if (this.props.text !== null) {
            if (args.length === 0) {
                text = this.props.text;
            }
            else {
                text = String.Format(this.props.text, ...args);
            }
        }
        this.setState({ isVisible: true, text });
    }

    private _onConfirmDialog() {
        this.props.onConfirmed();
        this.hide();
    }

    private _closeDialog() {
        this.hide();
    }

    hide() {
        this.setState({ ...this.state, isVisible: false });
    }
}
