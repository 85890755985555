import { CostsActionTypes, CostEditorState, KeyCost, CostGridCell } from "./types";
import { Action } from "redux-actions";
import CostTableBuilder from "../../helpers/CostTableBuilder";

const initState = new CostEditorState();

export const costEditorReducer = (state: CostEditorState = initState, action: Action<any>) => {
    switch (action.type) {
        case CostsActionTypes.LOAD: {
            let key: KeyCost = action.payload;
            let builder = new CostTableBuilder(key);
            return {
                ...state,
                key,
                costs: builder.buildTable(),
                builder,
                currentProductTypeId: key.productTypes[0].id
            };
        }
        case CostsActionTypes.CHANGE_PRODUCT_TYPE: {
            let costs = state.builder.changeCostsType(state.key, action.payload);
            return { ...state, costs, currentProductTypeId: action.payload };
        }
        case CostsActionTypes.CHANGE_KEY: {
            return { ...state, key: action.payload };
        }
        case CostsActionTypes.CHANGE_COST: {
            let row = action.payload.row;
            let col = action.payload.col;
            let value = action.payload.value;
            let modifiedCell = <CostGridCell>action.payload.modifiedCell;
            
            let costs = state.costs.map((x, rowI) => {
                return x.map((y, colJ) => {
                    if (rowI === row && colJ === col) {
                        return { ...y, value };
                    }
                    return y;
                });
            });

            state.key.productTypes.find(ptc => ptc.id === state.currentProductTypeId)
                .costBases.find(cb => cb.baseKeyItemValueId === modifiedCell.baseValueId)
                .costVariants.find(cv => cv.keyItemId === modifiedCell.itemId)
                .optionVariants.find(ov => ov.id === modifiedCell.costOptionId)
                .cost = value;
            return { ...state, costs };
        }
        default: {
            return state;
        }
    }
};
