export default class NavigationHelper {
    static goToOfferEditor(offerId: number) {
        location.href = `#/offer/${offerId}/edit`;
    }

    static goToOfferView(offerId: number) {
        location.href = `#/offer/${offerId}/read`;
    }

    static goToOfferList() {
        location.href = "#/offers";
    }

    static goToNewOffer() {
        location.href = "#/offer/add";
    }
}
