import * as React from "react";
import { DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import Validate from "../../helpers/Validate";
import { Dialog } from "../../../../components/dialogs";
import { IContact, IProject, IProjectListItem } from "../../models/interfaces";
import { IButton, CommandBar, Label } from "office-ui-fabric-react";
import { CustomerActions } from "../../actions/CustomerActions";
import "./ProjectDialog.less";
import { ContactList } from "./contacts";
import {v4 as uuidv4} from 'uuid';

interface Props {
    title: string;
    edited?: boolean;
    project: IProject;
    hidden: boolean;
    disabledTextFields?: boolean;
    onDismiss: () => void;
    onChange?: (project: IProject) => void;
    onSaved?: (project: IProjectListItem) => void;
}

interface State {}

export class ProjectDialog extends React.Component<Props, State> {
    private _closeButton: IButton;
    private _name: TextField;
    private _contactList: ContactList;

    constructor(props: Props) {
        super(props);
        this.state = { relatedContacts: [] };
    }

    public render() {
        if (this.props.project === undefined) { return null; }
        let { project, onChange } = this.props;
        let focus = { validateOnFocusOut: true, validateOnLoad: false, autoFocus: true };
        return (
            <Dialog
                {...this.props}
                modalProps={{ isBlocking: false, containerClassName: "dialog-min-width" }}>
                <div className="ms-Grid">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField label="Nazwa"
                                ref={ref => this._name = ref}
                                {...focus}
                                onChanged={name => onChange({ ...project, name })}
                                onGetErrorMessage={v => Validate.empty(v)}
                                defaultValue={project.name}
                                disabled={this.props.disabledTextFields}
                                required/>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <Label>Podmioty powiązane</Label>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <CommandBar items={[{
                                name: "Dodaj podmiot",
                                iconProps: {
                                    iconName: "Add"
                                },
                                key: "add-custom-position",
                                onClick: () => this._onAddNewRelatedEntity(),
                                disabled: this.props.disabledTextFields
                            }]}/>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className="">
                                <ContactList
                                    ref={ref => this._contactList = ref}
                                    contacts={this.props.project.contacts}
                                    onChange={contacts => onChange({...project, contacts})}
                                    onDeleteContact={this._onDeleteContact}
                                    disabled={this.props.disabledTextFields}/>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.props.disabledTextFields
                    ? (
                        <DialogFooter>
                            <PrimaryButton
                                onClick={this.props.onDismiss}
                                text="Ok"
                                disabled={this.props.hidden}/>
                        </DialogFooter>
                    )
                    : (
                        <DialogFooter>
                            <PrimaryButton
                                onClick={this._validateOnSave.bind(this)}
                                text="Zapisz"
                                disabled={this.props.hidden}/>
                            <DefaultButton
                                onMouseEnter={() => this._closeButton.focus()}
                                componentRef={ref => this._closeButton = ref}
                                onClick={this.props.onDismiss}
                                text="Anuluj"/>
                        </DialogFooter>
                    )
                }
            </Dialog>
        );
    }

    private _validateOnSave() {
        let valid = true;
        if (this._name.value === "") {
            this._name.focus();
            valid = false;
        }
        if (!this._contactList.validate()) {
            valid = false;
        }
        if (valid) {
            this._saveDialog();
        }
    }

    private _saveDialog() {
        let project = this.props.project;

        if (this.props.edited) {
            CustomerActions.editProject(project)
                .then(x => {
                    this.props.onSaved(x.body[0].body);
                });
        }
        else {
            CustomerActions.addProject({ ...project })
                .then(x => {
                    this.props.onSaved(x.body[0].body);
                });
        }
    }

    private _onAddNewRelatedEntity = () => {
        let contacts = this.props.project.contacts;
        contacts.push({id: uuidv4()});
        this.props.onChange({ ...this.props.project, contacts });
    }

    private _onDeleteContact = (contact: IContact) => {   
        let project = this.props.project;     
        let contacts = project.contacts.filter(c => c.id !== contact.id);
        this.props.onChange({ ...project, contacts });
    }
}
