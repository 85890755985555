import * as React from "react";
import PositionReadModel from "../../models/PositionReadModel";
import DisplayHelper from "../../../../helpers/DisplayHelper";

interface Props {
    no: number;
    model: PositionReadModel;
}

export class Position extends React.Component<Props, any> {
    render() {
        let { name, quantity, netValue, discountPercentage, netUnitPrice, netUnitPriceAfterDiscount, remarks } = this.props.model;

        return (
            <tr>
                <td>{this.props.no}</td>
                <td>{name}</td>
                <td>{remarks}</td>
                <td className="text-right">
                    {quantity}
                </td>
                <td className="text-right">
                    {DisplayHelper.fixed(netUnitPrice)}
                </td>
                <td className="text-right">
                    {DisplayHelper.fixed(discountPercentage)}
                </td>
                <td className="text-right">
                    {DisplayHelper.fixed(netUnitPriceAfterDiscount)}
                </td>
                <td className="text-right">
                    {DisplayHelper.fixed(netValue)}
                </td>
            </tr>
        );
    }
}
