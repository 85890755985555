import { RalColorsActionTypes, RalColorEditorState } from "./types";
import { Action } from "redux-actions";

const initState = new RalColorEditorState();

export const ralColorReducer = (state: RalColorEditorState = initState, action: Action<any>) => {
    switch (action.type) {
        case RalColorsActionTypes.LOAD: {
            return { ...state, items: action.payload };
        }
        case RalColorsActionTypes.SHOW_MENU: {
            return {
                ...state,
                isContextMenuVisible: true,
                target: action.payload.target,
                menuItem: action.payload.item
            };
        }
        case RalColorsActionTypes.DISMISS_MENU: {
            return { ...state, isContextMenuVisible: false };
        }
        case RalColorsActionTypes.DELETE: {
            let items = state.items;
            let index = items.findIndex(item => item.id === state.menuItem.id);
            items = items.slice(0, index).concat(items.slice(index + 1));
            return { ...state, items };
        }
        case RalColorsActionTypes.SHOW_ADD_DIALOG: {
            return { ...state, showDialog: true, edited: false, menuItem: initState.menuItem };
        }
        case RalColorsActionTypes.SHOW_EDIT_DIALOG: {
            return { ...state, showDialog: true, edited: true };
        }
        case RalColorsActionTypes.SHOW_INVOKED_DIALOG: {
            return { ...state, showDialog: true, edited: true, menuItem: action.payload };
        }
        case RalColorsActionTypes.DISMISS_DIALOG: {
            return { ...state, showDialog: false };
        }
        case RalColorsActionTypes.ON_CHANGE: {
            return { ...state, menuItem: action.payload };
        }
        case RalColorsActionTypes.ON_MARK: {
            let items = state.items.map(x => {
                if (x.isDefault && x.id !== action.payload.id) {
                    x.isDefault = false;
                }
                else if (x.id === action.payload.id) {
                    x.isDefault = true;
                }
                return x;
            });
            return { ...state, items };
        }
        case RalColorsActionTypes.ON_ADD: {
            let items = [action.payload, ...state.items];
            return { ...state, items, showDialog: false };
        }
        case RalColorsActionTypes.EDIT: {
            let items = state.items
                .map(x => {
                    if (x.id === state.menuItem.id) {
                        x = state.menuItem;
                    }
                    return x;
                });
            return { ...state, items, showDialog: false };
        }
        default: {
            return state;
        }
    }
};
