import { CustomColorsDialogActionTypes, CustomColorDialogState, CustomColorEditing } from "./types";
import { Action } from "redux-actions";

const initState = new CustomColorDialogState();

export const customColorReducer = (state: CustomColorDialogState = initState, action: Action<any>) => {
    switch (action.type) {
        case CustomColorsDialogActionTypes.OPEN: {
            let items: CustomColorEditing[] = action.payload;
            return {
                ...state,
                items,
                isHidden: false
            };
        }
        case CustomColorsDialogActionTypes.HIDE: {
            return { ...state, isHidden: true };
        }
        case CustomColorsDialogActionTypes.SHOW: {
            return { ...state, isHidden: false };
        }
        case CustomColorsDialogActionTypes.CHANGE_COST_FACTOR: {
            let items = state.items.map(x => {
                if (x.colorId === action.payload.colorId) {
                    x.costFactor = action.payload.costFactor;
                }
                return x;
            });
            return { ...state, items };
        }
        case CustomColorsDialogActionTypes.CHANGE_ALL: {
            return { ...state, items: action.payload };
        }
        case CustomColorsDialogActionTypes.MARK: {
            let items = state.items.map(x => {
                if (x.isDefault && x.colorId !== action.payload.colorId) {
                    x.isDefault = false;
                }
                else if (x.colorId === action.payload.colorId) {
                    x.isDefault = true;
                    x.assigned = true;
                }
                return x;
            });
            return { ...state, items };
        }
        default: {
            return state;
        }
    }
};
