import * as React from "react";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { Resources } from "../../../../tools/Conf";
import { AuthContext } from "../../../../tools/auth";
import { Spinner, autobind } from "office-ui-fabric-react";
import Comment from "./Comment";
import IComment from "./IComment";
import "./Comments.less";

interface Props {
    threadId: string;
}

interface State {
    connection: HubConnection;
    comments: IComment[];
}

export default class Hub extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            connection: undefined,
            comments: undefined
        };
    }

    componentDidMount() {
        let resource = Resources.getResource(null);
        let connection = new HubConnectionBuilder()
            .withUrl(`${resource.url}/hubs/comments`, { accessTokenFactory: () => AuthContext.instance.acquireToken(resource.id) })
            .configureLogging(LogLevel.Error)
            .withAutomaticReconnect([0, 0, 10000])
            .build();

        connection.start()
            .then(this._readComments)
            .catch(err => console.log("Error while establishing connection :("));
        
        connection.on("onCommentCreated", (comment) => {
            let comments = [comment].concat(this.state.comments);
            this.setState({...this.state, comments });
        });

        this.setState({connection});
    }

    render() {
        if (this.props.threadId === undefined) {
            return <></>;
        }

        if (this.state.comments === undefined) {
            return <Spinner/>;
        }

        return <div className="comments">{this.state.comments.map(x => <Comment key={x.id} data={x}/>)}</div>;
    }

    createComment(body: string) {
        return this.state.connection.invoke("CreateComment", this.props.threadId, body);
    }

    @autobind
    private _readComments() {
        this.state.connection.invoke<IComment[]>("ReadComments", this.props.threadId).then(
            value => {
                this.setState({...this.state, comments: value});
            }
        );
    }
}
