import { Request } from "../../../tools/requests";
import { ProductKeyListItem } from "../store/keyList/types";

export default class KeyListActions {
    static getProductKeyList() {
        return Request.query<ProductKeyListItem[]>("ReadProductKeyListItems");
    }
    
    static deleteProductKey(id: number) {
        return Request.command("DeleteProductKey", {id});
    }
    
    static duplicateProductKey(id: number) {
        return Request.freezeCommand("DuplicateProductKey", {id}, "Duplikowanie klucza");
    }
}
